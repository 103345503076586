import React, { useState, useEffect, useRef } from 'react';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { useUser } from '../Integrations/UserContext'; // Assuming you have user context for login
import { getFirestore, doc, getDoc, setDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import '../styles/voteChess.css';
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler
import TeamSelection from '../Integrations/TeamSelection';
import { db } from '../firebase-config';
import DiscussionBox from '../Integrations/DiscussionBox';
import { renderCapturedPieces, highlightLastMove, handleSidelineMove, handleSidelineSquareClick, onDropWithSidelines } from "../Integrations/helperFunctions";
import { renderMoveHistory } from "../Integrations/MoveHistoryTable";
import PromotionModal from '../Integrations/PromotionModal';

const VoteChess = () => {
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState('');
  const [candidateMoves, setCandidateMoves] = useState([]);
  const [forum, setForum] = useState(null);
  const [messageForum, setMessageForum] = useState({});
  const [team, setTeam] = useState('');
  const [userVoteCount, setUserVoteCount] = useState(0);
  const [userLikes, setUserLikes] = useState({});
  const { user } = useUser();
  const [timeRemaining, setTimeRemaining] = useState(0); // 48 hours
  const [turn, setTurn] = useState('gold'); // Track whose turn it is
  const [moveDeadline, setMoveDeadline] = useState(null); // Record move deadline timestamp
  const encodedFen = encodeURIComponent(fen);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [username, setUsername] = useState('');
  const [isWhite, setIsWhite] = useState(null);
  const [gameId, setGameId] = useState('');
  const settingsDocRef = doc(db, 'voteChessSettings', 'gameIdTracker');
  const hasMadeMoveRef = useRef(false);
  const [pgn, setPgn] = useState('');
  const [highestLikes, setHighestLikes] = useState(0);
  const [selectedMove, setSelectedMove] = useState(null);
  const [lastMoveSquares, setLastMoveSquares] = useState(null);
  const [showTeamSelection, setShowTeamSelection] = useState(false);
  const userDocRef = user ? doc(db, 'users', user.uid) : null;
  const [activeDiscussion, setActiveDiscussion] = useState("main");
  const [selectedMoveIndex, setSelectedMoveIndex] = useState(-1);
  const [displayFen, setDisplayFen] = useState(null);
  const [squareStyles, setSquareStyles] = useState({});
  const [orientation, setOrientation] = useState("White");
  const [selectedSidelineIndex, setSelectedSidelineIndex] = useState(null);
  const [selectedSidelineMoveIndex, setSelectedSidelineMoveIndex] = useState(0);
  const [sidelines, setSidelines] = useState([]);
  const [promotionOpen, setPromotionOpen] = useState(null);
  const [promotionDetails, setPromotionDetails] = useState(null);
  const [pieceSquare, setPieceSquare] = useState(null);
  const restoreSquareStyles = useRef({});

  const sidelineRef = useRef(null);
  const moveHistoryRef = useRef(null);

  const initialFen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

const handlePromotion = (piece) => {
  console.log('Handling promotion...');
  if (promotionDetails) {
    const { pieceSquare, square } = promotionDetails;

    // Temporary game to manage the FEN and moves
    const tempGame = new Chess();
    tempGame.load(displayFen);

    const move = tempGame.move({ from: pieceSquare, to: square, promotion: piece });

    if (!move) {
      console.error("Promotion move could not be applied.");
      return;
    }

    // Check if in a sideline
    if (selectedSidelineIndex !== null && selectedSidelineMoveIndex !== null) {
      console.log("Applying promotion in sideline...");

      handleSidelineMove({
        move,
        tempGame,
        displayFen,
        selectedMoveIndex,
        selectedSidelineIndex,
        selectedSidelineMoveIndex,
        setSelectedSidelineIndex,
        setSelectedSidelineMoveIndex,
        setSidelines,
      });
    } else {
      console.log("Applying promotion in mainline...");
      handleMove(pieceSquare, square, piece);
    }

    // Update state after promotion
    setPromotionOpen(false);
    setPromotionDetails(null);
    setDisplayFen(tempGame.fen());
    console.log("Promotion handled successfully.");
  }
};

const handleMove = (from, to, piece=null) => {

  const move = game.move({ from, to, promotion: piece });

  if (move) {
    // Update display and states if the move is valid
    setDisplayFen(game.fen());
    setSelectedMoveIndex((prevIndex) => prevIndex + 1);
    setSquareStyles({}); // Reset square styles
    setPieceSquare(""); // Clear the selected square
  } else {
//    alert("Invalid move!"); // Handle invalid moves gracefully
  }
};

  const flipBoard = () => {
    setOrientation((prev) => (prev === "White" ? "Black" : "White"));
  };

  useEffect(() => {
    if (fen) {
        setDisplayFen(fen)
        };
  }, [fen]);

  useEffect(() => {
    if (game) {
        setSelectedMoveIndex(game.history().length - 1);
        };
  }, [game]);

  useEffect(() => {
    highlightLastMove(selectedMoveIndex, game, setSquareStyles);
  }, [selectedMoveIndex, displayFen]);

// Generate a unique ID for the main discussion of a specific position
const getMainDiscussionId = (gameId, fen) => `fen_${encodeURIComponent(fen)}_team_${encodeURIComponent(team)}`;

// Generate a unique ID for candidate moves in a specific position
const getMoveDiscussionId = (gameId, fen, move) => `fen_${encodeURIComponent(fen)}_move_${encodeURIComponent(move)}`;

const mainDiscussionId = getMainDiscussionId(gameId, fen);

const candidateMoveDiscussions = candidateMoves.map((moveObj) => ({
  move: moveObj.move,
  discussionId: getMoveDiscussionId(gameId, fen, moveObj.move),
}));
//
//useEffect(() => {
//  if (!pgn) {
//    console.log("No PGN available, skipping last move highlight.");
//    return;
//  }
//
//  console.log("Loading PGN to determine the last move...");
//  const tempGame = new Chess();
//  tempGame.loadPgn(pgn);
//
//  const history = tempGame.history({ verbose: true });
//  console.log("Move history:", history);
//
//  if (history.length > 0) {
//    const lastMove = history[history.length - 1];
//
//  } else {
//    console.log("No moves in history, clearing last move highlight.");
//    setLastMoveSquares(null);
//  }
//}, [pgn]);


  const previewMove = (move) => {
    const tempGame = new Chess(fen); // Temporary game to preview the move
    if (tempGame.move(move)) {
      setDisplayFen(tempGame.fen()); // Update preview FEN
    }
  };

const handleMoveClick = (move) => {
  if (move === 'resign') {
    setDisplayFen(fen);
    setSelectedMove(null);
    setActiveDiscussion("main"); // Reset to main discussion for resign
    return;
  }

  if (selectedMove === move) {
    setDisplayFen(fen);
    setSelectedMove(null);
    setActiveDiscussion("main"); // Reset to main discussion if move deselected
  } else {
    previewMove(move);
    setSelectedMove(move);
    setActiveDiscussion(move); // Switch to the move's discussion
  }
};

// Function to get the current game ID
const getCurrentGameId = async () => {
  const settingsDoc = await getDoc(settingsDocRef);
  if (settingsDoc.exists()) {
    return settingsDoc.data().currentGameId;
  } else {
    // Initialize the gameId if it doesn't exist
    await setDoc(settingsDocRef, { currentGameId: 1 });
    return 1;
  }
};

// Function to check if the game is over
const checkGameOver = () => {
  let winningTeam = null;

  if (game.isCheckmate()) {
    // Determine winning team in case of checkmate
    winningTeam = turn === 'gold' ? 'blue' : 'gold';
    console.log(`Checkmate! ${winningTeam.charAt(0).toUpperCase() + winningTeam.slice(1)} team wins!`);
    incrementGameId(winningTeam);
  } else if (game.isStalemate()) {
    console.log('Stalemate! Game over. No winning team.');
    incrementGameId(null); // No winner
  } else if (game.isDraw()) {
    console.log('Draw! Game over. No winning team.');
    incrementGameId(null); // No winner
  } else if (game.isThreefoldRepetition()) {
    console.log('Threefold repetition! Game over. No winning team.');
    incrementGameId(null); // No winner
  } else if (game.isInsufficientMaterial()) {
    console.log('Insufficient material! Game over. No winning team.');
    incrementGameId(null); // No winner
  } else if (game.isGameOver()) {
    console.log('Generic game over. No winning team.');
    incrementGameId(null); // No winner
  }
};



const incrementGameId = async (winningTeam) => {
  try {
    const currentGameId = await getCurrentGameId();
    const nextGameId = currentGameId + 1;

    // Update team stats based on the winning team
    const settingsDocRef = doc(db, 'voteChessSettings', 'teamStats');
    const settingsDoc = await getDoc(settingsDocRef);

    if (settingsDoc.exists()) {
      // If stats exist, increment the appropriate team's win count or draw count
      const teamStats = settingsDoc.data();
      const updatedStats = {
        goldWins: winningTeam === 'gold' ? (teamStats.goldWins || 0) + 1 : (teamStats.goldWins || 0),
        blueWins: winningTeam === 'blue' ? (teamStats.blueWins || 0) + 1 : (teamStats.blueWins || 0),
        draws: winningTeam === null ? (teamStats.draws || 0) + 1 : (teamStats.draws || 0),
      };
      await updateDoc(settingsDocRef, updatedStats);
      console.log(winningTeam === null ? 'Updated draw count.' : `Updated ${winningTeam} team's win count.`);
    } else {
      // Initialize the stats if they don't exist
      const initialStats = {
        goldWins: winningTeam === 'gold' ? 1 : 0,
        blueWins: winningTeam === 'blue' ? 1 : 0,
        draws: winningTeam === null ? 1 : 0,
      };
      await setDoc(settingsDocRef, initialStats);
      console.log(winningTeam === null ? 'Initialized with first draw.' : `Initialized win count with ${winningTeam} team winning.`);
    }

    // Increment the currentGameId in gameIdTracker
    const gameIdTrackerRef = doc(db, 'voteChessSettings', 'gameIdTracker');
    await updateDoc(gameIdTrackerRef, { currentGameId: nextGameId });
    console.log(`Game ID incremented to ${nextGameId} in gameIdTracker.`);

    // Create a new game document for the next game
    const newGameDocRef = doc(db, 'voteChessGames', `vote_chess_game_${nextGameId}`);
    const randomTurn = Math.random() < 0.5 ? 'gold' : 'blue';

    await setDoc(newGameDocRef, {
      fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
      turn: randomTurn,
      moveDeadline: new Date(Date.now() + 86400000).toISOString(),
      isCurrentMatch: true,
      isWhite: randomTurn,
      pgn: '',  // Initialize empty PGN for the new game
    });

    console.log(`New game document created for gameId: vote_chess_game_${nextGameId}`);
    window.location.reload(); // Refresh to load the new game
  } catch (error) {
    console.error('Error incrementing game ID:', error);
  }
};


useEffect(() => {
  const fetchAndSetGameId = async () => {
    const gameId = await getCurrentGameId(); // Fetch the current game ID from Firestore
    setGameId(`vote_chess_game_${gameId}`);
  };

  fetchAndSetGameId();
}, []);

// Check game over whenever a move is made
useEffect(() => {
  checkGameOver(); // Call this whenever a move is made to detect game over
}, [fen]);

  // Check if user is logged in and set team, also load team from Firestore if previously selected
  useEffect(() => {
    if (!user) {
      setShowLoginMessage(true);
      return;
    } else {
      setShowLoginMessage(false);
    }

const userDocRef = doc(db, "users", user.uid);

  onSnapshot(userDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const fetchedUsername = userData.username || 'Anonymous';
      setUsername(fetchedUsername); // Save the fetched username

      // Check if the team field exists
      if (userData.team) {
          setTeam(userData.team);
      } else {
            setShowTeamSelection(true);
      }
    } else {
      // If the user document doesn't exist, ask the user to choose a team and create a new document
      const selectedTeam = window.prompt('Choose your team: Gold or Blue');
      if (selectedTeam.toLowerCase() === 'gold' || selectedTeam.toLowerCase() === 'blue') {
        setTeam(selectedTeam.toLowerCase());

        // Create a new user document with the chosen team
        setDoc(userDocRef, { team: selectedTeam.toLowerCase() })
          .then(() => {
//            console.log('New user document created and team saved to Firestore: ' + selectedTeam.toLowerCase());
          })
          .catch((error) => {
            console.error('Error creating new user document:', error);
          });
      } else {
        alert('Invalid team choice! Please choose Gold or Blue.');
        window.location.reload();
      }
    }
  });
}, [user, db]);

useEffect(() => {
  if (!gameId) return; // If gameId is not set yet, don't proceed.

  const gameDocRef = doc(db, 'voteChessGames', gameId);

  const unsubscribe = onSnapshot(gameDocRef, async (docSnapshot) => {
    if (docSnapshot.exists()) {
//      console.log(`Game data found for gameId: ${gameId}`);
      const data = docSnapshot.data();
//      console.log('Loaded game data:', data);

      setFen(data.fen);
        const tempGame = new Chess();
        if (data.pgn) {
          tempGame.loadPgn(data.pgn);  // Load the game using PGN
        } else if (data.fen) {
          tempGame.load(data.fen);      // Fallback to FEN if PGN is not available
        }
        setGame(tempGame);

      setTurn(data.turn || 'gold');
      setMoveDeadline(data.moveDeadline || null);
      setIsWhite(data.isWhite);
      // Check for PGN data and set it to state
      if (data.pgn) {
        console.log("PGN found in game data:", data.pgn);
        setPgn(data.pgn);
      } else {
        console.log("No PGN found in game data.");
        setPgn(''); // Optionally reset PGN if none is found
      }
      // Now fetch candidateMoves and forumMessages from the positions subcollection
      const positionDocRef = doc(
        db,
        'voteChessGames',
        gameId,
        'positions',
        encodeURIComponent(data.fen)
      );
      const positionSnapshot = await getDoc(positionDocRef);
      if (positionSnapshot.exists()) {
        const positionData = positionSnapshot.data();
        setCandidateMoves(positionData.candidateMoves || []);
        setMessageForum(positionData.forumMessages || {});
      } else {
        // Initialize the position document if it doesn't exist
        await setDoc(positionDocRef, {
          candidateMoves: [],
          forumMessages: {},
        });
        setCandidateMoves([]);
        setMessageForum({});
      }
    } else {
      console.log(`No such game document! Creating a new game document for gameId: ${gameId}`);

      const randomTurn = Math.random() < 0.5 ? 'gold' : 'blue';
      // Create a new game document if it doesn't exist
      try {
        await setDoc(gameDocRef, {
          fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
          turn: randomTurn,
          moveDeadline: new Date(Date.now() + 86400000).toISOString(), // 24 hours from now
          isWhite: randomTurn,
        });

        // Initialize the position document
        const positionDocRef = doc(
          db,
          'voteChessGames',
          gameId,
          'positions',
          encodeURIComponent('start')
        );
        await setDoc(positionDocRef, {
          candidateMoves: [],
//          forumMessages: {},
        });
        setCandidateMoves([]);
//        setMessageForum({});
        console.log(`New game document created for gameId: ${gameId}`);
      } catch (error) {
        console.error('Error creating game document:', error);
      }
    }
  });

  return () => unsubscribe(); // Cleanup on unmount
}, [gameId, db]);

useEffect(() => {
  if (!gameId || !fen) return; // Ensure gameId and fen are available before proceeding

  const positionDocRef = doc(
    db,
    'voteChessGames',
    gameId,
    'positions',
    encodeURIComponent(fen)
  );

  // Real-time listener for candidate moves
  const unsubscribe = onSnapshot(positionDocRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const positionData = docSnapshot.data();

      // Update candidate moves and forum messages in real-time
      setCandidateMoves(positionData.candidateMoves || []);
      setMessageForum(positionData.forumMessages || {});
    } else {
      console.warn('No position document found, initializing...');
      setCandidateMoves([]);
      setMessageForum({});
    }
  });

  // Cleanup listener when the component unmounts
  return () => unsubscribe();
}, [gameId, fen]);

//useEffect(() => {
//  if (!moveDeadline) return;
//
//  // Set an interval to check the time every second
//  const intervalId = setInterval(() => {
//    const now = Date.now();
//    const deadline = new Date(moveDeadline).getTime();
//
//    if (now >= deadline) {
//      // Deadline has been reached, refresh the page or trigger an action
//      console.log('Deadline reached, refreshing page...');
//      clearInterval(intervalId); // Stop the interval once the deadline is reached
//      window.location.reload(); // Refresh the page
//    }
//  }, 1000); // Check every second
//
//  // Cleanup the interval on component unmount
//  return () => clearInterval(intervalId);
//}, [moveDeadline]);

//// Save game data to Firestore
//const saveGameData = async (updatedMoves = [], updatedMessages = {}, updatedTurn = turn, updatedMoveDeadline = moveDeadline) => {
//  const gameDocRef = doc(db, 'voteChessGames', gameId);
//  const positionDocRef = doc(
//    db,
//    'voteChessGames',
//    gameId,
//    'positions',
//    encodeURIComponent(fen)
//  );
//
//  try {
//    // Log the current FEN, turn, and moveDeadline before saving
//    console.log('Saving game data...');
//    console.log('FEN before saving:', game.fen());
//    console.log('Turn before saving:', updatedTurn);  // Use the updatedTurn variable
//    console.log('Move Deadline before saving:', updatedMoveDeadline);  // Use the updatedMoveDeadline variable
//
//    // Save candidateMoves and forumMessages to the position document
//    await setDoc(
//      positionDocRef,
//      {
//        candidateMoves: updatedMoves,
//        forumMessages: updatedMessages,
//      },
//      { merge: true } // Merging updates with existing data
//    );
//    console.log('Updated candidateMoves and forumMessages in Firestore.');
//
//    // Save fen, turn, moveDeadline to the main game document using updateDoc
//    await updateDoc(gameDocRef, {
//      fen: game.fen(),
//      turn: updatedTurn,  // Use updatedTurn or fallback to default
//      moveDeadline: updatedMoveDeadline,  // Use updatedMoveDeadline or fallback to default
//    });
//    console.log('Updated FEN, turn, and moveDeadline in Firestore.');
//  } catch (error) {
//    console.error('Error saving game data:', error);
//  }
//};

// Save game data to Firestore, including PGN, FEN, candidate moves, and more
const saveGameData = async (updatedMoves = [], updatedMessages = {}, updatedTurn = turn, updatedMoveDeadline = moveDeadline) => {
  const gameDocRef = doc(db, 'voteChessGames', gameId);
  const positionDocRef = doc(
    db,
    'voteChessGames',
    gameId,
    'positions',
    encodeURIComponent(fen)
  );

  try {
    // Generate PGN from the current game state
    const currentPgn = game.pgn();  // Generate PGN from the current game
    setPgn(currentPgn);  // Update local state with the current PGN for other uses

    // Save candidateMoves and forumMessages to the position document
    await setDoc(
      positionDocRef,
      {
        candidateMoves: updatedMoves,
        forumMessages: updatedMessages,
      },
      { merge: true } // Merging updates with existing data
    );
//    console.log('Updated candidateMoves and forumMessages in Firestore.');

    // Save FEN, turn, moveDeadline, and PGN to the main game document
    await updateDoc(gameDocRef, {
      fen: game.fen(),
      turn: updatedTurn,
      moveDeadline: updatedMoveDeadline,
      pgn: currentPgn,  // Include the PGN in the main game document
    });
//    console.log('Updated FEN, turn, moveDeadline, and PGN in Firestore.');
  } catch (error) {
    console.error('Error saving game data:', error);
  }
};


// Add a candidate move if legal and the correct team’s turn
const addCandidateMove = async (move) => {
  if (turn !== team) {
    alert('It’s not your team’s turn!');
    return;
  }

  if (move === 'resign') {
    // Check if "resign" has already been added to candidate moves
    const existingResign = candidateMoves.find((m) => m.move === 'resign');
    if (existingResign) {
      alert('Resign has already been entered.');
      return;
    }
    const updatedMoves = [...candidateMoves, { move: 'resign', likes: 0 }];
    setCandidateMoves(updatedMoves);
    saveGameData(updatedMoves, messageForum); // Save the updated moves and messages
    return;
  }

  const tempGame = new Chess(fen); // Load the current game position from FEN
  const legalMoves = tempGame.moves(); // Get all legal moves for the current position

  if (!legalMoves.includes(move)) {
    alert('Move is not legal. Please enter a valid move.');
    return;
  }

  // Check if the move has already been entered
  const existingMove = candidateMoves.find((m) => m.move === move);
  if (existingMove) {
    alert('Move has already been entered.');
    return;
  }

  // If move is valid, add it to candidate moves
  const moveResult = tempGame.move(move); // Make the move
  const updatedMoves = [...candidateMoves, { move, likes: 0 }];
  setCandidateMoves(updatedMoves);
  saveGameData(updatedMoves, messageForum); // Save the updated moves and messages
};

  // Handle liking a move
  const likeMove = async (move) => {
    if (!user) {
      alert('Please log in to like a move.');
      return;
    }

    const userDocRef = doc(db, 'users', user.uid);

    // Fetch user's voting data
    const userSnapshot = await getDoc(userDocRef);
    let userData = userSnapshot.data();

    // Check if the user has voted in the current move cycle (based on moveDeadline)
    const hasVotedInCurrentCycle = userData && userData.lastMoveDeadline === moveDeadline;

    // Reset vote count if it's a new move cycle
    let currentVoteCount = hasVotedInCurrentCycle ? userData.voteCount : 0;

    if (currentVoteCount >= 3) {
      alert('You can only like up to 3 moves in this cycle.');
      return;
    }

    // Update move likes
    const updatedMoves = candidateMoves.map((m) =>
      m.move === move ? { ...m, likes: m.likes + 1 } : m
    );

    setCandidateMoves(updatedMoves);
    currentVoteCount++; // Increment the vote count for this move

    // Update the user’s vote count and last vote timestamp
    const updatedUserData = {
      voteCount: currentVoteCount,
      lastMoveDeadline: moveDeadline, // Store the current move cycle's deadline
    };

    // Save the updated vote count and move data
    await updateDoc(userDocRef, updatedUserData);
    saveGameData(updatedMoves, messageForum);

    setUserVoteCount(currentVoteCount);
    setUserLikes({ ...userLikes, [move]: true });
  };

//const addForumMessage = async (message) => {
//  if (!user) {
//    alert('Please log in to post a message.');
//    return;
//  }
//
//  const newMessage = {
//    id: Date.now().toString(), // Generate a unique ID for the message
//    message,
//    username: username || 'Anonymous',
//    likes: 0,
//  };
//
//  const updatedMessages = {
//    ...messageForum,
//    [forum]: Array.isArray(messageForum[forum])
//      ? [...messageForum[forum], newMessage]
//      : [newMessage],
//  };
//
//  setMessageForum(updatedMessages);
//
//  const positionDocRef = doc(db, 'voteChessGames', gameId, 'positions', encodeURIComponent(fen));
//
//  try {
//    await updateDoc(positionDocRef, {
//      [`forumMessages.${forum}`]: updatedMessages[forum],
//    });
////    console.log("Forum message added successfully!");
//  } catch (error) {
//    console.error('Error saving forum message:', error);
//  }
//};


//const likeMessage = async (move, messageId) => {
//  if (!user) {
//    alert('Please log in to like a message.');
//    return;
//  }
//
//  if (userLikes[`${move}-${messageId}`]) {
//    alert('You have already liked this message.');
//    return;
//  }
//
//  const positionDocRef = doc(db, 'voteChessGames', gameId, 'positions', encodeURIComponent(fen));
//
//  try {
//    const docSnapshot = await getDoc(positionDocRef);
//    let updatedMessages = {};
//
//    if (!docSnapshot.exists()) {
//      console.error('Position document does not exist.');
//      return;
//    } else {
//      updatedMessages = docSnapshot.data().forumMessages || {};
//    }
//
//    const messageList = updatedMessages[move] || [];
//    const messageIndex = messageList.findIndex((msg) => msg.id === messageId);
//
//    if (messageIndex === -1) {
//      console.error('Message not found.');
//      return;
//    }
//
//    // Increment likes for the correct message
//    messageList[messageIndex].likes += 1;
//
//    updatedMessages[move] = messageList;
//
//    await updateDoc(positionDocRef, {
//      [`forumMessages.${move}`]: messageList,
//    });
//
//    setMessageForum(updatedMessages);
//    setUserLikes({ ...userLikes, [`${move}-${messageId}`]: true });
//  } catch (error) {
//    console.error('Error updating forum messages:', error);
//  }
//};

useEffect(() => {
//  if (!moveDeadline || hasMadeMoveRef.current) {
//    // Exit if there's no deadline or a move has already been made
//    return;
//  }

  const deadline = new Date(moveDeadline).getTime();

  const updateRemainingTime = () => {
    const now = Date.now();
    const timeLeft = Math.max(deadline - now, 0) / 1000; // Convert to seconds
    setTimeRemaining(Math.floor(timeLeft)); // Set in seconds

    if (timeLeft <= 0 && !hasMadeMoveRef.current) {
      // Time's up, determine the best move
      const sortedMoves = [...candidateMoves].sort((a, b) => b.likes - a.likes);
      const highestLikes = sortedMoves[0]?.likes || 0;
      const topMoves = sortedMoves.filter((move) => move.likes === highestLikes);

      let bestMove;
      if (topMoves.length > 1) {
        bestMove = topMoves[Math.floor(Math.random() * topMoves.length)]; // Random from tied moves
      } else if (topMoves.length === 1) {
        bestMove = topMoves[0];
      } else {
        // No candidate moves, choose a random legal move
        const legalMoves = game.moves();
        if (legalMoves.length > 0) {
          const randomIndex = Math.floor(Math.random() * legalMoves.length);
          bestMove = { move: legalMoves[randomIndex] };
        }
      }

      if (bestMove && bestMove.move === 'resign') {
        const winningTeam = turn === 'gold' ? 'blue' : 'gold';
        console.log(`${turn} team has resigned. ${winningTeam.charAt(0).toUpperCase() + winningTeam.slice(1)} team wins!`);
        incrementGameId(winningTeam);
      } else if (bestMove) {
        game.move(bestMove.move);
        setFen(game.fen());

        // Set up the next turn
        const nextTurn = turn === 'gold' ? 'blue' : 'gold';
        setTurn(nextTurn);

        // Set a new move deadline
        const newDeadline = Date.now() + 86400000; // Timestamp for 24 hours from now
        setMoveDeadline(new Date(newDeadline).toISOString()); // Save as ISO string

        // Calculate the time remaining
        const timeLeftNewTurn = Math.max(newDeadline - Date.now(), 0) / 1000; // Convert to seconds
        setTimeRemaining(Math.floor(timeLeftNewTurn)); // Set in seconds

        // Save game data with the updated turn and deadline
        saveGameData([], {}, nextTurn, new Date(newDeadline).toISOString());

        hasMadeMoveRef.current = true;
      }
    }
  };

  // Start interval to update time every second
  const intervalId = setInterval(updateRemainingTime, 1000);

  return () => clearInterval(intervalId); // Cleanup on unmount
}, [moveDeadline, candidateMoves, turn, fen]);

  const handleCloseMessage = () => {
    setShowLoginMessage(false);
  };

useEffect(() => {
  if (candidateMoves.length > 0) {
    setHighestLikes(Math.max(...candidateMoves.map((move) => move.likes)));
  } else {
    setHighestLikes(0); // Reset if no moves
  }
}, [candidateMoves]);

  const handleTeamSelect = (selectedTeam) => {
    setTeam(selectedTeam); // Set team in main state
    setDoc(userDocRef, { team: selectedTeam.toLowerCase() }, { merge: true })
    setShowTeamSelection(false); // Hide team selection component
  };

  // Scroll to the selected move
  const scrollToSelectedMove = () => {
    if (moveHistoryRef.current) {
      const selectedElement = moveHistoryRef.current.querySelector(".selected");
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
      }
    }
  };

const scrollToSelectedSideline = () => {
  if (sidelineRef.current) {
    const selectedMove = sidelineRef.current.querySelector(".selected");
    if (selectedMove) {
      selectedMove.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
  }
};

  // Trigger scrolling whenever the selected move index changes
  useEffect(() => {
    scrollToSelectedMove();
  }, [selectedMoveIndex]);

    // Trigger scrolling whenever the selected move index changes
  useEffect(() => {
    scrollToSelectedSideline();
  }, [selectedSidelineMoveIndex]);

  return (
    <div    >
      <EnableScrollOnPage />
      {showTeamSelection && (
        <TeamSelection userDocRef={userDocRef} onSelectTeam={handleTeamSelect} />
      )}
      {showLoginMessage && (
        <div className="login-message">
          <button className="close-button" onClick={handleCloseMessage}>×</button>
          <p><a href="/login" >Log in</a> to play team chess.</p>
        </div>
      )}
      <div className="game-layout">
        {promotionOpen && (
          <div className='promotion-modal-container'>
            <PromotionModal
              onPromote={handlePromotion}
              pieces={['q', 'r', 'n', 'b']}
              color={team === isWhite ? 'w' : 'b'}
            />
          </div>
        )}
        <div className="chessboard-container" style={{ marginBottom: '20px' }}>
         <div className="captures-container-absolute">
          {renderCapturedPieces(fen)}
        </div>
          <ChessboardCustom
            position={displayFen}
            degreeOfBlindness="Normal"
            orientation={team === isWhite ? 'white' : 'black'}
            squareStyles={squareStyles}
            onSquareClick={(square) =>
                  handleSidelineSquareClick(
                    square,
                    setPromotionOpen,
                    promotionDetails,
                    setPromotionDetails,
                    displayFen,
                    setDisplayFen,
                    pieceSquare,
                    setPieceSquare,
                    restoreSquareStyles,
                    squareStyles,
                    setSquareStyles,
                    setSidelines,
                    selectedSidelineIndex,
                    setSelectedSidelineIndex,
                    selectedSidelineMoveIndex,
                    setSelectedSidelineMoveIndex,
                    selectedMoveIndex,
                    sidelines
                  )
                }
                onDrop={({ sourceSquare, targetSquare }) => {
                  onDropWithSidelines({
                    sourceSquare,
                    targetSquare,
                    game,
                    setPromotionDetails,
                    setPromotionOpen,
                    displayFen,
                    setDisplayFen,
                    selectedMoveIndex,
                    selectedSidelineIndex,
                    setSelectedSidelineIndex,
                    selectedSidelineMoveIndex,
                    setSelectedSidelineMoveIndex,
                    setSidelines,
                  });
                }}
          />
        </div>
        <div className="move-history-container">
          {renderMoveHistory(
            {
              state: {
                history: game.history({ verbose: true }),
                fen: game.fen(),
                selectedMoveIndex: selectedMoveIndex,
                initialFen: initialFen,
              },
              setDisplayFen,
              setSquareStyles,
              setSelectedMoveIndex,
              flipBoard,
              moveHistoryRef,
              sidelineRef,
              setSidelines,
              selectedSidelineIndex,
              setSelectedSidelineIndex,
              selectedSidelineMoveIndex,
              setSelectedSidelineMoveIndex
            },
            game.history({ verbose: true }),
            (data) => data,
            sidelines,
            true
          )}
        </div>
        <div className="candidate-container" style={{ backgroundColor: 'white', padding: '15px', borderRadius: '8px', color: team === 'gold' ? '#E3B866' : '#287F94' }}>
          {turn !== team ? (
            <div>
              <h3 style={{ color: team === 'gold' ? '#E3B866' : '#287F94' }}>It is not your team's turn!</h3>
                <p style={{ fontWeight: 'bold' }}>
                  {String(Math.floor(timeRemaining / 3600)).padStart(2, '0')} :{' '}
                  {String(Math.floor((timeRemaining % 3600) / 60)).padStart(2, '0')} :{' '}
                  {String(Math.floor(timeRemaining % 60)).padStart(2, '0')}
                </p>
            </div>
          ) : (
            <>
              <h3 style={{ color: team === 'gold' ? '#E3B866' : '#287F94' }}>{team.toUpperCase()} Team Candidate Moves </h3>
                <p style={{ fontWeight: 'bold' }}>
                  {String(Math.floor(timeRemaining / 3600)).padStart(2, '0')} :{' '}
                  {String(Math.floor((timeRemaining % 3600) / 60)).padStart(2, '0')} :{' '}
                  {String(Math.floor(timeRemaining % 60)).padStart(2, '0')}
                </p>
                <ul className="candidate-moves-list">
                  {Array.isArray(candidateMoves) &&
                    candidateMoves
                      .sort((a, b) => b.likes - a.likes)
                      .map((moveObj, index) => (
                        <li
                          key={index}
                          onClick={() => handleMoveClick(moveObj.move)}
                          className={`move-item ${moveObj.likes === highestLikes ? 'highlight' : ''}`} // Highlight if top move(s)
                        >
                          <span className="move-text">{moveObj.move}</span>
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents the card's onClick from triggering
                              likeMove(moveObj.move);
                            }}
                            className="like-btn"
                            style={{
                              backgroundColor: team === 'gold' ? '#E3B866' : '#287F94',
                              color: 'white',
                            }}
                          >
                            👍 {moveObj.likes}
                          </button>
                        </li>
                      ))}
                </ul>
            <div className="add-move-section">
              <input
                type="text"
                placeholder="Enter move (e.g., e4)"
                id="move-input"
                className="message-input"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    const move = e.target.value;
                    if (move) {
                      addCandidateMove(move);
                      e.target.value = ''; // Clear input after adding move
                    }
                  }
                }}
              />
              <button
                className={`add-message-btn ${team === 'gold' ? 'team-gold' : 'team-blue'}`}
                onClick={() => {
                  const move = document.getElementById('move-input').value;
                  if (move) {
                    addCandidateMove(move);
                    document.getElementById('move-input').value = ''; // Clear input after adding move
                  }
                }}
              >
                Add Move
              </button>
            </div>
                {/* Resign Button */}
              <button
                className={`resign-btn ${team === 'gold' ? 'team-gold' : 'team-blue'}`}
                onClick={() => {
                  addCandidateMove('resign');
                }}
                style={{
                  backgroundColor: team === 'gold' ? '#E3B866' : '#287F94', // Adjust colors to match team
                  color: 'white',
                  marginTop: '10px' // Add margin to space it from the above button
                }}
              >
                Resign
              </button>

            </>
          )}
        </div>
        </div>

<div className="discussion-container">

        {activeDiscussion === "main" ? (
          <DiscussionBox folder={gameId} articleId={mainDiscussionId} title="Main Discussion" />
        ) : (
          <DiscussionBox
            folder={gameId}
            articleId={getMoveDiscussionId(gameId, fen, activeDiscussion )}
            title={`Discussion for Move: ${activeDiscussion}`}
          />
        )}
      </div>

    </div>
  );

}

export default VoteChess;
