import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ChessboardCustom from '../components/ChessboardCustom';
import { Chess } from 'chess.js';
import { getFirestore, doc, getDoc, setDoc, deleteDoc, Timestamp, collection, query, orderBy, limit, addDoc, getDocs } from 'firebase/firestore';import { useUser } from '../Integrations/UserContext'; // Assuming you have a user context
import '../styles/coordinateTrainer.css'; // Custom CSS for styling
import Modal from '../Integrations/Modal';
import Countdown from '../Integrations/Countdown';
import Leaderboard from '../Integrations/Leaderboard';
import JoyrideWrapper from '../Integrations/JoyrideWrapper';
import { coordinateRide } from '../Integrations/Joyrides';

const db = getFirestore(); // Initialize Firestore

function CoordinateTrainer() {
    const [game, setGame] = useState(new Chess());
    const [targetSquare, setTargetSquare] = useState(''); // Square the user has to find
    const [playerOrientation, setPlayerOrientation] = useState('white'); // Random orientation
    const [answerSquare, setAnswerSquare] = useState(''); // Track the user's selected square
    const [squareStyles, setSquareStyles] = useState({}); // Style for squares
    const [score, setScore] = useState(0); // Track the current score
    const [isGameActive, setIsGameActive] = useState(false); // Track game state
    const [timeLeft, setTimeLeft] = useState(60); // 60-second timer
    const [bestScore, setBestScore] = useState(null); // Store the user's best score
    const [gameOverMessage, setGameOverMessage] = useState(''); // Message after the game ends
    const { user } = useUser(); // Retrieve the current user
    const [showOverlay, setShowOverlay] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false); // Control modal visibility
    const [showCountdown, setShowCountdown] = useState(false);
    const [leaderboardView, setLeaderboardView] = useState('monthly');
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [showLoginMessage, setShowLoginMessage] = useState(false);

    useEffect(() => {
        setShowLoginMessage(!user); // Show message if user is not logged in
    }, [user]);

    const handleCloseMessage = () => {
        setShowLoginMessage(false); // Close login message
    };

const checkAndSetTourSeen = async () => {
    try {
        const userRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && !userDoc.data().hasSeenCoordinateTrainer) {
            setIsTourOpen(true); // Start the tour
            await setDoc(userRef, { hasSeenCoordinateTrainer: true }, { merge: true });
        }
    } catch (error) {
        console.error("Error checking/setting tour seen:", error);
    }
};

useEffect(() => {
    if (user) {
        checkAndSetTourSeen();
    }
}, [user]);


    const toggleLeaderboardView = () => {
        setLeaderboardView((prevView) => (prevView === 'monthly' ? 'allTime' : 'monthly'));
    };

    // Function to generate a random square on the chessboard
    const getRandomSquare = () => {
        const files = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
        const file = files[Math.floor(Math.random() * files.length)];
        const rank = Math.floor(Math.random() * 8) + 1;
        return `${file}${rank}`;
    };

    // Function to generate a random orientation (white or black)
    const getRandomOrientation = () => {
        return Math.random() > 0.5 ? 'white' : 'black';
    };

    // Function to generate the puzzle by choosing a target square and random orientation
    const generatePuzzle = () => {
        const randomSquare = getRandomSquare();
        const randomOrientation = getRandomOrientation();

        setTargetSquare(randomSquare);
        setPlayerOrientation(randomOrientation);
        setAnswerSquare('');
        setSquareStyles({});

        // Show overlay for half a second whenever the target square changes
        setShowOverlay(true);
        setTimeout(() => setShowOverlay(false), 500);
    };

    // Function to flash the board green when the correct square is selected
    const flashBoardGreen = async () => {
        const allSquares = [];
        for (let row = 1; row <= 8; row++) {
            for (let col of 'abcdefgh') {
                allSquares.push(col + row);
            }
        }

        const flashStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "rgba(0, 255, 0, .5)" };
            return styles;
        }, {});

        const defaultStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "transparent" };
            return styles;
        }, {});

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 300));
        setSquareStyles(defaultStyle);
        await new Promise(resolve => setTimeout(resolve, 50));
    };

    // Function to flash the board red when the incorrect square is selected
    const flashBoardRed = async () => {
        const allSquares = [];
        for (let row = 1; row <= 8; row++) {
            for (let col of 'abcdefgh') {
                allSquares.push(col + row);
            }
        }

        const flashStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "rgba(255, 0, 0, .5)" };
            return styles;
        }, {});

        const defaultStyle = allSquares.reduce((styles, square) => {
            styles[square] = { backgroundColor: "transparent" };
            return styles;
        }, {});

        setSquareStyles(flashStyle);
        await new Promise(resolve => setTimeout(resolve, 400));
        setSquareStyles(defaultStyle);
    };

    // Function to handle user's square selection
    const handleSquareClick = (square) => {
        // Handle free play or game mode
        if (square === targetSquare) {
            flashBoardGreen(); // Flash the board green for correct answer
            if (isGameActive) {
                setScore(prevScore => prevScore + 1); // Increment score only if in game mode
            }
            setTimeout(generatePuzzle, 100); // Move to next puzzle after a delay
        } else {
            flashBoardRed(); // Flash the board red for incorrect answer
        }
        setAnswerSquare(square); // Highlight the user's clicked square
    };

    // Start a new game and reset the score
    const startGame = () => {
        setScore(0);
        setTimeLeft(60);
        setIsGameActive(true);
        generatePuzzle();
    };

    const endGame = async () => {
        setIsGameActive(false);
        if (score > bestScore) {
            setBestScore(score);
            setGameOverMessage(`New record! Your best score is now ${score}.`);
            if (user) {
                const userRef = doc(db, "users", user.uid);
                await setDoc(userRef, { bestCoordinateScore: score }, { merge: true });
            }
        } else {
            setGameOverMessage(`Your score: ${score}. Best score: ${bestScore}. Keep trying!`);
        }

        await saveScoreToLeaderboard(score); // Save score to leaderboard
        setIsModalOpen(true); // Show game over modal
    };

const getCurrentMonthPath = () => {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const currentYear = new Date().getFullYear();
    return `leaderboard/coordinateTrainer/${currentYear}-${currentMonth}`;
};

const saveScoreToLeaderboard = async (score) => {
    if (!user) return; // Ensure the user is logged in
    const entry = {
        username: user.displayName || 'Anonymous',
        score,
        timestamp: Timestamp.now(),
    };

    // Define paths for monthly and all-time leaderboards
    const monthlyPath = getCurrentMonthPath();
    const allTimePath = 'leaderboard/coordinateTrainer/allTime';

    // Helper function to update the top 5 scores for the given path
    const updateLeaderboard = async (path) => {
        const scoresCollection = collection(db, path);
        await addDoc(scoresCollection, entry); // Add new score

        // Fetch top 5 scores after adding the new entry
        const leaderboardQuery = query(scoresCollection, orderBy('score', 'desc'), limit(5));
        const leaderboardSnapshot = await getDocs(leaderboardQuery);

        // Keep only the top 5 scores, deleting any extras
        const topScores = leaderboardSnapshot.docs;
        if (topScores.length > 5) {
            const excessScores = topScores.slice(5);
            for (const docSnapshot of excessScores) {
                await deleteDoc(doc(db, path, docSnapshot.id)); // Remove excess scores
            }
        }
    };

    // Update both monthly and all-time leaderboards
    await updateLeaderboard(monthlyPath);
    await updateLeaderboard(allTimePath);
};

    // Countdown timer for the game
    useEffect(() => {
        if (isGameActive && timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(prevTime => prevTime - 1);
            }, 1000);

            return () => clearTimeout(timer);
        } else if (isGameActive && timeLeft === 0) {
            endGame();
        }
    }, [timeLeft, isGameActive]);

const fetchBestScoreAndLeaderboard = async () => {
    try {
        if (user) {
            // Fetch best score
            const userRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                setBestScore(userDoc.data().bestCoordinateScore || 0);
            }

            // Fetch leaderboard
            const leaderboardRef = collection(db, 'leaderboard/coordinateTrainer/scores');
            const topScoresQuery = query(leaderboardRef, orderBy('score', 'desc'), limit(5));
            const leaderboardSnapshot = await getDocs(topScoresQuery);
            const scores = leaderboardSnapshot.docs.map(doc => doc.data());
            setLeaderboard(scores);
        }
    } catch (error) {
        console.error("Error fetching best score or leaderboard:", error);
    }
};

useEffect(() => {
    fetchBestScoreAndLeaderboard();
}, [user]);


    useEffect(() => {
        const newGame = new Chess();
        setGame(newGame);
        generatePuzzle();
    }, []);

    const handleCountdownComplete = () => {
        setShowCountdown(false);
        startGame(); // Start the game after countdown
    };

    return (
        <div>
            {showLoginMessage && (
                <div className="login-message">
                    <button className="close-button" onClick={handleCloseMessage}>×</button>
                    <p><a href="/login">Log in</a> to participate in the leaderboard and save your scores.</p>
                </div>
            )}
            <JoyrideWrapper
                steps={coordinateRide}
                run={isTourOpen}
                onFinish={() => setIsTourOpen(false)} // Close tour when finished
            />
        <div className="game-layout">
          {isGameActive && (
            <div className="chess-clock">
              <div className="time-display">
                <span className="clock-value">{timeLeft !== null ? `${timeLeft}` : '∞'}</span>
              </div>
            </div>
          )}
            {showOverlay && (
                <div className="coordinate-container">
                    <span className="overlay-text">{targetSquare}</span>
                </div>
            )}
            <div className="chessboard-container">
                <ChessboardCustom
                    position={game.fen()}
                    orientation={playerOrientation}
                    onSquareClick={handleSquareClick}
                    squareStyles={squareStyles}
                    degreeOfBlindness="Normal"
                    showNotation={false}
                />
            </div>
            <div className='move-history-container'>
                <div className="feedback-container">
                    <button onClick={() => setIsTourOpen(true)} className="help-link">
                        Help
                    </button>
                    <p>{isGameActive ? `Time Left: ${timeLeft}s` : `Your Best Score: ${bestScore || 'N/A'}`}</p>
                    {!isGameActive && <button onClick={() => setShowCountdown(true)} className="puzzle-button">Start Game</button>}
                    <p>Click on the square: <strong style={{ fontSize: '24px' }}>{targetSquare}</strong></p>
                    <Leaderboard leaderboardType={leaderboardView} toggleLeaderboardView={toggleLeaderboardView} pathBase="leaderboard/coordinateTrainer" />
                </div>
            </div>
            {showCountdown && <Countdown onCountdownComplete={handleCountdownComplete} />}
            {isModalOpen && (
                <Modal message={gameOverMessage} onClose={() => setIsModalOpen(false)} />
            )}
        </div>
        </div>
    );
}

export default CoordinateTrainer;
