import { useNavigate } from 'react-router-dom';
import '../styles/HomePage.css'; // Ensure this imports your updated CSS
import EnableScrollOnPage from '../Integrations/EnableScroll'; // Import the scroll enabler
import useInView from '../hooks/useInView'; // Import the custom hook
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import JoyrideWrapper from '../Integrations/JoyrideWrapper'; // Import the JoyrideWrapper
import Modal from '../Integrations/Modal'; // Custom modal
import { useUser } from '../Integrations/UserContext'; // Assuming you have a user context
import { db } from '../firebase-config';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";

function HomePage() {
    const [hasSeenHome, setHasSeenHome] = useState(false);
    const [showUsernameModal, setShowUsernameModal] = useState(false);
    const [username, setUsername] = useState('');
    const [isTourOpen, setIsTourOpen] = useState(false); // Control the joyride state
    const { user } = useUser(); // Retrieve the current user from UserContext
    const navigate = useNavigate();
    const [usernameAvailable, setUsernameAvailable] = useState(null);
    const auth = getAuth();

    // Use the custom hook to observe the sections
    const [patternInView, patternRef] = useInView(0.3);
    const [visualizationInView, visualizationRef] = useInView(0.4);
    const [drillsInView, drillsRef] = useInView(0.5);
    const [communityInView, communityRef] = useInView(0.6);

useEffect(() => {
    if (username.trim().length > 0) {
        const checkUsernameAvailability = async () => {
            const usernamesRef = collection(db, "usernames");
            const q = query(usernamesRef, where("username", "==", username));
            const querySnapshot = await getDocs(q);
            setUsernameAvailable(querySnapshot.empty);
        };
        checkUsernameAvailability();
    } else {
        setUsernameAvailable(null); // Reset availability if input is cleared
    }
}, [username]);

useEffect(() => {
    const initializeUser = async () => {
        if (user) {
            const userDocRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                const data = userDoc.data();
                setUsername(data.username || '');
                setHasSeenHome(data.hasSeenHome || false);
                if (!data.username) {
                    setShowUsernameModal(true);
                } else if (!data.hasSeenHome) {
                    setIsTourOpen(true);
                }
            } else {
                setShowUsernameModal(true);
            }
        }
    };

    initializeUser();
}, [user]);


    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleUsernameSubmit = async () => {
        if (!username.trim()) {
            console.error("Username is required.");
            return;
        }

        try {
            const userDocRef = doc(db, "users", user.uid);
            await setDoc(userDocRef, { username: username, hasSeenHome: true }, { merge: true });
            setShowUsernameModal(false);
            console.log('Username set successfully for user:', user);
        } catch (error) {
            console.error('Error setting username:', error.message);
        }
    };

    const closeModal = () => {
        setShowUsernameModal(false);
    };

    const steps = [
         {
            disableBeacon: true,
            placement: 'center',
            target: '.feature-card',
            title: 'Welcome to Train Chess!',
            content: (
                username ? (
                    <>
                        Welcome {username}, to your new chess training gym for chess improvement! To start, if you’d like to change your username,{' '}
                        <span
                            style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => setShowUsernameModal(true)}
                        >
                            click here
                        </span>.
                    </>
                ) : (
                    'Please set a username to personalize your experience.'
                )
            ),
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.parallax-section', // CSS selector for the main banner section
            content: 'Train Chess is a webpage dedicated to providing proven tools for improving at chess',
        },
        {
            disableBeacon: true,
            placement: 'auto',
            target: '.home-features',
            content: 'These are the main features of Train Chess. Each card represents a different exercise or tool. Click on them to start training!',
        },
        {
            disableBeacon: true,
            target: '.tactics-trainer',
            content: 'Some of our unique features include our unique algorithm that gives you tactical puzzles of optimal difficulty for improvement.',
        },
        {
            disableBeacon: true,
            target: '.blindfold-chess',
            content: 'Six progressive  steps for learning to play blindfold chess.',
        },
        {
            disableBeacon: true,
            target: '.calculation-puzzles',
            content: 'Tactical foresight puzzles to practice evaluating positions a few moves ahead.',
        },
        {
            disableBeacon: true,
            target: '.team-chess',
            content: 'Ongoing collaborative chess games where you can learn from your peers in a competitive rivalry.',
        },
        {
            disableBeacon: true,
            placement: 'center',
            target: '.parallax-section', // CSS selector for the main banner section
            content: (
                <>
                    And much more! Check it out yourself and{' '}
                    <a
                        href="/contact"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                    >
                        contact us
                    </a>{' '}
                    with any questions or concerns.
                </>
            ),
        },

    ];

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login'); // Redirect to login page after logout
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

    return (
        <div className="home-page">
            {isTourOpen && (
                <JoyrideWrapper
                    steps={steps}
                    run={isTourOpen}
                    onFinish={async () => {
                        try {
                            // Close tour UI and set local state
                            setIsTourOpen(false);
                            setHasSeenHome(true);

                            // Update Firestore to set hasSeenHome to true for the user
                            if (user) {
                                const userDocRef = doc(db, "users", user.uid);
                                await setDoc(userDocRef, { hasSeenHome: true }, { merge: true });
                                console.log("hasSeenHome set to true in Firestore");
                            }
                        } catch (error) {
                            console.error("Error updating hasSeenHome in Firestore:", error.message);
                        }
                    }}
                />
            )}
            {showUsernameModal && (
                <div className="custom-modal">
                    <div className="modal-content">
                        <h2>Set Your Username</h2>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                            required
                        />
                        {usernameAvailable === false && <p className="error-message">Username is not available. Please choose another one.</p>}
                        {usernameAvailable === true && <p className="success-message">Username is available!</p>}

                        <div className="modal-buttons">
                            <button className="modal-close-button" onClick={handleUsernameSubmit}>Save Username</button>
                            <button className="modal-close-button" onClick={closeModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            <EnableScrollOnPage />
            <div
                className="parallax-section"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/checkeredBackground.png)`,
                }}
            >
            <div style={{ position: 'relative', height: '100vh' }}>
                </div>
                <div className="title-container">
                    <img className="logo" src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
                    <h1 className="home-title">
                        <span className="title-bold">TRAIN</span> <span className="title-thin">CHESS</span>
                    </h1>
                </div>
                <div className="welcome-section">
                    <button
                        className="home-btn start-btn"
                        onClick={() => {
                            if (user) {
                                setIsTourOpen(true);
                            } else {
                                window.location.href = '/login'; // Redirect to login page
                            }
                        }}
                    >
                        Get Started
                    </button>
                </div>
            </div>

            {/* Four Columns for Features */}
            <div className="home-features">
                {/* Pattern Recognition Section */}
                <div className={`feature ${patternInView ? 'fade-in' : ''}`} ref={patternRef}>
                    <h2 className="feature-header">Pattern Recognition</h2>
                    <ul className="feature-details">
                        <li className="feature-card tactics-trainer" onClick={() => handleNavigation('/puzzles')}>
                            <h3>Tactics Trainer</h3>
                            <p>Solve critical positions to sharpen your tactical recognition skills.</p>
                        </li>
                        <li className="feature-card" onClick={() => handleNavigation('/gameanalysis')}>
                            <h3>Game Analysis</h3>
                            <p>Reflect on and analyze your chess games to learn from your mistakes.</p>
                        </li>
                        <li className="feature-card" onClick={() => handleNavigation('/findthefork')}>
                            <h3>Find the Fork</h3>
                            <p>Learn to recognize forking patterns by quickly solving targeted exercises.</p>
                        </li>
                    </ul>
                </div>

                {/* Visualization Section */}
                <div className={`feature ${visualizationInView ? 'fade-in' : ''}`} ref={visualizationRef}>
                    <h2 className="feature-header">Visualization</h2>
                    <ul className="feature-details">
                        <li className="feature-card blindfold-chess" onClick={() => handleNavigation('/play')}>
                            <h3>Blindfold Chess</h3>
                            <p>Work up to playing blindfold chess in manageable progressive steps.</p>
                        </li>
                        <li className="feature-card" onClick={() => handleNavigation('/blindfoldpuzzles')}>
                            <h3>Blindfold Puzzles</h3>
                            <p>Solve puzzles given only the locations of each piece. No visual board.</p>
                        </li>
                        <li className="feature-card calculation-puzzles" onClick={() => handleNavigation('/calculationpuzzles')}>
                            <h3>Calculation Practice</h3>
                            <p>Solve a position several moves before it is reached.</p>
                        </li>
                        <li className="feature-card" onClick={() => handleNavigation('/coordinatetrainer')}>
                            <h3>Coordinate Trainer</h3>
                            <p>Practice identifying squares and coordinates to help you learn chess notation.</p>
                        </li>
                    </ul>
                </div>

                {/* Drills Section */}
                <div className={`feature ${drillsInView ? 'fade-in' : ''}`} ref={drillsRef}>
                    <h2 className="feature-header">Theory</h2>
                    <ul className="feature-details">
                        <li className="feature-card" onClick={() => handleNavigation('/openingtrainer')}>
                            <h3>Opening Trainer</h3>
                            <p>Develop and practice your own opening repertoire.</p>
                        </li>
                    </ul>
                    <ul className="feature-details">
                        <li className="feature-card" onClick={() => handleNavigation('/endgames')}>
                            <h3>Endgame Drills</h3>
                            <p>Practice converting common endgame positions.</p>
                        </li>
                    </ul>
                </div>

                {/* Community Section */}
                <div className={`feature ${communityInView ? 'fade-in' : ''}`} ref={communityRef}>
                    <h2 className="feature-header">Community</h2>
                    <ul className="feature-details">
                        <li className="feature-card team-chess" onClick={() => handleNavigation('/teamchess')}>
                            <h3>Team Chess</h3>
                            <p>Play and learn with your chosen team in a collaborative chess game.</p>
                        </li>
                        <li className="feature-card" onClick={() => handleNavigation('/club')}>
                            <h3>Chess.com Club</h3>
                            <p>Connect with our club on chess.com. Follow and join club matches and events.</p>
                        </li>
                    </ul>
                </div>
            </div>
            <footer className="footer">
                <p>
                    TrainChess.net
                    <a href="/privacypolicy" className="footer-link">Privacy Policy</a>
                </p>
            </footer>
        </div>
    );
}

export default HomePage;
