import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { useUser } from './../UserContext'; // Ensure useUser provides the username
import '../../styles/Header.css';

function DesktopHeader() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isVisualizationDropdownOpen, setIsVisualizationDropdownOpen] = useState(false);
  const [isPatternDropdownOpen, setIsPatternDropdownOpen] = useState(false);
  const [isDrillsDropdownOpen, setIsDrillsDropdownOpen] = useState(false);
  const [isCommunityDropdownOpen, setIsCommunityDropdownOpen] = useState(false);

  const navigate = useNavigate();
  const { username, user } = useUser(); // Retrieve the username from the user context
  const auth = getAuth();

  // Handles user logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login'); // Redirect to login page after logout
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

  // Handle hover events for desktop dropdowns
  const handleVisualizationMouseEnter = () => setIsVisualizationDropdownOpen(true);
  const handleVisualizationMouseLeave = () => setIsVisualizationDropdownOpen(false);

  const handlePatternMouseEnter = () => setIsPatternDropdownOpen(true);
  const handlePatternMouseLeave = () => setIsPatternDropdownOpen(false);

  const handleDrillsMouseEnter = () => setIsDrillsDropdownOpen(true);
  const handleDrillsMouseLeave = () => setIsDrillsDropdownOpen(false);

  const handleCommunityMouseEnter = () => setIsCommunityDropdownOpen(true);
  const handleCommunityMouseLeave = () => setIsCommunityDropdownOpen(false);

  return (
    <header className="header">
      <nav className="header-nav">
        <Link to="/" className="header-link">
          <button className="header-button">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
          </button>
        </Link>

        {/* Profile Dropdown */}
        <div className="profile-dropdown">
          <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="profile-button">
            <img src={`${process.env.PUBLIC_URL}/buttons/profile.png`} alt="Profile" />
          </button>
          {isDropdownOpen && (
            <div className="dropdown-content">
              {user ? (
                <>
                  <span className="bold-text">{username}</span> {/* Shows username */}
                  <Link to="/settings">Settings</Link>
                  <button onClick={handleLogout}>Log Out</button>
                </>
              ) : (
                <>
                  <span className="bold-text">Guest</span> {/* Shows Guest if no username */}
                  <Link to="/login">Log In</Link>
                </>
              )}
            </div>
          )}
        </div>

        {/* Pattern Recognition Dropdown (Desktop) */}
        <div className="pages-dropdown"
             onMouseEnter={handlePatternMouseEnter}
             onMouseLeave={handlePatternMouseLeave}>
          <button className='dropdown-button'>
            Pattern Recognition
          </button>
          {isPatternDropdownOpen && (
            <div className="dropdown-content2 show">
              <Link to="/puzzles">Tactics Trainer</Link>
              <Link to="/gameanalysis">Game Analysis</Link>
              <Link to="/findthefork">Find the Fork</Link>
            </div>
          )}
        </div>

        {/* Visualization Dropdown (Desktop) */}
        <div className="pages-dropdown"
             onMouseEnter={handleVisualizationMouseEnter}
             onMouseLeave={handleVisualizationMouseLeave}>
          <button className='dropdown-button'>
            Visualization
          </button>
          {isVisualizationDropdownOpen && (
            <div className="dropdown-content2 show">
              <Link to="/play">Blindfold Chess</Link>
              <Link to="/blindfoldpuzzles">Blindfold Puzzles</Link>
              <Link to="/calculationpuzzles">Calculation Practice</Link>
              <Link to="/coordinatetrainer">Coordinate Trainer</Link>
            </div>
          )}
        </div>

        {/* Drills Dropdown (Desktop) */}
        <div className="pages-dropdown"
             onMouseEnter={handleDrillsMouseEnter}
             onMouseLeave={handleDrillsMouseLeave}>
          <button className='dropdown-button'>
            Theory
          </button>
          {isDrillsDropdownOpen && (
            <div className="dropdown-content2 show">
              <Link to="/openingtrainer">Opening Trainer</Link>
              <Link to="/endgames">Endgame Drills</Link>
            </div>
          )}
        </div>

        {/* Community Dropdown (Desktop) */}
        <div className="pages-dropdown"
             onMouseEnter={handleCommunityMouseEnter}
             onMouseLeave={handleCommunityMouseLeave}>
          <button className='dropdown-button'>
            Community
          </button>
          {isCommunityDropdownOpen && (
            <div className="dropdown-content2 show">
              <Link to="/teamchess">Team Chess</Link>
              <Link to="/club">Chess.com Club</Link>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export default DesktopHeader;
