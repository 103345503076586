import React, { useState, useEffect } from "react";
import GameStats from './GameStats';
import { useGameState } from "../Integrations/GameStateContext";
import KnightHopLoadingScreen from '../Integrations/LoadingScreen';
import { Link } from "react-router-dom";
import { Chess } from 'chess.js';
import { useUser } from './UserContext';
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from '../firebase-config';

const PromptBox = ({ moveIndex, onMoveChange, game, onAiCheckChange, sidelines, playerColor, onlyUserMoves, setOnlyUserMoves, setShowSaveModal, flagsForReview, setFlagsForReview }) => {
  const { user } = useUser();
  const [userRatings, setUserRatings] = useState({});
  const [userComments, setUserComments] = useState({});
  const [revisedComments, setRevisedComments] = useState({});
  const [checkingWithAi, setCheckingWithAi] = useState(false);
  const [problematicMoves, setProblematicMoves] = useState([]);
  const [currentProblemIndex, setCurrentProblemIndex] = useState(0);
  const [lastOpeningMove, setLastOpeningMove] = useState(null);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { gameState, updateGameState } = useGameState();
  const [isLoading, setIsLoading] = useState(false);
  const [userMoveIndices, setUserMoveIndices] = useState([]);
  const [currentUserMoveIndex, setCurrentUserMoveIndex] = useState(0);

  const closeModal = () => {
    setShowStatsModal(false);
    setCurrentProblemIndex(0);
    onMoveChange(problematicMoves[0]);
  };

const handleFlagForReviewChange = (checked) => {
//  console.log(`Flag for review change detected. Move Index: ${moveIndex}, Checked: ${checked}`);

  setFlagsForReview((prev) => {
    const updatedFlags = {
      ...prev,
      [moveIndex]: checked,
    };
//    console.log("Updated flags for review:", updatedFlags);
    return updatedFlags;
  });
};

////////////////////////////////////// Navigating first review moves

  useEffect(() => {
    if (!game) return;

    // Identify the indices of the user's moves
    const isWhitePlayer = playerColor === "White"; // Adjust this based on the player's actual color
    const userMoves = [];
    const history = game.history({ verbose: true });

    history.forEach((move, index) => {
      const isUserMove = (isWhitePlayer && index % 2 === 0) || (!isWhitePlayer && index % 2 !== 0);
      if (isUserMove) {
        userMoves.push(index);
      }
    });

    setUserMoveIndices(userMoves);

    // If the game starts or resets, set the current user move index to 0
    if (userMoves.length > 0) {
      setCurrentUserMoveIndex(0);
      onMoveChange(userMoves[0]); // Set the first user move as the initial move to review
    }
  }, [game]);

const goToNextUserMove = () => {
  // Find the next user move index that occurs after the current moveIndex
  const nextUserMove = userMoveIndices.find((idx) => idx > moveIndex);

  if (nextUserMove !== undefined) {
    // Update currentUserMoveIndex based on the found user move
    const nextIndex = userMoveIndices.indexOf(nextUserMove);
    setCurrentUserMoveIndex(nextIndex);
    onMoveChange(nextUserMove);
  } else {
    alert("You have reviewed all your moves.");
  }
};

const goToPreviousUserMove = () => {
  // Find the previous user move index that occurs before the current moveIndex
  // We go in reverse order and find the last user move that is less than the current moveIndex
  let prevUserMove = undefined;
  for (let i = userMoveIndices.length - 1; i >= 0; i--) {
    if (userMoveIndices[i] < moveIndex) {
      prevUserMove = userMoveIndices[i];
      break;
    }
  }

  if (prevUserMove !== undefined) {
    const prevIndex = userMoveIndices.indexOf(prevUserMove);
    setCurrentUserMoveIndex(prevIndex);
    onMoveChange(prevUserMove);
  } else {
    alert("You are at the first move.");
  }
};

////////////////////////////////////// Navigating first review moves


// Populate userRatings when the component mounts
useEffect(() => {
  if (game) {
    console.log("game: " + JSON.stringify(game));
    try {
      const historyLength = game.history().length; // Get the number of moves in the game history
      const initialRatings = {};

      for (let i = 0; i < historyLength; i++) {
        initialRatings[i] = 5; // Initialize all ratings to 5
      }

      setUserRatings(initialRatings);
    } catch (error) {
      console.error("Error accessing game history:", error);
    }
  } else {
    console.error("Game structure is invalid or moves are missing.");
  }
}, [game]);


  const handleRatingChange = (rating) => {
    setUserRatings((prev) => ({
      ...prev,
      [moveIndex]: rating,
    }));
  };

  const handleCommentChange = (comment) => {
    setUserComments((prev) => ({
      ...prev,
      [moveIndex]: comment,
    }));
  };

  const handleRevisedCommentChange = (comment) => {
    setRevisedComments((prev) => ({
      ...prev,
      [moveIndex]: comment,
    }));
  };

// Populate userRatings when the component mounts
useEffect(() => {
  if (isLoading) {
    handleCheckWithAI();
    setIsLoading(false);
  }
}, [gameState.analysis]);

const handleCheckWithAI = () => {
  if (!isLoading) {
    const confirmation = window.confirm(
      "Are you confident with your analysis? Once checked, discrepancies will be highlighted."
    );
    if (!confirmation) return;
  }

  if (!gameState.analysis || gameState.analysis.length === 0) {
    setIsLoading(true);
    return;
  }

  if (lastOpeningMove === null) {
    alert("Please mark the end of your opening preparation before proceeding.");
    return;
  }

  const problems = [];
  let totalMoves = 0;
  let totalUserRating = 0;

  console.log("Starting AI check...");
  console.log("Last opening move index:", lastOpeningMove);

  gameState.analysis.forEach((move, index) => {
    if (index <= lastOpeningMove) return; // Skip moves before the opening point

    const aiRating = move.rating || 5; // Default AI rating is 5
    const userRating = userRatings[index] || 5; // Default user rating is 5
    const difference = Math.abs(aiRating - userRating);

    // If onlyUserMoves is enabled, ensure the index corresponds to the user's turn
    const isUserMove = index % 2 === (gameState.playerColor === "White" ? 0 : 1);
    if (onlyUserMoves && !isUserMove) return;

    totalMoves++;
    totalUserRating += userRating;

    if (difference >= 2) {
      problems.push(index); // Add problematic move indices
      console.log(`Problematic move added: Index ${index}, AI rating: ${aiRating}, User rating: ${userRating}`);
    }
  });

  // Combine flagged moves from `flagsForReview` with problematic moves
  console.log("Flagging moves from flagsForReview...");
  const flaggedMoves = Object.keys(flagsForReview)
    .filter((key) => flagsForReview[key]) // Only include flagged moves
    .map(Number); // Convert keys to integers

  console.log("Problems before combining:", problems);
  console.log("Flagged moves:", flaggedMoves);

  const combinedProblems = [...new Set([...problems, ...flaggedMoves])]; // Merge and remove duplicates

  console.log("Combined problematic moves:", combinedProblems);

  const averageUserRating = totalMoves > 0 ? (totalUserRating / totalMoves).toFixed(2) : "N/A";

  // Set problematic moves and show the stats modal
  setProblematicMoves(combinedProblems);
  setCheckingWithAi(true);

  const isCheckingWithAi = true; // Dynamic value for state
  if (onAiCheckChange) {
    onAiCheckChange(isCheckingWithAi); // Notify parent component
  }

  setCurrentProblemIndex(0);

  setModalContent({
    totalMoves,
    averageUserRating,
    numProblematicMoves: combinedProblems.length,
  });

  console.log("AI check complete. Problematic moves set:", combinedProblems);

  setShowStatsModal(true); // Open the modal
};


  const goToNextProblem = () => {
    if (currentProblemIndex < problematicMoves.length - 1) {
      const nextIndex = currentProblemIndex + 1;
      setCurrentProblemIndex(nextIndex);
      onMoveChange(problematicMoves[nextIndex]); // Navigate to the next problematic move
    } else {
      alert("You have reviewed all problematic moves.");
    }
  };

const generateDynamicPlaceholder = () => {
  const aiRating = gameState.analysis[moveIndex]?.rating || 5; // AI rating for the current move
  const userRating = userRatings[moveIndex] || 5; // User rating for the current move
  const aiBestMove = gameState.analysis[moveIndex]?.recommendedMove?.algebraic || "N/A"; // Opponent's best move in algebraic
  const userBestMove =
    moveIndex > 0
      ? gameState.analysis[moveIndex - 1]?.recommendedMove?.algebraic || "N/A"
      : "N/A"; // User's best move (previous move in algebraic)

  const ratingDifference = aiRating - userRating;
    const isMoveBetter = ratingDifference < 0;
    const isMoveWorse = ratingDifference > 0;
    const isMoveEqual = ratingDifference === 0;

    const context = isMoveBetter
      ? "You rated this move better than AI. Why did you think this move was good? What might the misunderstanding be?"
      : isMoveWorse
      ? "AI rated this move better than you. Why did you think this move was bad? What might the misunderstanding be?"
      : "Your rating matches the AI's. Great job! Why do you think this move was evaluated the same?";
      const bestMoveContext = `Best Move: ${userBestMove} | Opponent's Best Response: ${aiBestMove}`;

  return `${context}`;
};

const generateDynamicQuestion = () => {
  // Determine if it's the user's move
const playerColor = gameState.playerColor || "White"; // Default to "White" if undefined
const isUserMove = moveIndex % 2 === (playerColor === "White" ? 0 : 1);

console.log("Player color:", playerColor);
console.log("Move index:", moveIndex % 2);
console.log("Is user move:", isUserMove);

  // Check for opening prep end index
  if (moveIndex === gameState.openingPrepEndIndex) {
    console.log("Move is the opening prep end index.");
    return "You marked this as the end of your opening preparation. What are your middlegame plans from this position?";
  }

  // Determine context based on move type
  const context = isUserMove
    ? "Why did you make this move? What were you trying to achieve?"
    : "Why do you think your opponent played this move? What might their plans be?";

  return `${context}`;
};

const convertToAlgebraic = (move, chessInstance) => {
  if (!move || !chessInstance) return "N/A";
  try {
    // Generate all legal moves for the current position
    const moves = chessInstance.moves({ verbose: true });

    // Find the matching move in verbose format
    const matchingMove = moves.find(
      (m) => `${m.from}${m.to}` === move || m.san === move
    );

    // Return the algebraic notation (SAN) if found
    return matchingMove ? matchingMove.san : "N/A";
  } catch (err) {
    console.error("Error converting move to algebraic notation:", err);
    return "N/A";
  }
};


const currentAnalysis = gameState.analysis[moveIndex] || {};
const { evaluation, rating: aiRating = 5, recommendedMove = {} } = currentAnalysis;
const recommendedMoveStandard = recommendedMove?.standard || "N/A";

const previousMoveIndex = moveIndex > 0 ? moveIndex - 1 : null;
const recommendedMoveAlgebraic =
  previousMoveIndex !== null
    ? gameState.analysis[previousMoveIndex]?.recommendedMove?.algebraic || "N/A"
    : "N/A";

const moveNumber = Math.floor(moveIndex / 2) + 1;
const movePrefix = moveIndex % 2 === 0 ? `${moveNumber}.` : `${moveNumber}...`;

// Get the algebraic notation of the move from the analysis or game data
const currentMove = game.history()[moveIndex] || "";

const handleMarkOpeningEnd = (moveIndex) => {
  setLastOpeningMove(moveIndex);

  updateGameState({ openingPrepEndIndex: moveIndex });

  console.log(`Opening preparation ends set at move: ${moveIndex}`);
};

const getPartialGameData = (game, moveIndex) => {
  // Create a new Chess instance
  const partialGame = new Chess();

  // Get the full move history from the current game
  const fullHistory = game.history();

  // Replay moves up to moveIndex
  fullHistory.slice(0, moveIndex + 1).forEach((move) => {
    partialGame.move(move);
  });

  // Return the PGN representation
  const partialPgn = partialGame.pgn();
  console.log('partial pgn: ' + partialPgn)
  return partialPgn;
};

const savePosition = async () => {
  try {
    // Check if user is logged in
    if (!user) {
      throw new Error("User not logged in");
    }

    const userId = user.uid;
    const userDocRef = doc(db, "users", userId);

    // Check if the user document exists
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      throw new Error(`User document for ${userId} does not exist.`);
    }

    // Create a new Chess instance and replay moves from gameState.analysis
    const chess = new Chess();
    const history = gameState.analysis; // Assume this contains move history
    if (!history || history.length === 0) {
      throw new Error("No move history found in gameState.analysis.");
    }

    for (let i = 0; i <= moveIndex; i++) {
      const move = history[i];
      if (move && move.from && move.to) {
        chess.move({
          from: move.from,
          to: move.to,
          promotion: move.promotion || undefined, // Handle promotion if needed
        });
      } else {
        console.warn(`Skipping invalid move at index ${i}:`, move);
      }
    }

    // Get the FEN of the position after replaying moves
    const currentFen = chess.fen();

    // Prepare the position to save
    const newPosition = {
      fen: currentFen,
      target: "checkmate", // Default target
      timestamp: new Date().toISOString(), // Optional: Add a timestamp
    };

    // Save the position to Firestore
    await updateDoc(userDocRef, {
      savedpositions: arrayUnion(newPosition), // Add the new position
    });

    console.log("Position saved successfully to Firestore!");
  } catch (error) {
    console.error("Error saving position to Firestore:", error.message);
  }
};

useEffect(() => {
  // Sync userRatings, userComments, and revisedComments to gameState
  updateGameState({
    userRatings,
    userComments,
    revisedComments,
    sidelines,
    onlyUserMoves
  });
  console.log('updated game state');
}, [userRatings, userComments, revisedComments, sidelines, onlyUserMoves]);

return (
  <div className="prompt-box">
    {isLoading && <KnightHopLoadingScreen />}
    <GameStats
      isOpen={showStatsModal}
      onClose={closeModal}
      userRatings={userRatings}
      flagsForReview={flagsForReview}
    />
    {lastOpeningMove === null ? (
      // Opening Marker UI
      <div>
        <label>
          <input
            className='checkbox'
            type="checkbox"
            checked={onlyUserMoves}
            onChange={(e) => setOnlyUserMoves(e.target.checked)}
          />
          Review Only My Moves
        </label>
        <hr className="line-before-button" />
        <h4>Mark the End of Your Opening Preparation</h4>
        <hr className="line" />
        <p>
          Navigate to the move where your opening preparation ended and press the button below.
        </p>
        <hr className="line" />
        <button
          className="average-button"
          onClick={() => handleMarkOpeningEnd(moveIndex)}
        >
          Mark Opening End
        </button>
      </div>
    ) : (
      // Existing Prompt UI
      <>
        {moveIndex === -1 ? (
          // Message for move index 0
          <div>
            <h4>Let's Start Analyzing!</h4>
            <hr className="line-before-button" />
            <p>Navigate through the game and rate each move 1-5 stars based on your understanding. Add comments about your thought process. </p>
            <p>When you're ready, click "Check with AI" to identify any discrepencies in your analysis.</p>
          </div>
        ) : (
          // Rating UI for other moves
          <>
          {!checkingWithAi && (
          <div className="flag-review-container">
            <label htmlFor={`flagForReview-${moveIndex}`} className="flag-review-label">
              Flag for Review
            </label>
            <input
              type="checkbox"
              id={`flagForReview-${moveIndex}`}
              className="checkbox"
              checked={flagsForReview[moveIndex] || false}
              onChange={(e) => handleFlagForReviewChange(e.target.checked)}
            />
          </div>
          )}
            <h4>Rate Move {movePrefix} {currentMove}</h4>
            {!checkingWithAi ? (
              <>
                <div className="stars">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`star ${userRatings[moveIndex] >= star ? "selected" : ""}`}
                      onClick={() => handleRatingChange(star)}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <textarea
                  placeholder={generateDynamicQuestion()} // Dynamically set the placeholder
                  value={userComments[moveIndex] || ""}
                  onChange={(e) => handleCommentChange(e.target.value)}
                />

                {onlyUserMoves ? (
                  <div className="navigation-buttons">
                    <button
                      className="subtle-button"
                      onClick={goToPreviousUserMove}
                      disabled={currentUserMoveIndex === 0}
                    >
                      Back
                    </button>
                    <button
                      className="subtle-button"
                      onClick={goToNextUserMove}
                      disabled={currentUserMoveIndex === userMoveIndices.length - 1}
                    >
                      Next
                    </button>
                  </div>
                ) : (
                  <div className="navigation-buttons">
                    <button
                      className="subtle-button"
                      onClick={() => {
                        if (moveIndex > 0) {
                          onMoveChange(moveIndex - 1); // Go to the previous move
                        } else {
                          alert("You are at the first move.");
                        }
                      }}
                      disabled={moveIndex === 0}
                    >
                      Back
                    </button>
                    <button
                      className="subtle-button"
                      onClick={() => {
                        if (moveIndex < game.history().length - 1) {
                          onMoveChange(moveIndex + 1); // Go to the next move
                        } else {
                          alert("You have reached the last move.");
                        }
                      }}
                      disabled={moveIndex === game.history().length - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
                {moveIndex === gameState.openingPrepEndIndex && (
                  <div>
                    <a
                      className="opening-trainer-link"
                      href={`/openingtrainer?gameData=${encodeURIComponent(JSON.stringify(getPartialGameData(game, moveIndex)))}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open in Opening Trainer
                    </a>
                  </div>
                )}

                {(
                  onlyUserMoves
                    ? moveIndex === userMoveIndices[userMoveIndices.length - 1]
                    : moveIndex === game.history().length - 1
                ) && (
                  <button className="average-button" onClick={handleCheckWithAI}>
                    Check with Engine
                  </button>
                )}

              </>
            ) : (
              <>
                <h5>User Rating</h5>
                <div className="stars user-rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`star ${userRatings[moveIndex] >= star ? "selected" : ""}`}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <p>{userComments[moveIndex] || "No comment provided."}</p>

                <h5>AI Rating</h5>
                <div className="stars ai-rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`star ${aiRating >= star ? "selected" : ""}`}
                    >
                      ★
                    </span>
                  ))}
                </div>
                <p>Recommended Move: {recommendedMoveAlgebraic || "N/A"}</p>
                <textarea
                  placeholder={generateDynamicPlaceholder()}
                  value={revisedComments[moveIndex] || ""}
                  onChange={(e) => handleRevisedCommentChange(e.target.value)}
                />
                <div className="problem-navigation">
                  <p>
                    Problem {currentProblemIndex + 1} of {problematicMoves.length}
                  </p>
                  <hr className="line-before-button" />
                  <div className="navigation-buttons">
                      <button
                        className="subtle-button"
                        onClick={() => {
                          const prevIndex = currentProblemIndex - 1;
                          setCurrentProblemIndex(prevIndex);
                          onMoveChange(problematicMoves[prevIndex]); // Navigate to the previous problematic move
                        }}
                        disabled={currentProblemIndex === 0}
                      >
                        Back
                      </button>
                      <button
                        className="subtle-button"
                        onClick={() => {
                          const nextIndex = currentProblemIndex + 1;
                          setCurrentProblemIndex(nextIndex);
                          onMoveChange(problematicMoves[nextIndex]); // Navigate to the next problematic move
                        }}
                        disabled={currentProblemIndex >= problematicMoves.length - 1}
                      >
                        Next
                      </button>
                    <button
                      className="subtle-button"
                      onClick={() => {
                        setShowStatsModal(true);
                      }}
                    >
                      Analysis
                    </button>
                    <div>
                    <button
                      className="opening-trainer-link"
                      onClick={savePosition}
                    >
                      Save Position
                    </button>
                  </div>

                    {(currentProblemIndex === problematicMoves.length - 1) && ( // End of all moves
                      <button
                        className="average-button"
                        onClick={setShowSaveModal}
                      >
                        Finish and Save Game Analysis
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    )}
  </div>
);

};

export default PromptBox;
