import React, { useState, useEffect, useRef } from "react";
import ChessboardCustom from "../components/ChessboardCustom";
import { Chess } from "chess.js";
import PromotionModal from "../Integrations/PromotionModal";
import { renderMoveHistory } from "../Integrations/MoveHistoryTable";
import { highlightLastMove, onSquareClick, onDrop, renderCapturedPieces, handleTakeBackMove, updateLevelProgress } from "../Integrations/helperFunctions";
import { useUser } from '../Integrations/UserContext';
import { useNavigate } from "react-router-dom"; // Import navigate hook
import ShareModal from "./ShareModal";

const EndgameBoard = ({ initialFen, currentGame, levelDetailsRef, openSelection, handleNextPuzzle }) => {
  const gameRef = useRef(new Chess(initialFen || "start"));
  const [displayFen, setDisplayFen] = useState(initialFen || "start");
  const [orientation, setOrientation] = useState(currentGame.settings.playerColor);
  const [squareStyles, setSquareStyles] = useState({});
  const [promotionOpen, setPromotionOpen] = useState(false);
  const [promotionDetails, setPromotionDetails] = useState(null);
  const [playerColor, setPlayerColor] = useState(currentGame.settings.playerColor);
  const [selectedMoveIndex, setSelectedMoveIndex] = useState(-1);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const moveHistoryRef = useRef(null);
  const [pieceSquare, setPieceSquare] = useState('');
  const restoreSquareStyles = useRef({});
  const stockfishRef = useRef(null); // Ref for the Stockfish worker
  const [isGameOver, setIsGameOver] = useState(false);
  const [gameResult, setGameResult] = useState(null); // Stores the result of the game
  const { user } = useUser();
  const stockfishColor = currentGame.settings.playerColor === "white" ? "b" : "w";
  const [usedAssistance, setUsedAssistance] = useState(false);
  const [evaluation, setEvaluation] = useState(null);
  const [evaluationStatus, setEvaluationStatus] = useState("neutral");
  const evalRef = useRef(null); // New reference for evaluation worker
  const navigate = useNavigate(); // React Router hook
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

// Update modal state when game ends
useEffect(() => {
  if (isGameOver) {
    setModalMessage(
      gameResult === "Checkmate" || gameResult === "Draw"
        ? "Success! Puzzle Complete!"
        : "Failure! Try Again."
    );
    setIsModalOpen(true);
  }
}, [isGameOver, gameResult]);

// Function to close the modal
const closeModal = () => {
  setIsModalOpen(false);
};

// Dynamic evaluation thresholds based on player color
const getThresholds = () => {
  const isWhite = playerColor === "white";

  return {
    targetEvalCP: isWhite ? 1.5 : -1.5, // CP thresholds for evaluation
    targetEvalMate: isWhite ? 0 : 0,   // Mate thresholds (positive for White, negative for Black)
  };
};

// Initialize thresholds dynamically based on player color
let { targetEvalCP, targetEvalMate } = getThresholds();

// Track evaluation depth and stable evaluation
const [evalDepth, setEvalDepth] = useState(0);
const [stableEval, setStableEval] = useState(null); // Stable evaluation only after depth ≥ 10

// Update evaluation status dynamically
useEffect(() => {
  // Only evaluate if depth is 10 or higher and game is not over
  if (stableEval !== null && evalDepth >= 15 && !isGameOver) {
    if (typeof stableEval === "number") {
      // Handle CP scores
      const isWin = playerColor === "white" ? stableEval > targetEvalCP : stableEval < targetEvalCP;
      const isDraw = Math.abs(stableEval) <= 1.5; // Draw range (closer to 0)
      const isLoss = playerColor === "white" ? stableEval < -1.5 : stableEval > 1.5;

      if (
        (currentGame.settings.target === "checkmate" && isWin) || // Win
        (currentGame.settings.target === "draw" && isDraw) ||     // Draw
        (currentGame.settings.target === "loss" && isLoss)        // Loss
      ) {
        setEvaluationStatus("on-track");
//        console.log("Evaluation Status Updated: on-track (CP)");
      } else {
        setEvaluationStatus("off-track");
//        console.log("Evaluation Status Updated: off-track (CP)");
      }
    } else if (typeof stableEval === "string" && stableEval.includes("Mate")) {
      // Handle Mate scores
      const mateScore = parseInt(stableEval.split(" ")[2]); // Extract mate moves
      const isMateWin = playerColor === "white" ? mateScore > 0 : mateScore < 0; // Mate win condition
      const isMateLoss = playerColor === "white" ? mateScore < 0 : mateScore > 0; // Mate loss condition

      if (
        (currentGame.settings.target === "checkmate" && isMateWin) || // Win in mate
        (currentGame.settings.target === "loss" && isMateLoss)        // Loss in mate
      ) {
        setEvaluationStatus("on-track");
        console.log("Evaluation Status Updated: on-track (Mate)");
      } else {
        setEvaluationStatus("off-track");
        console.log("Evaluation Status Updated: off-track (Mate)");
      }
    }
  } else if (isGameOver) {
    setEvaluationStatus("game-over");
    console.log("Evaluation Status Updated: game-over");
  }
}, [stableEval, evalDepth, displayFen, currentGame.settings.target, playerColor, isGameOver]);

// Initialize evaluation worker
useEffect(() => {
//  console.log("Initializing Evaluation Worker...");

  const initializeEvalWorker = () => {
    const workerName =
      typeof SharedArrayBuffer !== "undefined"
        ? "stockfish-nnue-16.js"
        : "stockfish-nnue-16-single.js";

//    console.log("Using worker file:", workerName);

    const worker = new Worker(workerName); // Create worker
    return worker;
  };

  evalRef.current = initializeEvalWorker();

  return () => {
    if (evalRef.current) {
//      console.log("Terminating Evaluation Worker...");
      evalRef.current.terminate();
    }
  };
}, []);

// Listen for evaluation results
useEffect(() => {
  if (!evalRef.current) {
    console.warn("Evaluation worker is not initialized!");
    return;
  }

  evalRef.current.onmessage = (e) => {
    // Parse evaluation depth
    const depthMatch = e.data.match(/depth (\d+)/);
    if (depthMatch) {
      const depth = parseInt(depthMatch[1]);
      setEvalDepth(depth); // Update evaluation depth
    }

    // Handle CP scores
    const evalMatchCP = e.data.match(/score cp (-?\d+)/);
    // Handle Mate scores
    const evalMatchMate = e.data.match(/score mate (-?\d+)/);

    if (evalMatchCP) {
      const evalScore = parseInt(evalMatchCP[1]) / 100; // Convert CP to decimal
      setEvaluation(evalScore); // Update evaluation temporarily

      // Update stable evaluation only when depth >= 10
      if (evalDepth >= 10) {
        setStableEval(evalScore); // Commit stable evaluation
      }
    } else if (evalMatchMate) {
      const mateScore = parseInt(evalMatchMate[1]); // Extract mate moves
      const mateEval = mateScore > 0 ? `Mate in ${mateScore}` : `Mate in ${Math.abs(mateScore)}`;
      setEvaluation(mateEval); // Update evaluation temporarily

      // Update stable evaluation only when depth >= 10
      if (evalDepth >= 10) {
        setStableEval(mateEval); // Commit stable evaluation
      }
    }
  };
}, [evalDepth]); // Reacts to depth changes

// Cancel previous evaluation and send new one
const sendPositionToEval = (fen) => {
  if (evalRef.current) {
    evalRef.current.postMessage("stop"); // Stop ongoing evaluation

    evalRef.current.postMessage(`position fen ${fen}`);
    evalRef.current.postMessage(`go infinite`); // Infinite depth for evaluation
  } else {
    console.warn("Evaluation worker not available when sending FEN.");
  }
};

// Update evaluation whenever FEN changes
useEffect(() => {
//  console.log("FEN Changed:", displayFen);
  sendPositionToEval(displayFen); // Cancel previous and send new evaluation
}, [displayFen]); // React to FEN changes

const handlePuzzleSelection = () => {
    setIsGameOver(false);
    setGameResult(null);
    setSelectedMoveIndex(-1);
    setSquareStyles({});
    setPieceSquare("");
    setPromotionOpen(false);
    setPromotionDetails(null);
    openSelection();
};

useEffect(() => {
  if (isGameOver) {
    const targetResult = currentGame.settings.target; // Target result
    const gameSuccess = gameResult.toLowerCase() === targetResult.toLowerCase();
    const success = gameSuccess && !usedAssistance;

    console.log("Updating progress...");
    if (user?.uid) {
      const today = new Date();
      const dueDate = levelDetailsRef?.current?.dueDate ? new Date(levelDetailsRef.current.dueDate) : null;

      if (!dueDate || dueDate <= today) {
        // Update progress if there is no due date or if the due date is today or in the past
        updateLevelProgress(
          user.uid,
          currentGame.settings.levelName,
          success,
          currentGame.settings.puzzleNumber
        )
          .then((updatedDetails) => {
            console.log("Level progress updated successfully:", updatedDetails);
            if (updatedDetails) {
              // Directly update the levelDetailsRef with the returned data
              levelDetailsRef.current = updatedDetails;
              console.log("Updated details ref:", levelDetailsRef.current);
            }
          })
          .catch((error) => console.error("Error updating level progress:", error));
      } else {
        console.log("Puzzle is not yet due for review. Skipping progress update.");
      }
    } else {
      console.log("User is not logged in. Skipping progress update.");
    }

  }
}, [isGameOver, gameResult]);

const checkGameOver = () => {
  const game = gameRef.current;
  if (game.isCheckmate()) {
    if (game.turn() === stockfishColor) {
      console.log("Game Over. Player wins.");
      setGameResult("Checkmate");
    } else {
      console.log("Game Over: Checkmate. Winner: Stockfish");
      setGameResult("Loss");
    }
    setIsGameOver(true);
  } else if (game.isStalemate()) {
    console.log("Game Over: Stalemate");
    setGameResult("Draw");
    setIsGameOver(true);
  } else if (game.isInsufficientMaterial()) {
    console.log("Game Over: Insufficient Material");
    setGameResult("Draw");
    setIsGameOver(true);
  } else if (game.isThreefoldRepetition()) {
    console.log("Game Over: Threefold Repetition");
    setGameResult("Draw");
    setIsGameOver(true);
  } else if (game.isDraw()) {
    console.log("Game Over: Draw");
    setGameResult("Draw");
    setIsGameOver(true);
  } else {
    setIsGameOver(false); // Ensure `isGameOver` is reset if the game is not over
    setGameResult(null);  // Clear game result if the game is ongoing
  }
};

  // Initialize Stockfish
  useEffect(() => {
    const initializeStockfish = () => {
      const workerName =
        typeof SharedArrayBuffer !== "undefined"
          ? "stockfish-nnue-16.js"
          : "stockfish-nnue-16-single.js";

      const worker = new Worker(workerName);
      worker.onmessage = (e) => {
//        console.log("Stockfish Worker says:", e.data);
        if (e.data.includes("bestmove")) {
          const bestMove = e.data.split("bestmove ")[1].split(" ")[0];
          if (bestMove) {
            handleStockfishMove(bestMove);
          }
        }
      };
      return worker;
    };

    stockfishRef.current = initializeStockfish();

    return () => {
      stockfishRef.current.terminate(); // Cleanup Stockfish worker
    };
  }, []);

  // Function to send FEN to Stockfish
const sendPositionToStockfish = (fen) => {
  if (stockfishRef.current) {
    console.log("Sending FEN to Stockfish:", fen);

    // Count the number of pieces and check for pawns on the board
    const pieceCount = fen.split(" ")[0].replace(/[^prnbqkPRNBQK]/g, "").length;
    const hasPawn = /[pP]/.test(fen.split(" ")[0]); // Check if the board contains pawns
    console.log("Piece count on board:", pieceCount, "Has pawn:", hasPawn);

    if (pieceCount > 5) {
      // More than 5 pieces: Use nodes for more exhaustive analysis
//      console.log("More than 5 pieces - Using nodes 1000000");
      stockfishRef.current.postMessage(`position fen ${fen}`);
      stockfishRef.current.postMessage(`go nodes 1000000`);
    } else if (!hasPawn) {
      // Less than 5 pieces and no pawns: Use depth 10 for speed
//      console.log("Less than 5 pieces and no pawns - Using depth 10");
      stockfishRef.current.postMessage(`position fen ${fen}`);
      stockfishRef.current.postMessage(`go depth 15`);
    } else {
      // Default fallback: Use nodes 1000000 for other scenarios
//      console.log("Default fallback - Using nodes 1000000");
      stockfishRef.current.postMessage(`position fen ${fen}`);
      stockfishRef.current.postMessage(`go nodes 800000`);
    }
  }
};

  // Handle Stockfish's move
  const handleStockfishMove = (move) => {
//    console.log("Stockfish decided move:", move);
    const from = move.slice(0, 2);
    const to = move.slice(2, 4);
    const promotion = move.length === 5 ? move[4] : null; // Handle promotion if present

    handleMove(from, to, promotion);
  };

  const makeStockfishMove = () => {
    const game = gameRef.current;
    if (game.turn() === stockfishColor) {
      sendPositionToStockfish(game.fen());
    }
  };
//
//  useEffect(() => {
//    makeStockfishMove();
//  }, [game]);


  useEffect(() => {
    highlightLastMove(selectedMoveIndex, gameRef.current, setSquareStyles);
  }, [selectedMoveIndex, displayFen]);

const handleRestart = () => {
  // Reset the game instance stored in the ref
  gameRef.current = new Chess(initialFen || "start");

  // Update all dependent states
  setDisplayFen(gameRef.current.fen()); // Update the displayed FEN
  setSelectedMoveIndex(-1);            // Reset move index
  setSquareStyles({});                 // Clear styles
  setPieceSquare("");                  // Clear selected square
  setPromotionOpen(false);             // Close promotion modal
  setPromotionDetails(null);           // Clear promotion details
  setIsGameOver(false);                // Reset game over flag
  setGameResult(null);                  // Clear game result
  setUsedAssistance(false);             // Clear assistance flag if needed

  // Reset Stockfish
  if (stockfishRef.current) {
    stockfishRef.current.postMessage("ucinewgame"); // Reset Stockfish engine
    stockfishRef.current.postMessage(`position fen ${gameRef.current.fen()}`); // Send new FEN to Stockfish
  }

  // Re-evaluate the position with the evaluation worker
  if (evalRef.current) {
    evalRef.current.postMessage(`position fen ${gameRef.current.fen()}`);
    evalRef.current.postMessage("go depth 20");
  }
};

  const copyToClipboard = (text, message) => {
    navigator.clipboard.writeText(text).then(() => {
      alert(`${message} copied to clipboard!`);
    }).catch((err) => {
      console.error("Failed to copy text:", err);
    });
  };

  // Scroll to the selected move
  const scrollToSelectedMove = () => {
    if (moveHistoryRef.current) {
      const selectedElement = moveHistoryRef.current.querySelector(".selected");
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
      }
    }
  };

  useEffect(() => {
    scrollToSelectedMove();
  }, [selectedMoveIndex]);

const handleTakeBack = () => {
  const game = gameRef.current;

  // Prevent takeback if the game is already over or it's Stockfish's turn
  if (isGameOver || game.turn() === stockfishColor) return;

  // Show confirmation popup only if assistance has not been used yet
  if (!usedAssistance) {
    const confirmTakeBack = window.confirm(
      "Using a take back will mark this puzzle as unsuccessful. Do you want to proceed?"
    );
    if (!confirmTakeBack) return; // Exit if user cancels
  }

  // Mark assistance as used
  setUsedAssistance(true);

  // Perform two takebacks to return to the user's turn
  let movesUndone = 0;
  while (movesUndone < 2 && game.undo()) {
    movesUndone++;
  }

  // Update the board state
  if (movesUndone > 0) {
    setDisplayFen(game.fen());
    setSelectedMoveIndex((prevIndex) => Math.max(prevIndex - movesUndone, 0)); // Adjust move index
    setSquareStyles({});
  } else {
    console.error("No moves to undo.");
  }
};


  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

const handleMove = (from, to, piece = null) => {
  // Perform the move on the referenced game instance
  const move = gameRef.current.move({ from, to, promotion: piece });

  if (move) {
    // Update FEN and state to reflect the new position
    setDisplayFen(gameRef.current.fen());
    setSelectedMoveIndex((prevIndex) => prevIndex + 1);
    setSquareStyles({});
    setPieceSquare("");

    // Check game state after the move
    if (
      gameRef.current.isCheckmate() ||
      gameRef.current.isStalemate() ||
      gameRef.current.isDraw()
    ) {
      checkGameOver(); // Handle game over scenarios
    } else if (gameRef.current.turn() === stockfishColor) {
      makeStockfishMove(); // Trigger Stockfish to make the next move
    }
  } else {
    console.error("Invalid move:", { from, to, piece });
  }
};


const handlePromotion = (piece) => {
  if (promotionDetails) {
    const { pieceSquare, square } = promotionDetails;

    // Temporary game to manage the FEN and moves
    const tempGame = new Chess();
    tempGame.load(displayFen);

    const move = tempGame.move({ from: pieceSquare, to: square, promotion: piece });

    if (!move) {
      console.error("Promotion move could not be applied.");
      return;
    }
    handleMove(pieceSquare, square, piece);

    // Update state after promotion
    setPromotionOpen(false);
    setPromotionDetails(null);
    setDisplayFen(tempGame.fen());
//    console.log("Promotion handled successfully.");
  }
};

  const flipBoard = () => {
    setOrientation((prev) => (prev === "White" ? "Black" : "White"));
  };

const handleResign = () => {
  // Confirm with the user before proceeding
  const confirmResign = window.confirm(
    "Are you sure you want to resign? This will mark the puzzle as unsuccessful."
  );

  if (!confirmResign) {
    // User canceled the resign action
    return;
  }

  // Mark the game as over with a loss
  setGameResult("Loss");
  setIsGameOver(true);
};

const openInAnalyze = () => {
  // Get the current FEN and orientation
  const fenFromElse = currentGame.settings.fen;
  const orientationFromElse =
    currentGame.settings.playerColor.charAt(0).toUpperCase() +
    currentGame.settings.playerColor.slice(1);

  // Preserve the current state with level and puzzle details
  const currentPageState = {
    levelName: currentGame.settings.levelName,
    puzzleNumber: currentGame.settings.puzzleNumber,
  };

  // Navigate to the analyze page with the required data
  navigate("/analyze", {
    state: {
      fenFromElse,           // Current FEN
      orientationFromElse,   // Board orientation
      returnPath: "/endgames", // Path to return to
      currentPageState,      // Current level and puzzle number
    },
  });
};


return (
  <div className="game-layout">
    {promotionOpen && (
      <div className="promotion-modal-container">
        <PromotionModal
          onPromote={handlePromotion}
          pieces={["q", "r", "n", "b"]}
          color={playerColor === "white" ? "w" : "b"}
        />
      </div>
    )}
      <ShareModal
        isOpen={isShareModalOpen}
        closeModal={closeShareModal}
        fen={displayFen}
        pgn={gameRef.current.pgn()}
        copyToClipboard={copyToClipboard}
      />

<div
  className={`game-details-container ${
    (() => {
      // Calculate days until due date
      if (!levelDetailsRef.current.dueDate) return ""; // No border if no due date

      const today = new Date();
      const dueDate = new Date(levelDetailsRef.current.dueDate);
      const differenceInDays = Math.ceil(
        (dueDate - today) / (1000 * 60 * 60 * 24)
      );

      // Determine border color based on due date
      if (differenceInDays < -2) return "border-red"; // Overdue
      if (differenceInDays <= 0) return "border-yellow"; // Due soon
      return "border-green"; // Enough time
    })()
  }`}
>
  {/* Level and Puzzle Number */}
  <div className="info-box game-info">
    <h3 className="info-title">
      {currentGame.settings.levelName} - {currentGame.settings.puzzleNumber}
    </h3>
  </div>

  {/* Target and Evaluation Section */}
  <div
    className={`info-box ${
      isGameOver
        ? gameResult?.toLowerCase() === currentGame.settings.target.toLowerCase()
          ? "highlight-green" // Success
          : "highlight-red" // Failure
        : evaluationStatus === "on-track"
        ? "highlight-green" // On-track
        : "highlight-red" // Off-track
    }`}
  >
    <p className="info-text">
      Goal: {currentGame.settings.target
        .charAt(0)
        .toUpperCase() + currentGame.settings.target.slice(1)}
    </p>
    <p className="info-text">
      {evaluation !== null
        ? typeof evaluation === "number"
          ? `${evaluation.toFixed(2)} CP` // Centipawn evaluation
          : evaluation // Mate score directly (e.g., "Mate in 3")
        : "Calculating..."}
    </p>
  </div>

  {/* Stars and Attempt History */}
  <div className="info-box">
    <div className="stars-container-large">
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          className={`star ${index < levelDetailsRef.current.stars ? "filled" : ""}`}
        >
          ★
        </span>
      ))}
    </div>
    <div className="info-box attempts-container">
      {levelDetailsRef.current.attemptHistory.map((attempt, idx) => (
        <span
          key={idx}
          className={`attempt-box ${
            attempt === "s" ? "success-box" : attempt === "f" ? "failure-box" : ""
          }`}
        ></span> // Empty span for no text inside boxes
      ))}
    </div>

  </div>

  {/* Time Until Due Date */}
  <div className="info-box">
    <p className="info-text">
      {(() => {
        if (!levelDetailsRef.current.dueDate) {
          // Case when no review date is set
          return "Let's get started!";
        }

        const today = new Date();
        const dueDate = new Date(levelDetailsRef.current.dueDate);

        // Handle invalid dueDate
        if (isNaN(dueDate.getTime())) {
          return ""; // Error fallback for invalid dates
        }

        const differenceInDays = Math.ceil(
          (dueDate - today) / (1000 * 60 * 60 * 24)
        );

        if (differenceInDays > 0) {
          return `${differenceInDays} day(s) till review date`;
        } else if (differenceInDays === 0) {
          return "Time to Review!";
        } else {
          return `${Math.abs(differenceInDays)} day(s) past review date`;
        }
      })()}
    </p>

  </div>

{/* Buttons Section */}
<div className="horizontal-row">
  <button className="average-button" onClick={openSelection}>
    Menu
  </button>
  <button className="average-button" onClick={handleRestart}>
    Restart
  </button>
</div>

</div>

{isModalOpen && (
  <div className="modal-overlay">
    <div className="modal-container">
      <h2>{modalMessage}</h2>
      <div className="modal-buttons">
        <button className="average-button" onClick={openSelection}>
          Menu
        </button>
        <button className="average-button" onClick={handleRestart}>
          Restart
        </button>
        <button className="average-button" onClick={handleNextPuzzle}>
          Next Puzzle
        </button>
        <button className="subtle-button" onClick={closeModal}>
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

    <div className="chessboard-container">
      <div className="captures-container-absolute">
        {renderCapturedPieces(displayFen)}
      </div>

      <ChessboardCustom
        position={displayFen}
        orientation={orientation}
        onSquareClick={(square) => {
          if (!isGameOver && gameRef.current.turn() !== stockfishColor) {
            onSquareClick(
              square,
              setPromotionOpen,
              promotionDetails,
              setPromotionDetails,
              gameRef.current,
              pieceSquare,
              setPieceSquare,
              restoreSquareStyles,
              squareStyles,
              setSquareStyles,
              handleMove,
              selectedMoveIndex
            );
          }
        }}

        onDrop={({ sourceSquare, targetSquare }) => {
          if (!isGameOver && gameRef.current.turn() !== stockfishColor) {
            onDrop({
              sourceSquare,
              targetSquare,
              game: gameRef.current,
              setPromotionDetails,
              setPromotionOpen,
              handleMove,
              selectedMoveIndex,
            });
          }
        }}

        squareStyles={squareStyles}
        degreeOfBlindness="Normal"
      />
    </div>

    <div className="move-history-container">
        {renderMoveHistory(
          {
            state: {
              history: gameRef.current.history({ verbose: true }),
              fen: gameRef.current.fen(),
              selectedMoveIndex: selectedMoveIndex,
              isEndgame: true,
              initialFen: initialFen,
            },
            setDisplayFen,
            initialFen,
            setSquareStyles,
            openShareModal,
            flipBoard,
            handleTakeBack,
            setSelectedMoveIndex,
            moveHistoryRef,
            handleResign,
            openInAnalyze,
          },
          gameRef.current.history({ verbose: true }),
          (data) => data,
          [],                 // Sidelines (default as empty array)
          false,              // Sidelines allowed (set to false)
          isGameOver          // Pass isGameOver directly as a prop
        )}
    </div>
  </div>
);
};

export default EndgameBoard;
