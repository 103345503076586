import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import "../styles/gameStats.css";
import { useGameState } from "../Integrations/GameStateContext";
import { Chess } from 'chess.js';
import annotationPlugin from "chartjs-plugin-annotation";
import { useInView } from "react-intersection-observer";

Chart.register(annotationPlugin);

const GameStats = ({ isOpen, onClose, userRatings, flagsForReview }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [backupOpeningIndex, setBackupOpeningIndex] = useState(null);
  const { gameState, updateGameState } = useGameState();
  const rootStyles = getComputedStyle(document.documentElement);
  const [selectedPlayer, setSelectedPlayer] = useState("both"); // 'white', 'black', or 'both'
  const [stats, setStats] = useState({
    totalMoves: 0,
    whiteStars: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
    blackStars: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
    whiteAccuracy: 0,
    blackAccuracy: 0,
    aiComparison: {
      userStars: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
      aiStars: { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 },
      misunderstandings: [],
    },
    chartData: null,
    aiVsUserChartData: null,
  });


  const [chartRef, chartInView] = useInView({ threshold: 0.5 });
  const [analysisRef, analysisInView] = useInView({ threshold: 0.3 });
  const [observationsRef, observationsInView] = useInView({ threshold: 0.3 });

  const fadeInStyle = (inView) => ({
//    opacity: inView ? 1 : 0,
//    transform: inView ? "translateY(0)" : "translateY(20px)",
//    transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
  });

useEffect(() => {
  if (gameState.analysis) {
    console.log("Computing Stats for Analysis");
    computeStats(backupOpeningIndex);
  }
}, [gameState.analysis]);

useEffect(() => {
  if (gameState.analysis) {
    computeStats(backupOpeningIndex);
  }
}, [selectedPlayer, gameState.analysis]);

useEffect(() => {
  if (gameState.openingPrepEndIndex !== null && gameState.analysis) {
    console.log("Computing Stats for Opening");
    computeStats(gameState.openingPrepEndIndex);
    setBackupOpeningIndex(gameState.openingPrepEndIndex);
  }
}, [gameState.openingPrepEndIndex]);


const computeWinPercent = (centipawns) => {
  const evalValue = Math.min(500, Math.max(-500, centipawns)); // Clamp centipawn values
  const winPercent = 50 + 50 * (2 / (1 + Math.exp(-0.00368208 * evalValue)) - 1);
  return Math.min(100, Math.max(0, winPercent)); // Clamp Win% between 0 and 100
};

const computeAccuracyPercent = (winPercentBefore, winPercentAfter) => {
  const diff = winPercentBefore - winPercentAfter;
  const rawAccuracy = 103.1668 * Math.exp(-0.04354 * diff) - 3.1669;
  return Math.min(100, Math.max(0, rawAccuracy)); // Clamp Accuracy% between 0 and 100
};

const computeStats = (openingIndex = null) => {
  const chess = new Chess();
  let endgameStartIndex = -1;

  console.log("Using Opening Index:", openingIndex);

  // Initialize stats objects
  const whiteStars = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
  const blackStars = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
  const userStars = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
  const aiStars = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
  const misunderstandings = [];

  // Initialize variables for calculations
  let totalMoves = 0;
  let whiteAccuracySum = 0;
  let blackAccuracySum = 0;
  let aiAccuracySum = 0;

  const chartLabels = [];
  const chartData = [];
  const diffChartData = [];
  const diffColors = [];
  const misunderstandingPoints = [];
  let prevWinPercent = 50;

  gameState.analysis.forEach((move, index) => {
    if (!move.evaluation) return;

    // Play the move in the Chess instance
    const movePlayed = chess.move(move.move);
    if (!movePlayed) {
      console.error("Invalid move at index:", index);
      return;
    }

    // Get the FEN after the move
    const pieces = chess.board().flat().filter((square) => square !== null).length;

    // Determine if the endgame has started
    if (pieces <= 13 && endgameStartIndex === -1) {
      endgameStartIndex = index;
      console.log("Endgame starts at move index:", endgameStartIndex);
    }

    // Determine player (White or Black)
    const player = index % 2 === 0 ? "white" : "black";

    // Original evaluation in centipawns
    let evaluation =
      move.evaluation.type === "mate"
        ? move.evaluation.value > 0
          ? 500
          : -500
        : move.evaluation.value * 100;

    // Invert evaluation for Black
    const adjustedEvaluation = player === "black" ? -evaluation : evaluation;

    // Calculate Win% and Accuracy%
    const winPercentAfter = computeWinPercent(adjustedEvaluation);
    const accuracy = computeAccuracyPercent(prevWinPercent, winPercentAfter);

    prevWinPercent = 100 - winPercentAfter; // Update for next move
    aiAccuracySum += accuracy;

    // Update cumulative accuracy for the current player
    if (player === "white") {
      whiteAccuracySum += accuracy;
    } else {
      blackAccuracySum += accuracy;
    }

    // Update star ratings
    const aiRating = move.rating || 5;
    const userRating = userRatings[index] || 5;

    if (player === "white") whiteStars[aiRating]++;
    else blackStars[aiRating]++;

    aiStars[aiRating]++;
    userStars[userRating]++;

    // Log misunderstandings (difference of 2 or more in ratings)
    if (Math.abs(aiRating - userRating) >= 2) {
      misunderstandings.push(index);
      misunderstandingPoints.push({
        x: `Move ${index + 1}`,
        y: evaluation, // Use original evaluation
      });
    }

    // Add move number or blank label for the chart
    chartLabels.push(index % 2 === 0 ? `${Math.floor(index / 2) + 1}` : "");

    // Add evaluation to the chart data
    chartData.push(evaluation);

    // Calculate difference between AI and User ratings
    const diff = aiRating - userRating;
    diffChartData.push(diff);

    const absDiff = Math.abs(diff);
    const isUserTurn = index % 2 === (gameState.playerColor === "White" ? 0 : 1);

      // Apply gray background if onlyUserMoves is true and it's not the user's turn
      if (gameState.onlyUserMoves && !isUserTurn) {
        diffColors.push("rgba(128, 128, 128, 0.2)"); // Light gray for non-user moves
      } else {
        // Determine color based on difference magnitude
        if (absDiff === 0) {
          diffColors.push("rgba(0, 128, 0, 1)"); // Green
        } else if (absDiff === 1) {
          diffColors.push("rgba(255, 185, 0, 1)"); // Yellow
        } else {
          diffColors.push("rgba(255, 0, 0, 1)"); // Red
        }
      }

  totalMoves++;
});

//    const filteredChartLabels = gameState.onlyUserMoves
//      ? chartLabels.filter((_, index) =>
//          (index % 2 === (gameState.playerColor === "White" ? 0 : 1))) // Filter user moves only
//      : chartLabels;
//
//    const filteredDiffChartData = gameState.onlyUserMoves
//      ? diffChartData.filter((_, index) =>
//          (index % 2 === (gameState.playerColor === "White" ? 0 : 1))) // Filter user moves only
//      : diffChartData;

    const flaggedPointColors = diffChartData.map((_, index) =>
      flagsForReview[index]
        ? "rgba(255, 0, 255, 1)" // Purple for flagged moves
        : gameState.onlyUserMoves &&
          index % 2 !== (gameState.playerColor === "White" ? 0 : 1)
        ? "rgba(128, 128, 128, 0.2)" // Gray for non-user moves
        : "rgba(200, 200, 200, 0.5)" // Default color for other points
    );

  // Calculate average ratings and accuracy
  const whiteMoves = Math.ceil(totalMoves / 2); // Round up for White
  const blackMoves = Math.floor(totalMoves / 2); // Round down for Black

  setStats({
    openingPrepEndIndex: openingIndex, // Add this
    endgameStartIndex,
    totalMoves,
    whiteStars,
    blackStars,
    whiteAccuracy: (whiteAccuracySum / whiteMoves).toFixed(2),
    blackAccuracy: (blackAccuracySum / blackMoves).toFixed(2),
    aiComparison: {
      userStars,
      aiStars,
      misunderstandings,
    },
    chartData: {
      labels: chartLabels,
      datasets: [
        {
          label: "Evaluation",
          data: chartData.map(value => Math.min(500, Math.max(-500, value))),
          borderColor: "black",
          backgroundColor: "rgba(200, 200, 200, 0.35)",
          fill: true,
          pointBackgroundColor: chartData.map((value, index) => {
            const player = index % 2 === 0 ? "white" : "black"; // Determine the player for the move
            const rating = gameState.analysis[index]?.rating || 5;

            if (selectedPlayer !== "both" && selectedPlayer !== player) {
              return "rgba(128, 128, 128, 0.2)"; // Transparent gray for non-selected player's moves
            }

            // Colors for selected player's moves
            return rating === 5
              ? "rgba(0, 128, 0, 1)" // Green
              : rating === 4
              ? "rgba(50, 205, 50, 1)" // Limegreen
              : rating === 3
              ? "rgba(255, 223, 0, 1)" // Yellow
              : rating === 2
              ? "rgba(255, 165, 0, 1)" // Orange
              : "rgba(255, 0, 0, 1)"; // Red
          }),
          pointRadius: 4,
        },
      ],
    },
    aiVsUserChartData: {
      labels: chartLabels,
      datasets: [
        {
          label: "User vs AI Rating Difference",
          data: diffChartData,
          borderColor: "transparent", // Border color for line
          fill: false, // Filled chart area
          pointBackgroundColor: diffColors, // Colors for points
          pointRadius: 4.5, // Radius of points
          showLine: false,
        },
          {
            label: "Flagged Moves",
            data: diffChartData.map((value, index) =>
              flagsForReview[index] ? value : null // Only include flagged points
            ),
            borderColor: "rgba(255, 0, 0, 0)", // Purple for flagged points
            backgroundColor: "rgba(255, 0, 0, .25)",
            pointStyle: 'circle',
            pointRadius: 11,
            showLine: false,
          },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            openingEnds: openingIndex !== null && {
              type: "line",
              xMin: openingIndex,
              xMax: openingIndex,
              borderColor: "rgba(200, 200, 200, 0.6)",
              borderWidth: 4,
              label: {
                content: "Opening Prep Ends",
                display: true,
                position: "top",
                backgroundColor: "rgba(200, 200, 200, 0.6)",
                rotation: 270,
                font: {
                  size: 12,
                  weight: "bold",
                },
              },
            },
            endgameStarts: endgameStartIndex !== -1 && {
              type: "line",
              xMin: endgameStartIndex,
              xMax: endgameStartIndex,
              borderColor: "rgba(200, 200, 200, 0.6)",
              borderWidth: 4,
              label: {
                content: "Endgame Starts",
                rotation: 270,
                display: true,
                position: "top",
                backgroundColor: "rgba(200, 200, 200, 0.6)",
                font: {
                  size: 12,
                  weight: "bold",
                },
              },
            },
          },
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Move #",
          },
        },
        y: {
          title: {
            display: true,
            text: "Centipawns",
          },
          min: -520,
          max: 520,
          ticks: {
            stepSize: 100,
            callback: (value) => {
              if (value > 500) return "500";
              if (value < -500) return "-500";
              return value;
            },
          },
        },
      },
    },
    options2: {
        plugins: {
            legend: {
              labels: {
                generateLabels: (chart) => {
                  return [
                    {
                      text: "Flagged Moves",
                      fillStyle: "rgba(255, 0, 0, .25)",
                      strokeStyle: "rgba(255, 0, 0, 0)",
                      lineWidth: 2,
                      hidden: false,
                      datasetIndex: null, // Not linked to any dataset
                    },
                  ];
                },
              },
            },

        },
      scales: {
        x: {
          title: {
            display: true,
            text: "Move #",
          },
          ticks: {
            autoSkip: true, // Ensure every tick is displayed
          },
        },
        y: {
          title: {
            display: true,
            text: "Rating Difference",
          },
          ticks: {
            stepSize: 1, // Ensure tick marks at every rating difference
          },
        },
      },
    },


  });
};

const generateEvaluationObservations = (evaluationData, stats = {}) => {
  const observations = [];
  const maxEval = Math.max(...evaluationData);
  const minEval = Math.min(...evaluationData);
  const avgEval = evaluationData.reduce((sum, val) => sum + val, 0) / evaluationData.length;

  const openingPrepEndIndex = stats.openingPrepEndIndex || 0;
  const endgameStartIndex = stats.endgameStartIndex || evaluationData.length;

  const openingPhase = evaluationData.slice(0, openingPrepEndIndex);
  const middlegamePhase = evaluationData.slice(openingPrepEndIndex, endgameStartIndex);
  const endgamePhase = evaluationData.slice(endgameStartIndex);

  // Observations on opening prep
  const openingMoves = openingPrepEndIndex;
  if (openingMoves >= 5) {
    observations.push(`Good job on the opening prep! You followed preparation for ${openingMoves} moves.`);
  } else {
    observations.push(
      `Opening prep could be improved, as preparation ended after only ${openingMoves} moves.`
    );
  }

  // Observations on game phase strengths
    const evaluatePhase = (phase, name) => {
      if (phase.length === 0) {
        return;
      }

      const phaseAvg = phase.reduce((sum, val) => sum + val, 0) / phase.length || 0;
      const phaseMax = Math.max(...phase);
      const phaseMin = Math.min(...phase);

      let phaseStrength = "";

      if (phaseAvg > 150 && phaseMax > 300) {
        phaseStrength = `The ${name} phase was particularly strong for White, with an average advantage of ${phaseAvg.toFixed(
          2
        )} and a peak advantage of ${phaseMax.toFixed(2)}.`;
      } else if (phaseAvg < -150 && phaseMin < -300) {
        phaseStrength = `The ${name} phase was particularly strong for Black, with an average disadvantage of ${phaseAvg.toFixed(
          2
        )} and a peak disadvantage of ${phaseMin.toFixed(2)}.`;
      } else if (phaseAvg > 150) {
        phaseStrength = `The ${name} phase was strong for White, with an average advantage of ${phaseAvg.toFixed(2)}.`;
      } else if (phaseAvg < -150) {
        phaseStrength = `The ${name} phase was strong for Black, with an average disadvantage of ${phaseAvg.toFixed(2)}.`;
      } else {
        phaseStrength = `The ${name} phase was evenly matched, with no significant advantage for either side.`;
      }

      observations.push(phaseStrength);
    };

  evaluatePhase(openingPhase, "opening");
  evaluatePhase(middlegamePhase, "middlegame");
  if (endgameStartIndex !== -1) {
    evaluatePhase(endgamePhase, "endgame");
    };

//  // Observations for consistent advantage
//  if (avgEval > 200) {
//    observations.push("White had a significant advantage throughout the game.");
//  } else if (avgEval < -200) {
//    observations.push("Black had a significant advantage throughout the game.");
//  }

const countSignificantSwings = (evals, threshold) => {
  // Step 1: Filter out values within the threshold range
  const filteredEvals = evals.filter((value) => Math.abs(value) > threshold);

  // Step 2: Count sign changes in the remaining evaluations
  let count = 1;
  for (let i = 1; i < filteredEvals.length; i++) {
    if (
      (filteredEvals[i - 1] > 0 && filteredEvals[i] < 0) || // Positive to negative
      (filteredEvals[i - 1] < 0 && filteredEvals[i] > 0)    // Negative to positive
    ) {
      count++;
    }
  }

  return count;
};

const backAndForthThreshold = 150; // Significant swing threshold
const requiredSwings = 3; // Minimum number of back-and-forth swings

const significantSwings = countSignificantSwings(evaluationData, backAndForthThreshold);

if (maxEval - minEval > 400 && Math.abs(avgEval) < 100 && significantSwings >= requiredSwings) {
  observations.push(
    `The game was highly dynamic with evaluation swings giving chances for both players. It featured ${significantSwings} major shifts in advantage.`
  );
}

// Observations for major blunders
for (let i = 1; i < evaluationData.length; i++) {
  const prevEval = evaluationData[i - 1];
  const currEval = evaluationData[i];
  const change = Math.abs(currEval - prevEval);

  // Identify specific types of evaluation swings
  let blunderDescription = null;

  if (prevEval > 150 && currEval > -150 && currEval < 0) {
    blunderDescription = "White's winning position was reduced to a slight advantage for Black";
  } else if (prevEval > 150 && currEval < -150) {
    blunderDescription = "White went from a winning position to losing";
  } else if (prevEval < -150 && currEval < 150 && currEval > 0) {
    blunderDescription = "Black's winning position was reduced to a slight advantage for White";
  } else if (prevEval < -150 && currEval > 150) {
    blunderDescription = "Black went from a winning position to losing for White";
  }

  // Add observations if a significant swing occurred
  if (blunderDescription) {
    const side = currEval > prevEval ? "White" : "Black"; // Determine who gained the advantage
    const moveNumber = Math.ceil((i + 1) / 2); // Calculate move number (1-based)
    const movePrefix = i % 2 === 0 ? `${moveNumber}.` : `${moveNumber}...`; // Format for White or Black's turn

    observations.push(
      `On move ${movePrefix} ${blunderDescription}. The evaluation changed by ${change} centipawns.`
    );
  }
}

// Observations for closely contested game
const evaluateContestedGame = (evals) => {
  const length = evals.length;
  const firstTwoThirds = evals.slice(0, Math.ceil((2 / 3) * length)); // First two-thirds of the game
  const lastThird = evals.slice(Math.ceil((2 / 3) * length)); // Last third of the game

  const firstTwoThirdsAvg = firstTwoThirds.reduce((sum, val) => sum + val, 0) / firstTwoThirds.length || 0;
  const firstTwoThirdsMax = Math.max(...firstTwoThirds);
  const firstTwoThirdsMin = Math.min(...firstTwoThirds);

  // Check if the first two-thirds were closely contested
  const isContested = Math.abs(firstTwoThirdsAvg) < 100 && firstTwoThirdsMax - firstTwoThirdsMin < 200;

  // Ensure the last third doesn't negate the observation
  const lastThirdMax = Math.max(...lastThird);
  const lastThirdMin = Math.min(...lastThird);

  return isContested && lastThirdMax - lastThirdMin > 100; // Ensure some dynamism in the last third
};

if (evaluateContestedGame(evaluationData)) {
  observations.push(
    "The game was closely contested with minimal changes in evaluation until the last third, where dynamics started to shift."
  );
}

  return observations;
};

const generateAIComparisonObservations = (diffData, stats) => {
  const observations = [];
    const significantDiscrepancies = diffData
      .filter((diff, index) => {
        // Check if the move is a user's move if onlyUserMoves is true
        const isUserMove = index % 2 === (gameState.playerColor === "White" ? 0 : 1);
        if (gameState.onlyUserMoves && !isUserMove) {
          return false; // Skip non-user moves if onlyUserMoves is true
        }
        return Math.abs(diff) >= 2; // Check for significant discrepancies
      }).length;
  const minorDiscrepancies = diffData.filter((diff) => Math.abs(diff) === 1).length;
  const consistentUnderstanding = diffData.filter((diff) => diff === 0).length;

    // Divide by 2 and round up to count any partial moves as a full move
    const totalMoves = gameState.onlyUserMoves
      ? diffData.filter((_, index) => index % 2 === (gameState.playerColor === "White" ? 0 : 1)).length
      : diffData.length;

  const totalMovesDisplay = Math.ceil(diffData.length / 2);
  const discrepancyPercentage = ((significantDiscrepancies / totalMoves) * 100).toFixed(1);
  const averageDiscrepancy = (diffData.reduce((sum, diff) => sum + diff, 0) / totalMoves).toFixed(2);

  const beforeOpening = diffData.slice(0, stats.openingPrepEndIndex || 0);
  const afterOpening = diffData.slice(stats.openingPrepEndIndex || 0, stats.endgameStartIndex || diffData.length);
  const afterEndgame = diffData.slice(stats.endgameStartIndex || diffData.length);

  const significantBeforeOpening = beforeOpening.filter((diff) => Math.abs(diff) >= 2).length;
  const significantAfterOpening = afterOpening.filter((diff) => Math.abs(diff) >= 2).length;
  const significantAfterEndgame = afterEndgame.filter((diff) => Math.abs(diff) >= 2).length;

  observations.push(
    `Out of ${totalMoves} reviewed ply, there were ${significantDiscrepancies} significant discrepancies (${discrepancyPercentage}%).`
  );

  if (averageDiscrepancy < -0.5) {
    observations.push("On average, the user evaluations were significantly more hopeful than the AI evaluations.");
  } else if (averageDiscrepancy > 0.5) {
    observations.push("On average, the user evaluations were significantly harsher than the AI evaluations.");
  } else {
//    observations.push("The user evaluations were generally aligned with the AI.");
  }

//  if (significantBeforeOpening > significantAfterOpening && significantBeforeOpening > significantAfterEndgame) {
//    observations.push(
//      `Most misunderstandings occurred before the opening preparation phase, with ${significantBeforeOpening} significant discrepancies.`
//    );
//  } else if (significantAfterOpening > significantBeforeOpening && significantAfterOpening > significantAfterEndgame) {
//    observations.push(
//      `Most misunderstandings occurred in the middlegame phase, with ${significantAfterOpening} significant discrepancies.`
//    );
//  } else if (significantAfterEndgame > significantBeforeOpening && significantAfterEndgame > significantAfterOpening) {
//    observations.push(
//      `Most misunderstandings occurred after the endgame started, with ${significantAfterEndgame} significant discrepancies.`
//    );
//  }
    observations.push(
      `Click proceed to review discrepancies found.`
    );

  return observations;
};

if (!isOpen) return null;

return (
    <div className="stats-modal-overlay">
      <div className="stats-modal-content">
        <h3>Game Stats</h3>
        <div className="stats-columns">
          <div className="stats-table">
            <table>
              <thead>
                <tr>
                  <th>Stars</th>
                  <th
                    style={{
                      backgroundColor: "#f9f9f9", // Slightly off-white
                      color: "#555555", // Gray for text
                    }}
                  >
                    <div className="player-info">
                      <span className="player-name">
                        {gameState.usernames.white}
                      </span>
                      <span className="accuracy-box">{stats.whiteAccuracy}%</span>
                    </div>
                  </th>
                  <th
                    style={{
                      backgroundColor: "#2a2a2a", // Slightly off-black
                      color: "#d3d3d3", // Light gray for text
                    }}
                  >
                    <div className="player-info">
                      <span className="player-name">
                        {gameState.usernames.black}
                      </span>
                      <span className="accuracy-box">{stats.blackAccuracy}%</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {[5, 4, 3, 2, 1].map((stars) => (
                  <tr key={stars}>
                    <td>
                      <div className="stars-row">
                        {[...Array(stars)].map((_, i) => (
                          <span
                            key={i}
                            className={`star ${stars >= 1 ? "selected" : ""}`} // Always apply selected to all stars
                          >
                            ★
                          </span>
                        ))}
                      </div>
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          stars === 5
                            ? "rgba(0, 128, 0, 0.1)" // Green
                            : stars === 4
                            ? "rgba(50, 205, 50, 0.1)" // Limegreen
                            : stars === 3
                            ? "rgba(255, 255, 0, 0.1)" // Yellow
                            : stars === 2
                            ? "rgba(255, 165, 0, 0.1)" // Orange
                            : "rgba(255, 0, 0, 0.1)", // Red
                      }}
                    >
                      {stats.whiteStars[stars] || 0}
                    </td>
                    <td
                      style={{
                        backgroundColor:
                          stars === 5
                            ? "rgba(0, 128, 0, 0.1)" // Green
                            : stars === 4
                            ? "rgba(50, 205, 50, 0.1)" // Limegreen
                            : stars === 3
                            ? "rgba(255, 255, 0, 0.1)" // Yellow
                            : stars === 2
                            ? "rgba(255, 165, 0, 0.1)" // Orange
                            : "rgba(255, 0, 0, 0.1)", // Red
                      }}
                    >
                      {stats.blackStars[stars] || 0}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {stats.chartData && (
          <div ref={chartRef} className="stats-chart" style={fadeInStyle(chartInView)}>
                  <div className="player-selection">
          <div className="button-group">
            <button
              className={`player-button ${selectedPlayer === "white" ? "active" : ""}`}
              onClick={() => setSelectedPlayer("white")}
            >
              White
            </button>
            <button
              className={`player-button ${selectedPlayer === "black" ? "active" : ""}`}
              onClick={() => setSelectedPlayer("black")}
            >
              Black
            </button>
            <button
              className={`player-button ${selectedPlayer === "both" ? "active" : ""}`}
              onClick={() => setSelectedPlayer("both")}
            >
              Both
            </button>
          </div>
        </div>

            <h4>Stockfish Evaluation</h4>
            <Line data={stats.chartData} options={stats.options} />
            <div>
                {generateEvaluationObservations(stats.chartData.datasets[0].data, stats).map((observation, index) => (
                  <div className="game-dynamics" key={`eval-${index}`}>
                    <p>{observation}</p>
                  </div>
                ))}

            </div>
          </div>
        )}

        {stats.aiVsUserChartData && (
          <div ref={analysisRef} className="stats-chart" style={fadeInStyle(analysisInView)}>
            <h4>Analysis Comparison</h4>
            <Line data={stats.aiVsUserChartData} options={stats.options2} />
            <div>
              {generateAIComparisonObservations(
                stats.aiVsUserChartData.datasets[0].data,
                stats
              ).map((observation, index) => (
                <div ref={observationsRef} className="game-dynamics" key={`ai-${index}`} style={fadeInStyle(observationsInView)}>
                  <p>{observation}</p>
                </div>
              ))}

            </div>
          </div>
        )}
        <hr className="line-before-button" />
        <button className="average-button" onClick={onClose}>Proceed</button>
      </div>
    </div>
  );
};

export default GameStats;