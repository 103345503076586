import React, { useState, useEffect } from "react";
import "../styles/GameAnalysis.css";
import { db } from "../firebase-config";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const GameRetriever = ({user, onGameSelect}) => {
  const [chessComUsernames, setChessComUsernames] = useState([]);
  const [lichessUsernames, setLichessUsernames] = useState([]);
  const [isLoadingUsernames, setIsLoadingUsernames] = useState(true);
  const [newUsername, setNewUsername] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("Chess.com");
  const [games, setGames] = useState([]);
  const [error, setError] = useState("");
  const [isManageUsernamesVisible, setIsManageUsernamesVisible] = useState(false);
  const [isLoadingGames, setIsLoadingGames] = useState(false);

  useEffect(() => {
    fetchUsernames();
  }, [user]);

  const fetchUsernames = async () => {
    if (!user) return;
    console.log('Fetching usernames');
    try {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const data = userDoc.data();
        setChessComUsernames(data.chessComUsernames || []);
        setLichessUsernames(data.lichessUsernames || []);
      }
    } catch (error) {
      console.error("Error fetching usernames:", error);
    } finally {
      setIsLoadingUsernames(false);
    }
  };

  // Fetch games whenever the usernames list changes
  useEffect(() => {
    if (chessComUsernames.length > 0 || lichessUsernames.length > 0) {
      handleRetrieveGames();
    }
  }, [chessComUsernames, lichessUsernames]);

const handleRetrieveGames = async () => {
  setIsLoadingGames(true);
  setError("");

  try {
    const fetchedChessComGames = await Promise.all(
      chessComUsernames.map((username) => fetchChessComGames(username))
    );
    const fetchedLichessGames = await Promise.all(
      lichessUsernames.map((username) => fetchLichessGames(username))
    );

    const allGames = [
      ...fetchedChessComGames.flat(),
      ...fetchedLichessGames.flat(),
    ];

    // Order games by date (most recent first)
    allGames.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Group games by date
    const groupedGames = allGames.reduce((acc, game) => {
      const date = game.date;
      if (!acc[date]) acc[date] = [];
      acc[date].push(game);
      return acc;
    }, {});

    setGames(groupedGames);
  } catch (err) {
    console.error("Error fetching games:", err);
    setError("Failed to retrieve games. Please try again.");
  } finally {
    setIsLoadingGames(false);
  }
};

const fetchChessComGames = async (username) => {
  console.log("username chesscom: " + username);
  const url = `https://api.chess.com/pub/player/${username}/games/archives`;
  const archivesResponse = await fetch(url);
  if (!archivesResponse.ok) {
    throw new Error("Failed to fetch Chess.com archives.");
  }
  const archives = await archivesResponse.json();
  const latestArchiveUrl = archives.archives[archives.archives.length - 1];
  const gamesResponse = await fetch(latestArchiveUrl);
  if (!gamesResponse.ok) {
    throw new Error("Failed to fetch Chess.com games.");
  }
  const gamesData = await gamesResponse.json();

  return gamesData.games.map((game) => {
    const playerColor = game.black.username === username ? "Black" : "White";
    let result;
      (console.log('Game result for ' + game.white.username + ' vs ' + game.black.username + ": " + game.white.result))

    // Determine the game result
    if (
      (game.white.result === "win" && playerColor === "White") ||
      (game.black.result === "win" && playerColor === "Black")
    ) {
      result = "Victory";
    } else if (
      (game.black.result === "win" && playerColor === "White") ||
      (game.white.result === "win" && playerColor === "Black")
    ) {
      result = "Defeat";
    } else {
      result = "Draw";
    }

    return {
      platform: "Chess.com",
      url: game.url,
      date: new Date(game.end_time * 1000).toLocaleDateString(),
      opponent: game.black.username === username ? game.white.username : game.black.username,
      playerColor,
      result,
      pgn: game.pgn || "", // PGN if available
    };
  });
};

const fetchLichessGames = async (username) => {
  console.log("username lichess: " + username);
  const url = `https://lichess.org/api/games/user/${username}?max=20`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Failed to fetch Lichess games.");
  }

  const gamesData = await response.text();
  console.log("Raw Lichess PGN Data:", gamesData);

  // Split the PGN into individual game blocks by finding empty lines between games
  const gameBlocks = gamesData.split(/\n\n(?=\[Event)/).map((block) => block.trim());
  console.log("Total games found:", gameBlocks.length);

  const normalizedUsername = username.trim().toLowerCase();

  const games = gameBlocks.map((gameBlock, index) => {
    const lines = gameBlock.split("\n");
    console.log(`Processing game block ${index + 1}:`, gameBlock);

    const urlLine = lines.find((line) => line.startsWith("[Site"));
    const opponentLine = lines.find((line) => line.startsWith("[White") || line.startsWith("[Black"));

    const playerColor = opponentLine.toLowerCase().includes(`"${normalizedUsername}"`)
      ? opponentLine.startsWith("[White")
        ? "White"
        : "Black"
      : "Black"; // Default to "Black" if username is not explicitly found

    const opponent =
      playerColor === "White"
        ? lines.find((line) => line.startsWith("[Black")).split('"')[1]
        : lines.find((line) => line.startsWith("[White")).split('"')[1];

    const dateLine = lines.find((line) => line.startsWith("[Date"));
    const resultLine = lines.find((line) => line.startsWith("[Result"));

    const movesStartIndex = lines.findIndex((line) => line.trim().match(/^\d+\./)); // Detect where moves start
    const moves = movesStartIndex !== -1 ? lines.slice(movesStartIndex).join(" ") : "";

    const url = urlLine ? urlLine.split('"')[1] : "";
    const date = dateLine ? dateLine.split('"')[1] : "";
    const result = resultLine ? resultLine.split('"')[1] : "";

    // Determine victory, defeat, or draw
    let gameResult;
    if (
      (result === "1-0" && playerColor === "White") ||
      (result === "0-1" && playerColor === "Black")
    ) {
      gameResult = "Victory";
    } else if (result === "1/2-1/2") {
      gameResult = "Draw";
    } else {
      gameResult = "Defeat";
    }

    return {
      platform: "Lichess",
      url,
      date: new Date(date).toLocaleDateString(),
      opponent,
      playerColor,
      result: gameResult, // "Victory", "Defeat", or "Draw"
      pgn: gameBlock, // Full PGN block
      moves, // Extracted moves
    };
  });

  return games;
};

const handleAddUsername = async () => {
  if (!user) {
    setError("User not authenticated.");
    return;
  }

  const trimmedUsername = newUsername.trim();
  if (!trimmedUsername) {
    setError("Username cannot be empty.");
    return;
  }

  setError("");
  const userRef = doc(db, "users", user.uid);

  try {
    // Verify the username
    const isValid = await verifyUsername(trimmedUsername, selectedPlatform);
    if (!isValid) {
      setError(`The username "${trimmedUsername}" is invalid or not found on ${selectedPlatform}.`);
      return;
    }

    // Save the verified username
    if (selectedPlatform === "Chess.com") {
      const updatedChessComUsernames = [...chessComUsernames, trimmedUsername];
      setChessComUsernames(updatedChessComUsernames);
      await updateDoc(userRef, { chessComUsernames: updatedChessComUsernames });
    } else if (selectedPlatform === "Lichess") {
      const updatedLichessUsernames = [...lichessUsernames, trimmedUsername];
      setLichessUsernames(updatedLichessUsernames);
      await updateDoc(userRef, { lichessUsernames: updatedLichessUsernames });
    }
    setNewUsername(""); // Clear the input field
  } catch (error) {
    console.error("Error adding username:", error);
    setError("An error occurred while adding the username.");
  }
};

const verifyUsername = async (username, platform) => {
  try {
    if (platform === "Chess.com") {
      const url = `https://api.chess.com/pub/player/${username}`;
      const response = await fetch(url);
      return response.ok; // Username is valid if the response is OK
    } else if (platform === "Lichess") {
      const url = `https://lichess.org/api/user/${username}`;
      const response = await fetch(url);
      return response.ok; // Username is valid if the response is OK
    }
    return false; // Invalid platform
  } catch (error) {
    console.error(`Error verifying username on ${platform}:`, error);
    return false;
  }
};

  const handleDeleteUsername = async (username, platform) => {
    if (!user) {
      setError("User not authenticated.");
      return;
    }

    setError("");

    const userRef = doc(db, "users", user.uid);

    try {
      if (platform === "Chess.com") {
        const updatedChessComUsernames = chessComUsernames.filter((u) => u !== username);
        setChessComUsernames(updatedChessComUsernames);
        await updateDoc(userRef, { chessComUsernames: updatedChessComUsernames });
      } else if (platform === "Lichess") {
        const updatedLichessUsernames = lichessUsernames.filter((u) => u !== username);
        setLichessUsernames(updatedLichessUsernames);
        await updateDoc(userRef, { lichessUsernames: updatedLichessUsernames });
      }
    } catch (error) {
      console.error("Error deleting username:", error);
    }
  };

return (
  <div className="game-list-sidebar">
    <button
      onClick={() => setIsManageUsernamesVisible((prev) => !prev)}
      className="toggle-manage-button"
    >
      {isManageUsernamesVisible ? "Close" : "Add/Delete Usernames"}
    </button>

    {isManageUsernamesVisible && (
      <div className="username-input-section">
        <h3>Retrieving Games For:</h3>
        {isLoadingUsernames ? (
          <p>Loading usernames...</p>
        ) : (
          <>
            {error && <p className="error">{error}</p>}
            <ul>
              {chessComUsernames.map((username, index) => (
                <li key={index} className="username-list-item">
                  {username} (Chess.com)
                  <button
                    onClick={() => handleDeleteUsername(username, "Chess.com")}
                    className="delete-button"
                  >
                    −
                  </button>
                </li>
              ))}
            </ul>
            <ul>
              {lichessUsernames.map((username, index) => (
                <li key={index} className="username-list-item">
                  {username} (Lichess)
                  <button
                    onClick={() => handleDeleteUsername(username, "Lichess")}
                    className="delete-button"
                  >
                    −
                  </button>
                </li>
              ))}
            </ul>
            <div>
                <label>
                  Platform:{" "}
                  <select
                    value={selectedPlatform}
                    onChange={(e) => setSelectedPlatform(e.target.value)}
                  >
                    <option value="Chess.com">Chess.com</option>
                    <option value="Lichess">Lichess</option>
                  </select>
                </label>

            </div>
            <div>
              <input
                type="text"
                value={newUsername}
                placeholder={`Add ${selectedPlatform} Username`}
                onChange={(e) => setNewUsername(e.target.value)}
              />
              <button className='average-button' onClick={handleAddUsername}>Add Username</button>
            </div>

          </>
        )}
      </div>
    )}
    {isLoadingGames ? (
      <p>Loading games...</p>
    ) : (
      Object.entries(games).map(([date, gamesOnDate]) => (
        <div key={date} className="game-date-section">
          <h3 className="game-date-header">{date}</h3>
            {gamesOnDate.map((game, index) => (
              <div
                key={index}
                className={`game-list-item ${
                  game.platform === "Lichess" ? "lichess-game" : "chesscom-game"
                }`}
                onClick={() => onGameSelect({ game })}
              >
                <p className="game-result">
                  {game.result === "Victory" ? (
                    <span className="badge win-badge">
                      Victory VS {game.opponent}
                    </span>
                  ) : game.result === "Defeat" ? (
                    <span className="badge loss-badge">
                      Defeat VS {game.opponent}
                    </span>
                  ) : (
                    <span className="badge draw-badge">
                      Draw VS {game.opponent}
                    </span>
                  )}
                </p>
                <a
                  href={game.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-game-link"
                >
                  From {game.platform}
                </a>
              </div>
            ))}

        </div>
      ))
    )}
  </div>
);

};

export default GameRetriever;
