import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { Chess } from 'chess.js';
import { db } from '../firebase-config';

export const quotes = [
    "\"All I want to do, ever, is just play Chess.\" — Bobby Fischer",
    "\"Chess is life.\" — Bobby Fischer",
    "\"A bad day of Chess is better than any good day at work.\" — Anonymous",
    "\"Chess is imagination.\" — David Bronstein",
    "\"No one ever won a game by resigning.\" — Savielly Tartakover",
    "\"When in doubt... play Chess!\" — Tevis",
    "\"Chess is mental torture.\" — Garry Kasparov",
    "\"Play the opening like a book, the middle game like a magician, and the endgame like a machine.\" — Spielmann",
    "\"Every Chess master was once a beginner.\" — Chernev",
    "\"Chess is above all, a fight!\" — Emanuel Lasker",
    "\"The King is a fighting piece. Use it!\" — Wilhelm Steinitz",
    "\"Chess is 99 percent tactics.\" — Teichmann",
    "\"You can only get good at Chess if you love the game.\" — Bobby Fischer",
    "\"Chess makes man wiser and clear-sighted.\" — Vladimir Putin",
    "\"Help your pieces so they can help you.\" — Paul Morphy",
    "\"Chess is the art of analysis.\" — Mikhail Botvinnik",
    "\"A passed Pawn increases in strength as the number of pieces on the board diminishes.\" — Capablanca",
    "\"Chess is a sea in which a gnat may drink and an elephant may bathe.\" — Hindu proverb",
    "\"The mistakes are there, waiting to be made.\" — Savielly Tartakover",
    "\"The blunders are all there on the board, waiting to be made.\" — Savielly Tartakover",
    "\"Chess is war over the board. The object is to crush the opponent's mind.\" — Bobby Fischer",
    "\"The hardest game to win is a won game.\" — Emanuel Lasker",
    "\"In life, as in Chess, forethought wins.\" — Charles Buxton",
    "\"Chess is the gymnasium of the mind.\" — Blaise Pascal",
    "\"Excellence at Chess is one mark of a scheming mind.\" — Arthur Conan Doyle",
    "\"Chess is a beautiful mistress.\" — Bent Larsen",
    "\"The beauty of a move lies not in its appearance but in the thought behind it.\" — Aaron Nimzovich",
    "\"The game of Chess is not just idle amusement; it is training of the mind for battle.\" — Benjamin Franklin",
    "\"Chess, like love, like music, has the power to make people happy.\" — Siegbert Tarrasch",
    "\"Openings teach you openings. Endgames teach you chess.\" — Stephan Gerzadowicz",
    "\"The primary constraint on a piece's activity is the Pawn structure.\" — Michael Stean",
    "\"Chess is a fighting game which is purely intellectual and includes chance.\" — Richard Reti",
    "\"You may learn much more from a game you lose than from a game you win.\" — José Raúl Capablanca",
    "\"I always see a little chessboard in my mind with every move in its place.\" — Marcel Duchamp",
    "\"To avoid losing a piece, many a person has lost the game.\" — Savielly Tartakower",
    "\"Chess is everything: art, science, and sport.\" — Anatoly Karpov",
    "\"In chess, as in life, one is always trying to force the other into a compromise.\" — Charles Buxton",
    "\"Every Pawn is a potential Queen.\" — James Mason",
    "\"Even a poor plan is better than no plan at all.\" — Mikhail Chigorin",
    "\"The passed Pawn is a criminal, who should be kept under lock and key.\" — Aron Nimzowitsch",
    "\"Chess is not always about winning. Sometimes it's simply about learning.\" — Anonymous",
    "\"When you see a good move, look for a better one.\" — Emanuel Lasker",
    "\"It is always better to sacrifice your opponent's men.\" — Savielly Tartakower",
    "\"No price is too great for the scalp of the enemy King.\" — Koblentz",
    "\"Chess, like any creative activity, can exist only through the combined efforts of those with creative talent.\" — Mikhail Botvinnik",
    "\"Half the variations which are calculated in a tournament game turn out to be completely superfluous.\" — Jan Timman",
    "\"Chess is as much a mystery as women.\" — Purdy",
    "\"Discovered check is the dive bomber of the Chessboard.\" — Reuben Fine",
    "\"The King is the weakest piece on the board; the King is the strongest piece on the board.\" — Anonymous",
    "\"Different people feel differently about resigning.\" — Bobby Fischer",
    "\"When the Chess game is over, the Pawn and the King go back to the same box.\" — Irish Saying",
    "\"No one ever won a game by resigning.\" — Savielly Tartakower",
    "\"Chess demands total concentration.\" — Bobby Fischer",
    "\"Chess is a struggle against error.\" — Johannes Zukertort",
    "\"The sign of a great master is his ability to win a won game quickly and painlessly.\" — Irving Chernev",
    "\"Chess is a terrific way for kids to build self-image and self-esteem.\" — Saudin Robovic",
    "\"I have always a slight feeling of pity for the man who has no knowledge of Chess\" - Siegbert Tarrasch",
    "\"We like to think\" - Garry Kasparov"
];

export const fetchPuzzleStats = async (user, timeLimit, isEndgame, setPuzzleRating, setPuzzleAccuracy, setPuzzleCount) => {
  // Default rating when the user is not logged in
  const defaultPuzzleStats = { rating: 1000, accuracy: 0, count: 0 };

  if (!user) {
    // No user logged in, set default rating and return it
    setPuzzleRating(defaultPuzzleStats.rating);
    console.log('No user logged in, using default rating:', defaultPuzzleStats.rating);
    return defaultPuzzleStats.rating; // Return the default rating
  }

  const userRef = doc(db, "users", user.uid);
  const userDoc = await getDoc(userRef);

  if (userDoc.exists()) {
    const data = userDoc.data();

    // Determine which field to fetch based on time limit and endgame filter
    let ratingKey;
//    if (isEndgame) {
    if (false) {
      switch (timeLimit) {
        case 5:
          ratingKey = "fiveSecondEndgameStats";
          break;
        case 10:
          ratingKey = "tenSecondEndgameStats";
          break;
        case 30:
          ratingKey = "thirtySecondEndgameStats";
          break;
        case 60:
          ratingKey = "oneMinuteEndgameStats";
          break;
        default:
          ratingKey = "noTimeLimitEndgameStats";
          break;
      }
    } else {
      switch (timeLimit) {
        case 5:
          ratingKey = "fiveSecondPuzzleStats";
          break;
        case 10:
          ratingKey = "tenSecondPuzzleStats";
          break;
        case 30:
          ratingKey = "thirtySecondPuzzleStats";
          break;
        case 60:
          ratingKey = "oneMinutePuzzleStats";
          break;
        default:
          ratingKey = "noTimeLimitPuzzleStats";
          break;
      }
    }

    // Fetch the rating data based on the key
    const puzzleStats = data[ratingKey] || "r1000a0c0"; // Default to "r1000a0c0" if not found
    const { rating, accuracy, count } = decodePuzzleStats(puzzleStats);

    // Set the rating, accuracy, and count to state
    setPuzzleRating(rating);
    setPuzzleAccuracy(accuracy);
    setPuzzleCount(count);

    return rating; // Return the fetched rating
  } else {
    // If the user document doesn't exist, fallback to default rating and return it
    setPuzzleRating(defaultPuzzleStats.rating);
    return defaultPuzzleStats.rating; // Return the default rating
  }
};


// Helper function to decode puzzleStats string into individual values
const decodePuzzleStats = (puzzleStats) => {
  const rating = parseInt(puzzleStats.match(/r(\d+)/)[1], 10); // Extract rating
  const accuracy = parseFloat(puzzleStats.match(/a([\d.]+)/)[1]); // Extract accuracy
  const count = parseInt(puzzleStats.match(/c(\d+)/)[1], 10); // Extract puzzle count

  return { rating, accuracy, count };
};

export const fetchPuzzle = async (puzzleRating, setCurrentPuzzle, setLoadingPuzzle, loadingPuzzleRef, isEndgame) => {
  if (puzzleRating === undefined || loadingPuzzleRef.current) return;
  loadingPuzzleRef.current = true;
  setLoadingPuzzle(true);

  try {
    // Fetch a random chunk of puzzles
    const chunkNumber = Math.floor(Math.random() * 811) + 1;
    const url = `${process.env.PUBLIC_URL}/calculationpuzzles/puzzles_chunk_${chunkNumber}.json`;

    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');

    const puzzles = await response.json();

    // Filter puzzles based on rating
    let filteredPuzzles = puzzles.filter(puzzle => Math.abs(puzzle.Rating - puzzleRating) <= 50);

    // If endgame filter is active, filter to puzzles with 7 pieces or less
    if (isEndgame) {
      filteredPuzzles = filteredPuzzles.filter(puzzle => {
        const newGame = new Chess(puzzle.FEN); // Initialize Chess.js with the puzzle's FEN
        const pieceCount = countPieces(newGame.board()); // Count the number of pieces on the board
        return pieceCount <= 9; // Endgames are generally considered to have 7 or fewer pieces
      });
    }

    if (filteredPuzzles.length > 0) {
      const selectedPuzzle = filteredPuzzles[Math.floor(Math.random() * filteredPuzzles.length)];

      // Apply LeadingMoves if available
      if (selectedPuzzle.LeadingMoves) {
        const newGame = new Chess(selectedPuzzle.FEN);
        const leadingMoves = selectedPuzzle.LeadingMoves.split(' ');

        leadingMoves.forEach(move => {
          const from = move.substring(0, 2);
          const to = move.substring(2, 4);
          const promotion = move.length === 5 ? move[4] : undefined;
          newGame.move({ from, to, promotion });
        });

        selectedPuzzle.FEN = newGame.fen(); // Update FEN after leading moves
      }

      setCurrentPuzzle(selectedPuzzle); // Set the puzzle with updated FEN
    } else {
      console.log('No puzzles found within the rating range or matching the endgame filter');
    }
  } catch (error) {
    console.error('Failed to load puzzle data:', error);
  } finally {
    loadingPuzzleRef.current = false;
    setLoadingPuzzle(false);
  }
};

// Helper function to count the number of pieces on the board
const countPieces = (board) => {
  let pieceCount = 0;
  board.forEach(row => {
    row.forEach(square => {
      if (square !== null) pieceCount += 1; // Each non-null square is a piece
    });
  });
  return pieceCount;
};

  export const toggleEndgame = (setIsEndgame) => {
    setIsEndgame(prevIsEndgame => !prevIsEndgame); // Toggle the state
  };

const initializeStockfish = () => {
    const workerName = typeof SharedArrayBuffer !== "undefined" ?
      "stockfish-nnue-16.js" :
      "stockfish-nnue-16-single.js";

    const worker = new Worker(workerName);

  worker.onmessage = (e) => console.log("Stockfish Worker says:", e.data);
  return worker;
};

const parsePGN = (pgn) => {
  console.log("Extracting moves from PGN...");

  const sanitizedPGN = pgn
    .split(/\n|\r/)
    .filter((line) => !line.startsWith("[") && line.trim() !== "") // Ignore metadata and blank lines
    .join(" ")
    .replace(/\{[^}]*\}/g, "") // Remove comments {...}
    .replace(/\s+/g, " ") // Replace multiple spaces with a single space
    .trim();

  const moves = [];
  const tokens = sanitizedPGN.split(/\s+/);

  tokens.forEach((token) => {
    if (/^\d+\./.test(token)) return; // Skip move numbers
    if (!["*", "1-0", "0-1", "1/2-1/2"].includes(token)) {
      moves.push(token.trim());
    }
  });

  console.log("Extracted moves:", moves);
  return moves.filter((move) => move !== ""); // Remove any residual empty moves
};

const analyzeGame = (game, depth = 15) => {
  return new Promise((resolve, reject) => {
    if (!game || !game.pgn) {
      console.error("Invalid game data provided.");
      reject("Invalid game data");
      return;
    }

    console.log("Initializing analysis for game with PGN:", game.pgn);

    const worker = initializeStockfish();
    const chess = new Chess();
    const moves = parsePGN(game.pgn);
    const analysis = [];
    let currentMoveIndex = 0;
    let lastEvaluation = null;
    let evaluationReceivedForCurrentMove = false; // Flag to track evaluation

    if (!moves || moves.length === 0) {
      console.error("No moves found in PGN. Aborting analysis.");
      reject("No moves in PGN");
      return;
    }

    console.log("Parsed moves from PGN:", moves);
    const cpuCores = navigator.hardwareConcurrency || 1;

    worker.postMessage("uci"); // Initialize Stockfish
    worker.postMessage("ucinewgame"); // Start a new game
    worker.postMessage(`setoption name Threads value ${cpuCores}`);

const rateMove = (initialEval, finalEval, initialType, finalType, isBlackTurn) => {
  console.log("Black Turn: " + isBlackTurn);
  console.log(
    `Initial Eval: ${initialEval}, Final Eval: ${finalEval}, Initial Type: ${initialType}, Final Type: ${finalType}, Black's Turn: ${isBlackTurn}`
  );

  // Determine if the evaluation type is 'mate' or 'centipawn'
  const isMateType = (type) => type === "mate";
  const isCentipawnType = (type) => type === "cp";

  const initialIsMate = isMateType(initialType);
  const finalIsMate = isMateType(finalType);

  if (initialIsMate && finalIsMate) {
    // Both evaluations are mate
    const mateDiff = Math.abs(initialEval - finalEval);
    console.log(`Mate evaluation detected. Mate difference: ${mateDiff}`);

    if (initialEval < 0 && finalEval < 0) {
      console.log("Black is moving closer to a win. Rating: 5");
      return 5;
    } else if (initialEval > 0 && finalEval > 0) {
      console.log("White is moving closer to a win. Rating: 5");
      return 5;
    } else {
      console.log("Switching between winning and losing mate. Rating: 1");
      return 1;
    }
  } else if (initialIsMate || finalIsMate) {
    // Transition between mate and centipawn
    console.log("Transition between mate and centipawn detected.");

    if (initialIsMate && isCentipawnType(finalType)) {
      // From mate to centipawn
      if (initialEval < 0 && finalEval < 0) {
        console.log("Black moves from mate to centipawn but remains winning. Rating: 4");
        return 4;
      } else if (initialEval > 0 && finalEval > 0) {
        console.log("White moves from mate to centipawn but remains winning. Rating: 4");
        return 4;
      } else {
        console.log("Switching sides during mate-to-centipawn transition. Rating: 1");
        return 1;
      }
    } else if (isCentipawnType(initialType) && finalIsMate) {
      // From centipawn to mate
      if (finalEval < 0 && !isBlackTurn) {
        console.log("Black achieves mate. Rating: 5");
        return 5;
      } else if (finalEval > 0 && isBlackTurn) {
        console.log("White achieves mate. Rating: 5");
        return 5;
      } else {
        console.log("Mate achieved, but evaluation doesn't match the turn. Rating: 2");
        return 2;
      }
    }
  } else {
    // Both evaluations are centipawns
    const evalLoss = Math.abs(initialEval - finalEval);
    console.log("Centipawn evaluation detected.");
    console.log(`Evaluation loss: ${evalLoss}, Absolute initial evaluation: ${Math.abs(initialEval)}`);

    if (Math.abs(initialEval) <= 1) {
      if (evalLoss <= 0.4) return 5;
      if (evalLoss <= 0.8) return 4;
      if (evalLoss <= 1.1) return 3;
      if (evalLoss <= 1.5) return 2;
      return 1;
    } else if (Math.abs(initialEval) <= 2) {
      if (evalLoss <= 0.7) return 5;
      if (evalLoss <= 1.1) return 4;
      if (evalLoss <= 1.6) return 3;
      if (evalLoss <= 2.1) return 2;
      return 1;
    } else if (Math.abs(initialEval) <= 4) {
      if (evalLoss <= 1.0) return 5;
      if (evalLoss <= 1.5) return 4;
      if (evalLoss <= 2.2) return 3;
      if (evalLoss <= 3.0) return 2;
      return 1;
    } else {
      if (evalLoss <= 2.0) return 5;
      if (evalLoss <= 3.0) return 4;
      if (evalLoss <= 4.0) return 3;
      if (evalLoss <= 5.0) return 2;
      return 1;
    }
  }
};


        const analyzeNextMove = () => {
          if (currentMoveIndex >= moves.length) {
//            console.log("All moves analyzed. Terminating Stockfish worker.");
            worker.terminate();
            resolve({ ...game, analysis });
            return;
          }

          evaluationReceivedForCurrentMove = false; // Reset for the next move
          const move = moves[currentMoveIndex];
          chess.move(move); // Apply the move
          const fen = chess.fen();

          console.log(`Analyzing move ${currentMoveIndex + 1}/${moves.length}: ${move}`);
          console.log(`FEN: ${fen}`);

          analysis.push({ move, fen });

          worker.postMessage(`position fen ${fen}`);
//          console.log("Sent to Stockfish: position fen " + fen);
          worker.postMessage(`go depth ${depth}`);
//          console.log("Sent to Stockfish: go depth " + depth);
        };

worker.onmessage = (e) => {
  const message = e.data;

  // Handle evaluation message
  if (!evaluationReceivedForCurrentMove && message.includes(`depth ${depth}`) && message.includes("score")) {
    const rawEvaluation = parseEvaluation(message);

    if (!rawEvaluation) {
      console.error("Failed to parse evaluation. Skipping...");
      return;
    }

    const fen = analysis[currentMoveIndex].fen;
    const isBlackTurn = fen.split(" ")[1] === "b";

    const adjustedEvaluation = {
      type: rawEvaluation.type,
      value:
        rawEvaluation.type === "mate"
          ? rawEvaluation.value * (isBlackTurn ? -1 : 1)
          : rawEvaluation.value * (isBlackTurn ? -1.8 : 1.8),
    };

    lastEvaluation = adjustedEvaluation;
    evaluationReceivedForCurrentMove = true; // Mark evaluation as received

    console.log(`Final evaluation for move ${currentMoveIndex + 1}:`, adjustedEvaluation);

    if (currentMoveIndex > 0) {
      const prevEvaluation = analysis[currentMoveIndex - 1]?.evaluation || {};
      const rating = rateMove(
        prevEvaluation.value,
        adjustedEvaluation.value,
        prevEvaluation.type,
        adjustedEvaluation.type,
        isBlackTurn
      );
      analysis[currentMoveIndex].rating = rating; // Assign rating to current move
      console.log(`Move ${currentMoveIndex + 1} rating: ${rating}`);
    }
  }

  // Handle best move message
  if (message.startsWith("bestmove")) {
    const bestMoveMatch = message.match(/bestmove (\w+)/);
    const bestMoveStandard = bestMoveMatch ? bestMoveMatch[1] : null;

    // Convert the best move into algebraic notation
    const bestMoveAlgebraic = convertToAlgebraic(bestMoveStandard, chess);

    if (lastEvaluation) {
      analysis[currentMoveIndex].evaluation = lastEvaluation;
    }

    // Save the best moves in both notations
    analysis[currentMoveIndex].recommendedMove = {
      standard: bestMoveStandard,
      algebraic: bestMoveAlgebraic,
    };

    console.log(
      `Best move for position ${currentMoveIndex + 1}: Standard: ${bestMoveStandard}, Algebraic: ${bestMoveAlgebraic}`
    );

    // Access the move from `analysis`
    const currentMove = analysis[currentMoveIndex]?.move;
    const bestMoveInPosition = analysis[currentMoveIndex - 1]?.recommendedMove.algebraic;
    console.log('current move: ' + currentMove + ' best move ' + bestMoveInPosition)
    if (bestMoveInPosition && currentMove === bestMoveInPosition) {
      analysis[currentMoveIndex].rating = 5;
      console.log(`Move ${currentMoveIndex + 1} is the best move. Automatically rated as 5.`);
    }

    lastEvaluation = null; // Reset for the next move
    currentMoveIndex++;
    analyzeNextMove();
  }
};


        worker.onerror = (error) => {
          console.error("Stockfish Worker error:", error);
          worker.terminate();
          reject(error);
        };

        analyzeNextMove();
  });
};

const parseEvaluation = (msg) => {
  const scoreMatch = msg.match(/score (cp|mate) (-?\d+)/);
  if (!scoreMatch) {
    console.error("Failed to parse evaluation from message:", msg);
    return null;
  }

  const type = scoreMatch[1];
  const value = parseInt(scoreMatch[2], 10);

  if (type === "mate") {
    return { type: "mate", value }; // Mating score
  }
  return { type: "cp", value: value / 100 }; // Centipawn score converted to pawns
};

const convertToAlgebraic = (move, chessInstance) => {
  if (!move || !chessInstance) return "N/A";
  try {
    const moves = chessInstance.moves({ verbose: true }); // Get all legal moves in verbose format
    const matchingMove = moves.find((m) => `${m.from}${m.to}` === move);
    return matchingMove ? matchingMove.san : "N/A"; // Return algebraic notation (SAN) if found
  } catch (err) {
    console.error("Error converting move to algebraic notation:", err);
    return "N/A";
  }
};

export { analyzeGame };

export const drawEngineArrow = (canvasRef, fromSquare, toSquare, color) => {
  const canvas = canvasRef.current;
  if (!canvas) return;

  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  const canvasSize = canvas.width;
  const squareSize = canvasSize / 8;

  const fromCoords = squareToCanvasCoords(fromSquare, squareSize);
  const toCoords = squareToCanvasCoords(toSquare, squareSize);

  if (!fromCoords || !toCoords) return;

  const headlen = squareSize / 3; // Length of the arrow head
  const angle = Math.atan2(toCoords.y - fromCoords.y, toCoords.x - fromCoords.x);
  const offset = headlen * 0.75; // Adjust to get the line end closer to the arrow head
  const stemEndX = toCoords.x - offset * Math.cos(angle);
  const stemEndY = toCoords.y - offset * Math.sin(angle);

  // Draw the arrow stem
  ctx.beginPath();
  ctx.moveTo(fromCoords.x, fromCoords.y);
  ctx.lineTo(stemEndX, stemEndY);
  ctx.strokeStyle = color;
  ctx.lineWidth = squareSize / 10; // Adjust thickness as needed
  ctx.stroke();

  // Draw the arrow head
  ctx.beginPath();
  ctx.moveTo(toCoords.x, toCoords.y);
  ctx.lineTo(
    toCoords.x - headlen * Math.cos(angle - Math.PI / 6),
    toCoords.y - headlen * Math.sin(angle - Math.PI / 6)
  );
  ctx.lineTo(
    toCoords.x - headlen * Math.cos(angle + Math.PI / 6),
    toCoords.y - headlen * Math.sin(angle + Math.PI / 6)
  );
  ctx.closePath();
  ctx.fillStyle = color;
  ctx.fill();
};

const squareToCanvasCoords = (square, squareSize) => {
  const file = square.charCodeAt(0) - 'a'.charCodeAt(0); // 'a' -> 0, 'b' -> 1, ...
  const rank = 8 - parseInt(square[1], 10); // '1' -> 7, '2' -> 6, ...
  return {
    x: file * squareSize + squareSize / 2,
    y: rank * squareSize + squareSize / 2,
  };
};

export const highlightLastMove = (selectedMoveIndex, game, setSquareStyles) => {
  let newStyles = {};

  if (selectedMoveIndex >= 0) {
    const history = game.history({ verbose: true });

    const lastMove = history[selectedMoveIndex];
    if (lastMove) {
      const from = lastMove.from;
      const to = lastMove.to;


      // Highlight the `from` and `to` squares
      newStyles[from] = { backgroundColor: "rgba(255, 255, 0, 0.4)" };
      newStyles[to] = { backgroundColor: "rgba(255, 255, 0, 0.4)" };

      // Get the last move in algebraic notation (SAN)
      const san = lastMove.san;

      // Check if the move indicates check or checkmate
      if (san.includes("+") || san.includes("#")) {
        console.log("King is in check or checkmate!");
        const kingPosition = findKingPosition(game, selectedMoveIndex);
        if (kingPosition) {
          console.log(`King in check at position: ${kingPosition}`);

          // Apply a red circle highlight style to the king's position
          newStyles[kingPosition] = {
            background: "radial-gradient(circle at center, rgba(255, 0, 0, 1) 0%, rgba(255, 0, 0, 0) 80%)",
          };
        } else {
          console.log("Could not find the king's position.");
        }
      }
    } else {
//      console.log("No last move found at the given index.");
    }
  } else {
//    console.log("No moves to highlight.");
  }

  // Apply the new styles
  setSquareStyles(newStyles);
};

const findKingPosition = (game, selectedMoveIndex) => {
  console.log("Starting findKingPosition...");
  console.log(`Initial FEN: ${game.fen()}`);
  console.log(`Selected move index: ${selectedMoveIndex}`);

const tryMainlineReplay = () => {
  // Extract the starting FEN from the current game
  const setUp = game.header()?.SetUp;
  const fenFromHeader = game.header()?.FEN;
  const startingFen = setUp === "1" && fenFromHeader ? fenFromHeader : "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1";

  console.log("Extracted SetUp from header:", setUp);
  console.log("Extracted FEN from header:", fenFromHeader);
  console.log("Determined starting FEN:", startingFen);

  const gameCopy = new Chess(startingFen); // Start from the extracted FEN
  const history = game.history({ verbose: true });

  console.log("Starting FEN:", startingFen);
  console.log("Game history (verbose):", history);

  // Replay the moves up to the selected move index
  for (let i = 0; i <= selectedMoveIndex; i++) {
    const move = history[i];
    if (move) {
      gameCopy.move(move.san); // Play the move in SAN notation
    }
  }

  console.log(`FEN after replaying moves: ${gameCopy.fen()}`);
  return gameCopy;
};

  const findKing = (gameInstance) => {
    const color = gameInstance.turn(); // Determine whose turn it is ('w' or 'b')
    console.log(`Finding king for color: ${color}`);

    const board = gameInstance.board();
    console.log("Current board state:", board);

    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[i].length; j++) {
        const piece = board[i][j];
        if (piece && piece.type === "k" && piece.color === color) {
          // Convert 0-indexed board coordinates to algebraic notation
          const row = "87654321"[i];
          const col = "abcdefgh"[j];
          const position = col + row;
          console.log(`Found king at: ${position}`);
          return position;
        }
      }
    }

    throw new Error("King not found on the board");
  };

  try {
    // Try to find the king by replaying moves
    console.log("Attempting mainline replay...");
    const gameCopy = tryMainlineReplay();
    return findKing(gameCopy);
  } catch (error) {
    console.warn("Mainline replay failed, attempting sideline handling:", error.message);

    // Fall back to sideline handling: Assume game is already at the correct position
    try {
      return findKing(game);
    } catch (finalError) {
      console.error("Failed to find king in sideline or mainline:", finalError.message);
      return null;
    }
  }
};

export const onSquareClick = (square, setPromotionOpen, promotionDetails, setPromotionDetails, game, pieceSquare, setPieceSquare, restoreSquareStyles, squareStyles, setSquareStyles, handleMove, selectedMoveIndex ) => {
  setPromotionOpen(false);
  setPromotionDetails(null);
  const piece = game.get(square); // Get the piece on the clicked square

  const isOnLastMove = selectedMoveIndex === game.history().length - 1;
  if (!isOnLastMove) {
    console.log(`Not on the last move. Returning early. SelectedMoveIndex: ${selectedMoveIndex}, Game History Length: ${game.history().length - 1} ${!isOnLastMove}`);
    return;
  }

  // Only capture initial square styles if there isn't a piece already selected
  if (!pieceSquare) {
    restoreSquareStyles.current = { ...squareStyles }; // Preserve existing styles
  }

  if (square === pieceSquare) {
    // Deselect the currently selected square
    setPieceSquare("");
    setSquareStyles(restoreSquareStyles.current); // Restore previously captured styles
    return;
  }

  if (piece && piece.color === game.turn()) {
    // Highlight moves for the selected piece
    const moves = game.moves({ square, verbose: true });

    const squaresToHighlight = moves.map((move) => move.to);

    const darkSquare = "var(--dark-square-color)";
    const lightSquare = "var(--light-square-color)";

    const highlightStyles = squaresToHighlight.reduce((acc, curr) => {
      const squareColor =
        (curr.charCodeAt(0) - "a".charCodeAt(0) + parseInt(curr[1], 10)) % 2 === 0
          ? lightSquare
          : darkSquare;
      const highlightColor =
        squareColor === lightSquare ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)";

      if (game.get(curr)) {
        // Highlight squares with pieces
        acc[curr] = {
          background: `
            linear-gradient(to bottom right, ${highlightColor} 30%, transparent 30%) 0 0,
            linear-gradient(to bottom left, ${highlightColor} 30%, transparent 30%) 100% 0,
            linear-gradient(to top left, ${highlightColor} 30%, transparent 30%) 100% 100%,
            linear-gradient(to top right, ${highlightColor} 30%, transparent 30%) 0 100%
          `,
          backgroundSize: "40% 40%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0 0, 100% 0, 100% 100%, 0 100%",
        };
      } else {
        // Highlight empty squares
        acc[curr] = {
          background: `radial-gradient(circle, ${highlightColor} 25%, transparent 27%)`,
          borderRadius: "50%",
        };
      }
      return acc;
    }, {});

    setSquareStyles({
      ...restoreSquareStyles.current, // Retain existing styles
      ...highlightStyles,
      [square]: { backgroundColor: "rgba(255, 255, 0, 0.4)" }, // Highlight the selected square
    });
    setPieceSquare(square);
  } else if (pieceSquare) {
    // Get all legal moves and check if there is a match
    const allMoves = game.moves({ verbose: true });

    const legalMove = allMoves.find(
      (move) => move.from === pieceSquare && move.to === square
    );

    if (legalMove) {
      // If the move is legal, apply it
      const isPromotion = isPromotionMove(pieceSquare, square, game);
      if (isPromotion) {
        setPromotionOpen(true);
        setPromotionDetails({ pieceSquare, square });
      } else {
        handleMove(pieceSquare, square);
      }
    } else {
      setPieceSquare(""); // Clear the selected square
      setSquareStyles(restoreSquareStyles.current); // Restore previously captured styles
    }
  }
};

  const isPromotionMove = (sourceSquare, targetSquare, game) => {
    const targetRank = targetSquare[1];
    const pieceOnSource = game.get(sourceSquare);

    return pieceOnSource &&
           pieceOnSource.type === 'p' &&
           ((pieceOnSource.color === 'w' && targetRank === '8') ||
            (pieceOnSource.color === 'b' && targetRank === '1'));
  };

  export const onDrop = ({ sourceSquare, targetSquare, game, setPromotionDetails, setPromotionOpen, handleMove, selectedMoveIndex }) => {
//    console.log("onDrop called with:", { sourceSquare, targetSquare, selectedMoveIndex });
    const promotion = 'q';
    const legalMoves = game.moves({ verbose: true });

      const isOnLastMove = selectedMoveIndex === game.history().length - 1;
      if (!isOnLastMove) {
        console.log(`Not on the last move. Returning early. SelectedMoveIndex: ${selectedMoveIndex}, Game History Length: ${game.history().length - 1} ${!isOnLastMove}`);
        return;
      }
    const moveIsLegal = legalMoves.some(move => move.from === sourceSquare && move.to === targetSquare);

    if (!moveIsLegal) {
      return 'snapback';
    }

    const piece = game.get(sourceSquare);
    const isPromotion = piece.type === 'p' && (targetSquare[1] === '8' || targetSquare[1] === '1');
    const pieceSquare = sourceSquare
    const square = targetSquare
    if (isPromotion) {
      setPromotionOpen(true);
      setPromotionDetails({ pieceSquare, square });
    } else {
      handleMove(sourceSquare, targetSquare);
    }
  };

const calculateDifferentialCaptures = (fen) => {
  // Starting counts for a full chess set
  const startingCounts = { P: 8, R: 2, N: 2, B: 2, Q: 1, K: 1 };
  // Current counts initialized to 0
  const currentCounts = { w: { P: 0, R: 0, N: 0, B: 0, Q: 0, K: 0 }, b: { P: 0, R: 0, N: 0, B: 0, Q: 0, K: 0 } };

  // Parse the piece placement part of the FEN
  const piecesPlacement = fen.split(' ')[0];
  piecesPlacement.replace(/\d/g, '').split('').forEach(piece => {
    // Increment count for white and black pieces
    if (currentCounts.w[piece.toUpperCase()] !== undefined) {
      piece === piece.toUpperCase() ? currentCounts.w[piece]++ : currentCounts.b[piece.toUpperCase()]++;
    }
  });

  // Calculate differential captures, ensuring we don't show duplicates
  const differentialCaptures = { white: {}, black: {} };
  Object.keys(startingCounts).forEach(piece => {
    const whiteCaptures = startingCounts[piece] - currentCounts.w[piece];
    const blackCaptures = startingCounts[piece] - currentCounts.b[piece];

    // Calculate the differential for each piece type
    const differential = whiteCaptures - blackCaptures;

    if (differential > 0) {
      // If positive, white has captured more of this piece
      differentialCaptures.white[piece] = differential;
    } else if (differential < 0) {
      // If negative, black has captured more of this piece
      differentialCaptures.black[piece] = -differential; // Use the positive value of the differential
    }
    // If differential is 0, it means an equal number of pieces were captured, so we don't show these pieces
  });

  return differentialCaptures;
}

const calculateMaterialAdvantage = (fen) => {
  const pieceValues = { 'p': 1, 'n': 3, 'b': 3, 'r': 5, 'q': 9 };
  let whiteMaterial = 0;
  let blackMaterial = 0;

  // Extract the piece positions from the FEN string
  const pieces = fen.split(' ')[0];

  // Iterate through each character in the FEN piece placement
  for (const char of pieces) {
    if (char in pieceValues) {
      // Add value if the piece is black (lowercase in FEN denotes black pieces)
      blackMaterial += pieceValues[char];
    } else if (char.toLowerCase() in pieceValues) {
      // Add value if the piece is white (uppercase in FEN denotes white pieces)
      whiteMaterial += pieceValues[char.toLowerCase()];
    }
    // Ignore slashes and numbers (which denote empty squares) in FEN
  }

  // Material advantage: positive if white has more material, negative if black has more
  return whiteMaterial - blackMaterial;
}

export const renderCapturedPieces = (fen) => {
  const materialAdvantage = calculateMaterialAdvantage(fen);
  const differentialCaptures = calculateDifferentialCaptures(fen);

  const normalPieceImages = {
  wP: process.env.PUBLIC_URL + "pieces/normal/wP.png",
  wR: process.env.PUBLIC_URL + "pieces/normal/wR.png",
  wN: process.env.PUBLIC_URL + "pieces/normal/wN.png",
  wB: process.env.PUBLIC_URL + "pieces/normal/wB.png",
  wQ: process.env.PUBLIC_URL + "pieces/normal/wQ.png",
  wK: process.env.PUBLIC_URL + "pieces/normal/wK.png",
  bP: process.env.PUBLIC_URL + "pieces/normal/bP.png",
  bR: process.env.PUBLIC_URL + "pieces/normal/bR.png",
  bN: process.env.PUBLIC_URL + "pieces/normal/bN.png",
  bB: process.env.PUBLIC_URL + "pieces/normal/bB.png",
  bQ: process.env.PUBLIC_URL + "pieces/normal/bQ.png",
  bK: process.env.PUBLIC_URL + "pieces/normal/bK.png",
};

const renderPieceImages = (color) => {
  // Convert 'w' or 'b' to uppercase for image retrieval
  const colorPrefix = color === 'white' ? 'w' : 'b';

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
      {Object.entries(differentialCaptures[color]).flatMap(([pieceType, count]) =>
        Array.from({ length: count }, (_, i) => {
          const imageKey = `${colorPrefix}${pieceType.toUpperCase()}`;
          return (
            <img
              key={`${color}-${pieceType}-${i}`}
              src={normalPieceImages[imageKey]}
              alt={`${colorPrefix}${pieceType.toUpperCase()}`}
              style={{ width: '20px', margin: '2px' }}
            />
          );
        })
      )}
    </div>
  );
};

return (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '10px' }}>
      {renderPieceImages('white')}
    </div>
    {/* Material advantage display */}
    <div style={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}>
      {materialAdvantage > 0 ? (
        <span style={{ color: 'gray', fontWeight: 'bold' }}>+{materialAdvantage}</span>
      ) : materialAdvantage < 0 ? (
        <span style={{ color: 'gray', fontWeight: 'bold' }}>{materialAdvantage}</span>
      ) : ''}
    </div>
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '10px' }}>
      {renderPieceImages('black')}
    </div>
  </div>
);

};

export const handleTakeBackMove = (game, selectedMoveIndex, initialFen, setGame, setSelectedMoveIndex, setDisplayFen) => {
  // Get the current move history and ensure moves are present
  const moves = game.history({ verbose: true });
  if (moves.length === 0) {
    console.warn("No moves to take back");
    return; // No moves to take back
  }

  // Ensure the selected move index is aligned with the current state
  const isAtCurrentMove = selectedMoveIndex === moves.length - 1;

  // Remove the last move only if at the current position
  const updatedMoves = isAtCurrentMove ? moves.slice(0, -1) : moves;

  // Create a new Chess instance and reset it to the initial FEN
  const newGame = new Chess(initialFen);

  // Replay moves up to the adjusted index
  updatedMoves.slice(0, selectedMoveIndex).forEach((move, index) => {
    const moveResult = newGame.move(move.san);
    console.log(`Replaying move ${index + 1}:`, move.san, "Result:", moveResult);
  });

  // Update the FEN display with the new game state
  const newFen = newGame.fen();

  // Update state to reflect the new game state
  setGame(newGame);
  setDisplayFen(newFen);

  // Adjust indices only if at the current position
  setSelectedMoveIndex((prevIndex) => Math.max(-1, prevIndex - 1));

};

export const handleSidelineMove = ({
  move,
  tempGame,
  displayFen,
  selectedMoveIndex,
  selectedSidelineIndex,
  selectedSidelineMoveIndex,
  setSelectedSidelineIndex,
  setSelectedSidelineMoveIndex,
  setSidelines,
}) => {
  if (selectedMoveIndex === -1) {
    console.log("Selected move index is -1. Exiting early.");
    return;
  }

  setSidelines((prev) => {
    const updatedSidelines = [...prev];

    // Initialize sideline structure if not present
    if (!updatedSidelines[selectedMoveIndex]) {
      console.log(`Initializing sideline structure for move index ${selectedMoveIndex}.`);
      updatedSidelines[selectedMoveIndex] = [];
    }

    const sideline = updatedSidelines[selectedMoveIndex];

    if (
      sideline.length > 0 &&
      sideline[sideline.length - 1].fen === displayFen
    ) {
      console.log("Continuing existing sideline.");
      // Append the move to the existing sideline
      sideline[sideline.length - 1].moves.push(move.san);
      sideline[sideline.length - 1].fen = tempGame.fen();
      setSelectedSidelineMoveIndex((prevIndex) => prevIndex + 1);
    } else if (
      selectedSidelineIndex !== null &&
      selectedSidelineMoveIndex !== null &&
      selectedSidelineMoveIndex < sideline[selectedSidelineIndex]?.moves.length - 1
    ) {
      console.log("Starting a new sideline from an existing sideline midpoint.");

      const currentSideline = sideline[selectedSidelineIndex];
      const initialFen = currentSideline.initialFen;

      // Combine moves up to the current point in the new sideline
      const movesUpToCurrent = [
        ...currentSideline.moves.slice(0, selectedSidelineMoveIndex + 1),
        move.san,
      ];

      const tempGameForNewSideline = new Chess(initialFen);
      movesUpToCurrent.forEach((moveSan) => tempGameForNewSideline.move(moveSan));

      sideline.push({
        initialFen: initialFen,
        fen: tempGame.fen(),
        moves: movesUpToCurrent,
      });

      setSelectedSidelineMoveIndex(movesUpToCurrent.length - 1);
      setSelectedSidelineIndex(sideline.length - 1);
    } else {
      console.log("Starting a new sideline from the current position.");

      // Create a new sideline starting from the current display FEN
      sideline.push({
        initialFen: displayFen,
        fen: tempGame.fen(),
        moves: [move.san],
      });

      setSelectedSidelineMoveIndex(0);
      setSelectedSidelineIndex(sideline.length - 1);
    }

    console.log("Updated sidelines:", JSON.stringify(updatedSidelines));
    return updatedSidelines;
  });
};

export const handleSidelineSquareClick = (
  square,
  setPromotionOpen,
  promotionDetails,
  setPromotionDetails,
  displayFen,
  setDisplayFen,
  pieceSquare,
  setPieceSquare,
  restoreSquareStyles,
  squareStyles,
  setSquareStyles,
  setSidelines,
  selectedSidelineIndex,
  setSelectedSidelineIndex,
  selectedSidelineMoveIndex,
  setSelectedSidelineMoveIndex,
  selectedMoveIndex,
  sidelines
) => {
  const tempGame = new Chess();
  tempGame.load(displayFen);

  const piece = tempGame.get(square);

  if (!pieceSquare) {
    restoreSquareStyles.current = { ...squareStyles };
  }

  if (square === pieceSquare) {
    setPieceSquare("");
    setSquareStyles(restoreSquareStyles.current);
    return;
  }

  if (piece && piece.color === tempGame.turn()) {
    const moves = tempGame.moves({ square, verbose: true });
    const squaresToHighlight = moves.map((move) => move.to);

    const highlightStyles = squaresToHighlight.reduce((acc, curr) => {
      const squareColor =
        (curr.charCodeAt(0) - "a".charCodeAt(0) + parseInt(curr[1], 10)) % 2 === 0
          ? "var(--light-square-color)"
          : "var(--dark-square-color)";
      const highlightColor =
        squareColor === "var(--light-square-color)" ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)";

      acc[curr] = {
        background: `radial-gradient(circle, ${highlightColor} 25%, transparent 27%)`,
        borderRadius: "50%",
      };
      return acc;
    }, {});

    setSquareStyles({
      ...restoreSquareStyles.current,
      ...highlightStyles,
      [square]: { backgroundColor: "rgba(255, 255, 0, 0.4)" },
    });
    setPieceSquare(square);
  } else if (pieceSquare) {
    const legalMove = tempGame.moves({ verbose: true }).find(
      (move) => move.from === pieceSquare && move.to === square
    );

    if (legalMove) {
      const isPromotion = isPromotionMove(pieceSquare, square, tempGame);
      if (isPromotion) {
        setPromotionOpen(true);
        setPromotionDetails({ pieceSquare, square });
      } else {
        const move = tempGame.move({ from: pieceSquare, to: square });
        if (move) {
          handleSidelineMove({
            move,
            tempGame,
            displayFen,
            selectedMoveIndex,
            selectedSidelineIndex,
            selectedSidelineMoveIndex,
            setSelectedSidelineIndex,
            setSelectedSidelineMoveIndex,
            setSidelines,
          });
          setDisplayFen(tempGame.fen());
          setPieceSquare("");
          setSquareStyles({});
        }
      }
    } else {
      setPieceSquare("");
      setSquareStyles(restoreSquareStyles.current);
    }
  }
};

export const onDropWithSidelines = ({
  sourceSquare,
  targetSquare,
  game,
  setPromotionDetails,
  setPromotionOpen,
  displayFen,
  setDisplayFen,
  selectedMoveIndex,
  selectedSidelineIndex,
  setSelectedSidelineIndex,
  selectedSidelineMoveIndex,
  setSelectedSidelineMoveIndex,
  setSidelines,
}) => {
  console.log('in on drop. fen: ' + displayFen + ' sourcesqure: ' + sourceSquare + ' target square ' + targetSquare)
  const promotion = 'q';
  const tempGame = new Chess();
  tempGame.load(displayFen);

  console.log("onDropWithSidelines called.");
  console.log(`Source: ${sourceSquare}, Target: ${targetSquare}`);

  const legalMoves = tempGame.moves({ verbose: true });
  const moveIsLegal = legalMoves.some(
    (move) => move.from === sourceSquare && move.to === targetSquare
  );

  if (!moveIsLegal) {
    console.log("Move is illegal. Returning 'snapback'.");
    return 'snapback';
  }

  const piece = tempGame.get(sourceSquare);
  const isPromotion =
    piece.type === 'p' &&
    (targetSquare[1] === '8' || targetSquare[1] === '1');

  if (isPromotion) {
    console.log("Detected promotion. Opening promotion modal.");
    setPromotionOpen(true);
    setPromotionDetails({ pieceSquare: sourceSquare, square: targetSquare });
    return;
  }

  const move = tempGame.move({ from: sourceSquare, to: targetSquare, promotion });

  if (!move) {
    console.error("Move could not be played on tempGame. Returning 'snapback'.");
    return 'snapback';
  }

  handleSidelineMove({
    move,
    tempGame,
    displayFen,
    selectedMoveIndex,
    selectedSidelineIndex,
    selectedSidelineMoveIndex,
    setSelectedSidelineIndex,
    setSelectedSidelineMoveIndex,
    setSidelines,
  });

  setDisplayFen(tempGame.fen());
  console.log("Updated display FEN:", tempGame.fen());
};

export const getAccuracyColor = (accuracy) => {
  if (accuracy === null || accuracy === undefined) return 'rgba(128, 128, 128, 0.5)'; // Default gray with transparency

  const baseOpacity = 0.6; // Adjust transparency level

  if (accuracy >= 75 && accuracy <= 95) {
    const distanceFrom85 = Math.abs(accuracy - 85);

    if (distanceFrom85 <= 2) {
      // Green near 85%
      return `rgba(50, 200, 50, ${baseOpacity})`; // Strong green with transparency
    } else if (distanceFrom85 <= 5) {
      // Yellowish transition near 80 and 90
      const yellowIntensity = Math.min(Math.max((5 - distanceFrom85) * 50, 0), 255);
      return `rgba(255, ${200 - yellowIntensity}, 50, ${baseOpacity})`; // More yellow around edges
    } else {
      // Transition closer to orange
      const orangeIntensity = Math.min(Math.max((distanceFrom85 - 5) * 25, 0), 255);
      return `rgba(255, ${150 - orangeIntensity}, 50, ${baseOpacity})`; // Orangish tones
    }
  } else {
    // Red outside the range of 75% to 95%
    const redIntensity = Math.min(Math.max((Math.abs(85 - accuracy) - 5) * 30, 0), 255);
    return `rgba(255, ${150 - redIntensity}, ${150 - redIntensity}, ${baseOpacity})`; // Muted red with transparency
  }
};

export const reverseTurnInFEN = (fen) => {
  const parts = fen.split(" ");

  // Switch the active color
  parts[1] = parts[1] === "w" ? "b" : "w";

  // Clear the en passant square
  parts[3] = "-";

  return parts.join(" ");
};

export const updateLevelProgress = async (userId, level, success, puzzleNumber) => {
    if (!userId) {
        console.error('No user ID provided. Cannot update progress.');
        return null;
    }

    const endgamesDocRef = doc(db, 'users', userId);

    try {
        const endgamesSnapshot = await getDoc(endgamesDocRef);
        let levels = {};

        if (endgamesSnapshot.exists()) {
            levels = endgamesSnapshot.data().levels || {};
        }

        let levelString = levels[level] || "";
        const puzzleEntries = levelString.split(';').filter(entry => entry.trim() !== "");

        console.log("Initial puzzle entries:", puzzleEntries);

        let puzzleFound = false;
        const updatedEntries = puzzleEntries.map(entry => {
            // Validate entry structure
            if (!entry.includes(':') || !entry.includes(',')) {
                console.error(`Invalid entry format: ${entry}`);
                return entry; // Return as is to avoid breaking
            }

            // Extract parts
            const parts = entry.split(':');
            if (parts.length < 3) {
                console.error(`Malformed entry: ${entry}`);
                return entry; // Return as is
            }

            const index = parts[0];
            const rank = parts[1];
            const rest = parts.slice(2).join(':'); // Join the rest in case timestamp contains colons
            const [timestamp, history] = rest.split(',');

            if (parseInt(index) === puzzleNumber) {
                puzzleFound = true;

                // Updated cleaning logic
                let cleanHistory = history ? history.match(/[sf]+/g)?.join('') || '' : '';
                console.log(`Clean history before update for puzzle ${index}:`, cleanHistory);

                const newHistory = (cleanHistory + (success ? 's' : 'f')).slice(-10);

                // Analyze recent attempts
                const recentAttempts = newHistory.slice(-3); // Last 3 attempts
                const successCount = recentAttempts.split('').filter(h => h === 's').length;

                let newRank = parseInt(rank);
                let nextReviewDate;

                // Update rank and next review date
                if (success) {
                    if (recentAttempts === 'sss') {
                        newRank = 5;
                        nextReviewDate = calculateNextReviewDate(newRank);
                    } else if (successCount >= 2) {
                        newRank = Math.min(5, newRank + 1);
                        nextReviewDate = calculateNextReviewDate(newRank);
                    } else {
                        nextReviewDate = calculateNextReviewDate(newRank);
                    }
                } else {
                    const isFailureStreak = recentAttempts === 'fff';
                    if (isFailureStreak) {
                        newRank = Math.max(1, newRank - 1);
                    }
                    nextReviewDate = calculateToday();
                }

                console.log(`Updated rank for puzzle ${index}:`, newRank);
                console.log(`Next review date for puzzle ${index}:`, nextReviewDate);

                return `${index}:${newRank}:${nextReviewDate},${newHistory}`;
            }
            return entry; // Leave other entries unchanged
        });

        // If the puzzle is not found, add a new entry
        if (!puzzleFound) {
            let newRank = success ? 5 : 1;
            let nextReviewDate = success ? calculateNextReviewDate(5) : calculateToday();
            let history = success ? 's' : 'f';
            console.log(`Adding new puzzle ${puzzleNumber} with history:`, history);
            updatedEntries.push(`${puzzleNumber}:${newRank}:${nextReviewDate},${history}`);
        }

        // Sort entries by puzzle number
        updatedEntries.sort((a, b) => parseInt(a.split(':')[0]) - parseInt(b.split(':')[0]));
        levels[level] = updatedEntries.join(';');

        // Save updated levels to the database
        await setDoc(endgamesDocRef, { levels }, { merge: true });

        console.log(`Updated Level ${level}, Puzzle ${puzzleNumber} for user ${userId}`);

        // Return the updated puzzle details
        const updatedPuzzle = updatedEntries.find(entry => parseInt(entry.split(':')[0]) === puzzleNumber);

        // Parse the updated puzzle data
        const parts = updatedPuzzle.split(':');
        const index = parts[0];   // Puzzle number
        const rank = parts[1];    // Rank (stars)

        // Combine remaining parts for timestamp and history
        const combined = parts.slice(2).join(':'); // Preserve full timestamp with ':'

        // Split the combined data into dueDate and history
        const [dueDate, history = ""] = combined.split(',');

        // Parse the due date as a Date object
        let dueDateObj = new Date(dueDate);

        // Handle invalid date fallback
        if (isNaN(dueDateObj.getTime())) {
            console.error(`Invalid dueDate: ${dueDate}`);
            dueDateObj = new Date(); // Fallback to today
        }

        // Return structured data
        return {
            stars: parseInt(rank),                // Number of stars
            attemptHistory: history.split(''),    // Convert history string to array
            dueDate: dueDateObj.toISOString(),    // Return ISO format for consistency
        };

    } catch (error) {
        console.error(`Error updating progress: ${error}`);
        return null; // Return null in case of error
    }
};

const calculateNextReviewDate = (rank) => {
    const intervals = [1, 3, 7, 21, 45]; // Days for each rank
    const days = intervals[rank - 1];

    const nextReview = new Date(); // Current time (UTC)
    nextReview.setUTCDate(nextReview.getUTCDate() + days); // Add days to current date
    return nextReview.toISOString(); // Return ISO string
};

export const calculateToday = () => {
    const today = new Date();
    return today.toISOString(); // Full ISO string includes date and time in UTC
};
