import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";
import { useUser } from './../UserContext'; // Adjust the import path as needed
import '../../styles/Header.css';

function MobileHeader() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null); // State to track the currently open dropdown

  const navigate = useNavigate();
  const { username } = useUser(); // Use the username from context

  const auth = getAuth();

  // Handles user logout
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/login'); // Redirect to login page after logout
      })
      .catch((error) => {
        console.error('Logout Error:', error);
      });
  };

  // Toggle the mobile menu
  const toggleMobileMenu = () => {
//    console.log('Toggling mobile menu. Current state:', isMobileMenuOpen);
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setOpenDropdown(null); // Close any open dropdown when toggling the menu
  };

  // Toggle specific dropdown by name
  const toggleDropdown = (dropdownName) => {
//    console.log(`Toggling dropdown: ${dropdownName}. Currently open: ${openDropdown}`);
    setOpenDropdown((prevDropdown) => {
      const newDropdown = prevDropdown === dropdownName ? null : dropdownName;
//      console.log(`New dropdown state: ${newDropdown}`);
      return newDropdown;
    });
  };

  return (
    <div>
      <div className="mobile-menu-toggle" onClick={toggleMobileMenu}>
        <img src={`${process.env.PUBLIC_URL}/buttons/menu.png`} alt="Menu" />
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-menu-container">
          <div className="mobile-menu">
            <div className="menu-header">
              <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className="menu-logo" />
              <h1>{username}</h1>
            </div>

            <Link to="/" className="mobile-menu-item" onClick={toggleMobileMenu}>
              Home
            </Link>

            {/* Mobile Dropdown for Pattern Recognition */}
            <div className="mobile-dropdown">
              <button
                className="mobile-menu-item mobile-dropdown-btn"
                onClick={() => toggleDropdown('pattern')}
              >
                Pattern Recognition
              </button>
              {openDropdown === 'pattern' && (
                <div className="mobile-dropdown-content show">
                  <Link to="/puzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Tactics Trainer
                  </Link>
                  <Link to="/gameanalysis" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Game Analysis
                  </Link>
                  <Link to="/findthefork" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Find the Fork
                  </Link>
                </div>
              )}
            </div>

            {/* Mobile Dropdown for Visualization */}
            <div className="mobile-dropdown">
              <button
                className="mobile-menu-item mobile-dropdown-btn"
                onClick={() => toggleDropdown('visualization')}
              >
                Visualization
              </button>
              {openDropdown === 'visualization' && (
                <div className="mobile-dropdown-content show">
                  <Link to="/play" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Blindfold Chess
                  </Link>
                  <Link to="/blindfoldpuzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Blindfold Puzzles
                  </Link>
                  <Link to="/calculationpuzzles" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Calculation Practice
                  </Link>
                  <Link to="/coordinatetrainer" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Coordinate Trainer
                  </Link>
                </div>
              )}
            </div>

            {/* Mobile Dropdown for Drills */}
            <div className="mobile-dropdown">
              <button
                className="mobile-menu-item mobile-dropdown-btn"
                onClick={() => toggleDropdown('drills')}
              >
                Theory
              </button>
              {openDropdown === 'drills' && (
                <div className="mobile-dropdown-content show">
                  <Link to="/openingtrainer" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Opening Trainer
                  </Link>
                  <Link to="/endgames" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Endgame Drills
                  </Link>
                </div>
              )}
            </div>

            {/* Mobile Dropdown for Community */}
            <div className="mobile-dropdown">
              <button
                className="mobile-menu-item mobile-dropdown-btn"
                onClick={() => toggleDropdown('community')}
              >
                Community
              </button>
              {openDropdown === 'community' && (
                <div className="mobile-dropdown-content show">
                  <Link to="/teamchess" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Team Chess
                  </Link>
                  <Link to="/club" className="mobile-dropdown-item" onClick={toggleMobileMenu}>
                    Chess.com Club
                  </Link>
                </div>
              )}
            </div>

            {username && username !== 'Guest' ? (
              <>
                <Link to="/settings" className="mobile-menu-item" onClick={toggleMobileMenu}>
                  Settings
                </Link>
                <div className="mobile-menu-item" onClick={handleLogout}>
                  Log Out
                </div>
              </>
            ) : (
              <Link to="/login" className="mobile-menu-item" onClick={toggleMobileMenu}>
                Log In
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default MobileHeader;
