import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../firebase-config'; // Adjust based on your setup
import { useUser } from '../Integrations/UserContext'; // Hook to get user context
import '../styles/endgames.css';
import endgameData from '../data/sorted_puzzles.json';
import ChessboardPreview from '../Integrations/ChessboardPreview';
import EndgameBoard from '../Integrations/EndgameBoard';
import { calculateToday } from "../Integrations/helperFunctions";
import { useLocation, useNavigate } from "react-router-dom";

const Endgames = () => {
  const { user } = useUser(); // Get the current user
  const [currentGame, setCurrentGame] = useState(null);
  const levelDetailsRef = React.useRef(null);
  const [showPreview, setShowPreview] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [hoveredPuzzle, setHoveredPuzzle] = useState(null);
  const [positionStyle, setPositionStyle] = useState({});
  const [userLevels, setUserLevels] = useState({}); // Store user's levels data
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const returnedState = location.state; // Capture state returned from Analyze

  // Map data for dropdowns
  const levels = endgameData.map(level => ({
    name: level.categories[0].name,
    games: level.categories[0].games.map((game, index) => ({
      fen: game.fen,
      puzzleNumber: index + 1,
      target: game.target,
    })),
  }));

useEffect(() => {
  if (returnedState) {
    // Process the returned state
    if (returnedState.levelName && returnedState.puzzleNumber) {
      const level = levels.find(lvl => lvl.name === returnedState.levelName);
      const puzzle = level?.games.find(
        (game) => game.puzzleNumber === returnedState.puzzleNumber
      );

      if (level && puzzle) {
        setSelectedLevel(level);
        handleGameSelection(puzzle, returnedState.levelName);
      }
    }

    // Clear the returned state by replacing it in history
    navigate(location.pathname, { replace: true, state: null });
  }
}, [returnedState, levels, navigate, location.pathname]);

useEffect(() => {
  if (user) {
    setShowLoginMessage(false); // Hide the login message if the user is logged in
  } else {
    setShowLoginMessage(true);  // Show the login message if no user is logged in
  }
}, [user]);

  const handleCloseMessage = () => {
    setShowLoginMessage(false);
  };

  // Function to fetch user's puzzle data
  const fetchLevelsData = async () => {
    if (user?.uid) {
      const userDocRef = doc(db, 'users', user.uid);
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserLevels(userDoc.data().levels || {}); // Update levels data
        }
      } catch (error) {
        console.error("Error fetching levels data:", error);
      }
    }
  };

  useEffect(() => {
    fetchLevelsData(); // Initial fetch when component mounts
  }, [user]); // Refetch data if user changes

  const getPlayerColorFromFen = (fen) => {
    const turn = fen.split(' ')[1]; // The second part of the FEN indicates the turn
    return turn === 'w' ? 'white' : 'black';
  };

  const handleMouseEnter = (game, event) => {
    setHoveredPuzzle(game.fen);
    updatePosition(event);
  };

  const handleMouseLeave = () => {
    setHoveredPuzzle(null);
  };

  const updatePosition = (event) => {
    const button = event.target;
    const rect = button.getBoundingClientRect();
    const isNearBottom = window.innerHeight - rect.bottom < 300;

    setPositionStyle(isNearBottom ? { bottom: '120%', top: 'auto' } : { top: '120%', bottom: 'auto' });
  };

  const openSelection = () => {
    setShowPreview(true);
    fetchLevelsData();
  };

const fetchLevelDetails = (levelName, puzzleNumber) => {
  const userLevelData = userLevels[levelName];
  const puzzleData = userLevelData
    ?.split(';')
    .map(entry => {
      const parts = entry.split(':');
      const index = parts[0]; // Puzzle number
      const rank = parts[1];  // Stars
      const combinedDate = parts.slice(2).join(':'); // Preserve full timestamp
      return [index, rank, combinedDate];
    })
    .find(([index]) => parseInt(index) === puzzleNumber);

  // If no puzzle data is found, return default empty details
  if (!puzzleData) {
//    console.warn(`No data found for Level: ${levelName}, Puzzle: ${puzzleNumber}`);
    return {
      stars: 0,              // Default 0 stars
      attemptHistory: [],   // Empty attempt history
      dueDate: null,        // No due date set
    };
  }

  // Extract stars and attempt history
  const stars = parseInt(puzzleData[1] || 0); // Default stars to 0
  const timestamp = puzzleData[2]; // Extract timestamp

  // If no timestamp exists, set dueDate to null
  if (!timestamp) {
//    console.warn(`No timestamp found for Level: ${levelName}, Puzzle: ${puzzleNumber}`);
    return {
      stars: stars,              // Stars from data
      attemptHistory: [],       // Empty attempt history
      dueDate: null,            // No due date set
    };
  }

  // Parse timestamp and extract date and history
  const [dueDate, history = ""] = timestamp.split(','); // Split into due date and history
  let dueDateObj = new Date(dueDate); // Parse due date

  // Handle invalid due date
  if (isNaN(dueDateObj.getTime())) {
    console.error(`Invalid dueDate: ${dueDate}`);
    dueDateObj = null; // Set dueDate to null if invalid
  }

  // Handle attempt history
  let attemptHistory = history.replace(/[^sf]/g, "").split(""); // Filter out invalid characters

//  // Ensure at least 3 entries by padding with '0' (no attempt)
//  while (attemptHistory.length < 3) {
//    attemptHistory.unshift('0'); // Add '0' at the start until we have 3 attempts
//  }
//
//  // Trim to only the last 3 attempts
//  attemptHistory = attemptHistory.slice(-3);

  // Return level details
  return {
    stars: stars,                               // Stars from user data
    attemptHistory: attemptHistory,             // Last 3 attempts (padded if needed)
    dueDate: dueDateObj ? dueDateObj.toISOString() : null, // Valid date or null
  };
};

const handleGameSelection = (game, levelName) => {
  const playerColor = getPlayerColorFromFen(game.fen); // Derive player color from FEN

  // Create the game state object
  const newGameState = {
    fen: game.fen,
    history: [],
    playerColor, // Use the derived player color
    target: game.target,
    puzzleNumber: game.puzzleNumber,
    levelName: levelName,
  };

  // Fetch level details separately
  const levelDetails = fetchLevelDetails(levelName, game.puzzleNumber);

  // Update the current game state and level details
  setCurrentGame({ settings: newGameState });
  levelDetailsRef.current = levelDetails; // Separate variable for level details
  setShowPreview(false);
};

  // Handles loading the next puzzle
  const handleNextPuzzle = () => {
    if (!currentGame) return;

    const nextPuzzleNumber = currentGame.settings.puzzleNumber + 1;
    const level = levels.find(lvl => lvl.name === currentGame.settings.levelName);
    const nextPuzzle = level?.games.find(
      (game) => game.puzzleNumber === nextPuzzleNumber
    );

    if (nextPuzzle) {
      handleGameSelection(nextPuzzle, currentGame.settings.levelName);
    } else {
      alert("No more puzzles in this level!");
    }
  };

  const handleLevelClick = (levelName) => {
    if (selectedLevel && selectedLevel.name === levelName) {
      setSelectedLevel(null); // Deselect if already selected
    } else {
      const level = levels.find(lvl => lvl.name === levelName);
      setSelectedLevel(level);
    }
  };

const getPuzzleStyle = (levelName, puzzleNumber) => {
  // Use the full UTC timestamp from calculateToday()
  const today = new Date(calculateToday()); // Parse as Date object for math operations


  // Check if level exists in user data
  if (!userLevels[levelName]) {
//    console.warn(`No data found for level: ${levelName}`);
    return {}; // No data for this level
  }

  // Extract puzzle data safely
  const puzzleData = userLevels[levelName]
    .split(';')
    .map(entry => {
      const parts = entry.split(':');
      const index = parts[0]; // Puzzle number
      const rank = parts[1];  // Rank
      const combinedDate = parts.slice(2).join(':'); // Preserve full timestamp
      return [index, rank, combinedDate];
    })
    .find(([index]) => parseInt(index) === puzzleNumber);

  if (!puzzleData) {
//    console.warn(`No data found for puzzle number: ${puzzleNumber} in level: ${levelName}`);
    return {}; // No specific data for this puzzle
  }

  // Extract rank and timestamp
  const [, rank = 1, timestamp = calculateToday()] = puzzleData; // Default to today if missing

  // Extract dueDate and history
  const [dueDate, history = ""] = timestamp.split(',');

  // Parse as full ISO string
  let dueDateObj = new Date(dueDate);

  // Handle invalid timestamps
  if (isNaN(dueDateObj.getTime())) {
    console.error(`Invalid dueDate: ${dueDate}`);
    dueDateObj = today; // Fallback to today
  }

  // Calculate difference in milliseconds and days
  const differenceInMs = dueDateObj.getTime() - today.getTime();
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  // Rank and history checks
  const isMaxRank = parseInt(rank) === 5;
  const successCount = history.split('').filter(h => h === 's').length;
  const isSuccessful = successCount >= 2;

  // Determine border color
  let borderColor = '#28a745'; // Default: Green (No review needed)

  if (!isMaxRank || !isSuccessful) {
    if (differenceInDays < -2) {
      borderColor = '#dc3545'; // Overdue - Deep Red
    } else if (differenceInDays <= 0) {
      borderColor = '#ffc107'; // Due soon - Yellow
    }
  }

  // Return dynamic style
  return {
    border: `2px solid ${borderColor}`,
    boxShadow: `0 0 4px ${borderColor}`,
    padding: '5px',
    borderRadius: '8px',
    transition: 'all 0.3s ease',
  };
};



const renderStars = (rank) => {
  const maxStars = 5; // Maximum stars for repetition level
  const stars = [];
  for (let i = 1; i <= maxStars; i++) {
    stars.push(
      <span
        key={i}
        style={{
          color: i <= rank ? 'gold' : 'lightgray', // Highlight stars up to the rank
          fontSize: '16px',
        }}
      >
        ★
      </span>
    );
  }
  return stars;
};

const calculateLevelCompletion = (levelName) => {
  if (!userLevels[levelName]) return 0; // Default to 0% if no data

  const puzzleData = userLevels[levelName]
    .split(';') // Split into puzzles
    .map(entry => entry.split(':')); // Extract individual data fields

  const totalStars = 250; // 50 puzzles * 5 stars each
  const accumulatedStars = puzzleData.reduce((sum, [index, rank]) => sum + parseInt(rank || 0), 0); // Sum up ranks

  return Math.round((accumulatedStars / totalStars) * 100); // Return percentage
};

const getLevelStatus = (levelName) => {
  console.log(`Checking status for level: ${levelName}`);

  // Check if the level exists in user data
  if (!userLevels[levelName]) {
    console.warn(`No data found for level: ${levelName}`);
    return 'green'; // Default to green if no data
  }

  // Parse level data into puzzles
  const puzzles = userLevels[levelName]
    .split(';')
    .map(entry => {
      const parts = entry.split(':');
      const index = parts[0];                     // Puzzle number
      const rank = parts[1];                      // Rank or stars
      const combined = parts.slice(2).join(':');  // Preserve full timestamp and history
      const [dueDateRaw] = combined.split(',');   // Extract only the due date
      return [index, rank, dueDateRaw];           // Return extracted data
    });

  console.log(`Puzzles for ${levelName}:`, puzzles);

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Ignore time for comparison

  let hasYellow = false;
  let hasRed = false;

  puzzles.forEach(([index, rank, dueDateRaw]) => {
    console.log(`Processing puzzle ${index}: rank=${rank}, dueDate=${dueDateRaw}`);

    // Safely parse the due date
    let dueDateObj = new Date(dueDateRaw);

    // Log parsed due date
    console.log(`Parsed dueDateObj for puzzle ${index}:`, dueDateObj);

    // Handle invalid dates
    if (isNaN(dueDateObj.getTime())) {
      console.error(`Invalid due date for puzzle ${index}:`, dueDateRaw);
      return; // Skip invalid dates
    }

    dueDateObj.setHours(0, 0, 0, 0); // Ignore time for consistency

    // Calculate difference in days
    const differenceInDays = Math.ceil((dueDateObj - today) / (1000 * 60 * 60 * 24));
    console.log(`Difference in days for puzzle ${index}: ${differenceInDays}`);

    // Determine overdue or due soon status
    if (differenceInDays < -2) {
      console.warn(`Puzzle ${index} is overdue!`);
      hasRed = true; // Overdue
    } else if (differenceInDays < 0) {
      console.warn(`Puzzle ${index} is due soon!`);
      hasYellow = true; // Due soon
    }
  });

  // Final status based on checks
  const status = hasRed ? 'red' : hasYellow ? 'yellow' : 'green';
  console.log(`Level status for ${levelName}: ${status}`);
  return status; // Return determined status
};

  return (
    <div className="endgame-wrapper">
      {showLoginMessage && (
        <div className="login-message">
          <button className="close-button" onClick={handleCloseMessage}>×</button>
          <p><a href="/login">Log in</a> to save your endgame progress.</p>
        </div>
      )}
      {showPreview ? (
        <div className="endgame-layout">
            <div className="endgame-sidebar">
              {levels.map((level, index) => {
                const status = getLevelStatus(level.name); // Get level status (green, yellow, red)

                return (
                  <div
                    key={index}
                    className={`endgame-level-item ${selectedLevel && selectedLevel.name === level.name ? 'selected' : ''}`}
                    onClick={() => handleLevelClick(level.name)}
                    style={{
                      border:
                        status === 'red'
                          ? '3px solid #e74c3c' // Red border for overdue
                          : status === 'yellow'
                          ? '3px solid #ffc107' // Golden yellow border for due soon
                          : 'none', // No border if no review needed
                    }}
                  >
                    {level.name} - {calculateLevelCompletion(level.name)}%
                  </div>
                );
              })}
            </div>
          <div className="endgame-puzzles">
            <button className='help-link' onClick={() => window.location.href = '/endgames/help'}>Help</button>
            {selectedLevel &&
              selectedLevel.games.map((game, gameIndex) => (
                <div
                  key={gameIndex}
                  className="endgame-puzzle"
                  onMouseEnter={(event) => handleMouseEnter(game, event)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleGameSelection(game, selectedLevel.name)}
                  style={getPuzzleStyle(selectedLevel.name, game.puzzleNumber)} // Dynamic border
                >
                  <div className="endgame-puzzle-content">
                    {game.puzzleNumber}
                    <div className="stars-container">
                      {renderStars(
                        userLevels[selectedLevel.name]
                          ?.split(';')
                          .map(entry => entry.split(':'))
                          .find(([index]) => parseInt(index) === game.puzzleNumber)?.[1] || 0 // Get rank or default 1
                      )}
                    </div>
                  </div>
                  <div className="only-desktop">
                    {hoveredPuzzle === game.fen && (
                      <div
                        style={{
                          ...positionStyle,
                          position: 'absolute',
                          right: '55px',
                          width: '100%',
                          boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                          zIndex: 100,
                        }}
                      >
                        <ChessboardPreview fen={game.fen} playerColor={getPlayerColorFromFen(game.fen)} />
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div>
          {currentGame && (
            <EndgameBoard
              initialFen={currentGame.settings.fen}
              currentGame={currentGame}
              levelDetailsRef={levelDetailsRef}
              openSelection={openSelection}
              handleNextPuzzle={handleNextPuzzle}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Endgames;
