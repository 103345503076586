import React, { useState, useEffect } from "react";
import { useUser } from "./UserContext"; // Your existing auth context
import {
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  increment,
  query,
  orderBy,
  serverTimestamp,
  onSnapshot
} from "firebase/firestore";
import { db } from "../firebase-config"; // Ensure Firebase is configured
import "../styles/discussion.css";

const DiscussionBox = ({ folder, articleId, title }) => {
  const { user, username } = useUser();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [replyTo, setReplyTo] = useState(null); // ID of the message being replied to
  const [expandedReplies, setExpandedReplies] = useState({}); // Track expanded replies by message ID
  const [newReply, setNewReply] = useState(""); // Content of the new reply

useEffect(() => {
  if (!folder || !articleId) {
    console.error("Folder or Topic is missing.");
    return;
  }
  // Fetch messages logic here
}, [folder, articleId]);

//  useEffect(() => {
//    const fetchMessages = async () => {
//      setLoading(true);
//        const messagesRef = collection(
//          db,
//          "forums",
//          folder,
//          "topics",
//          articleId,
//          "messages"
//        );
//      const q = query(messagesRef, orderBy("likes", "desc"));
//      const snapshot = await getDocs(q);
//
//      const fetchedMessages = snapshot.docs.map((doc) => ({
//        id: doc.id,
//        ...doc.data(),
//      }));
//
//      setMessages(fetchedMessages);
//      setLoading(false);
//    };
//
//    fetchMessages();
//  }, [articleId]);

useEffect(() => {
  if (!folder || !articleId) {
    console.error("Folder or Topic is missing.");
    return;
  }

  setLoading(true); // Start loading spinner

  const messagesRef = collection(
    db,
    "forums",
    folder,
    "topics",
    articleId,
    "messages"
  );

  // Query to order messages by likes
  const q = query(messagesRef, orderBy("likes", "desc"));

  // Real-time listener for updates
  const unsubscribe = onSnapshot(q, (snapshot) => {
    const fetchedMessages = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setMessages(fetchedMessages); // Update messages in state
    setLoading(false); // Stop loading spinner
  });

  // Cleanup listener on unmount
  return () => unsubscribe();
}, [folder, articleId]);

  const addMessage = async () => {
    if (!user) {
      alert("You must be logged in to post a message.");
      return;
    }

    if (!newMessage.trim()) {
      alert("Message cannot be empty.");
      return;
    }

    const message = {
      username: username || "Anonymous",
      message: newMessage.trim(),
      likes: 0,
      replies: [], // Initialize with an empty array for replies
      timestamp: serverTimestamp(),
    };

    const messagesRef = collection(
      db,
      "forums",
      folder,
      "topics",
      articleId,
      "messages"
    );
    const docRef = await addDoc(messagesRef, message);

    setMessages((prev) => [
      ...prev,
      { id: docRef.id, ...message, timestamp: new Date().toISOString() },
    ]);
    setNewMessage("");
  };

const addReply = async (parentMessageId) => {
  if (!user) {
    alert("You must be logged in to reply.");
    return;
  }

  if (!newReply.trim()) {
    alert("Reply cannot be empty.");
    return;
  }

  const reply = {
    id: `${Date.now()}`,
    username: username || "Anonymous",
    message: newReply.trim(),
    likes: 0,
    timestamp: new Date().toISOString(),
  };

  const messageRef = doc(
    db,
    "forums",
    folder,
    "topics",
    articleId,
    "messages",
    parentMessageId
  );

  const parentMessage = messages.find((msg) => msg.id === parentMessageId);

  try {
    // Update Firestore directly
    await updateDoc(messageRef, {
      replies: [...(parentMessage.replies || []), reply],
    });

    // Remove manual state update—Firestore listener will handle it
    setNewReply("");
    setReplyTo(null); // Close reply box
  } catch (error) {
    console.error("Error adding reply:", error);
  }
};

  const toggleReplies = (messageId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [messageId]: !prev[messageId], // Toggle the expanded state
    }));
  };

const likeMessage = async (messageId) => {
  if (!user) {
    alert("You must be logged in to like a message.");
    return;
  }

  const messageRef = doc(
    db,
    "forums",
    folder,
    "topics",
    articleId,
    "messages",
    messageId
  );

  // Fetch the current message data
  const messageDoc = await getDoc(messageRef);
  if (!messageDoc.exists()) {
    console.error("Message not found");
    return;
  }

  const messageData = messageDoc.data();
  const likedBy = messageData.likedBy || []; // Array of user IDs who liked the message

  // Check if the user has already liked this message
  if (likedBy.includes(user.uid)) {
    alert("You have already liked this message.");
    return;
  }
  // Update Firestore directly (no manual local update)
  await updateDoc(messageRef, {
    likes: increment(1),
    likedBy: [...likedBy, user.uid],
  });

  // No need to manually update local state—Firestore listener will handle updates automatically
};

return (
  <div className="discussion-box">
    <h3>{title}</h3>

    {loading ? (
      <p>Loading messages...</p>
    ) : (
      <ul className="message-list">
        {messages.map((msg) => (
          <React.Fragment key={msg.id}>
            <li className="message-item">
              <div className="message-header">
                <strong>{msg.username}: </strong> {/* Space after username */}
                <span>{msg.message}</span>
              </div>
                <div className="forum-btn-container">
                  {/* Like button */}
                  <button className="forum-like-btn" onClick={() => likeMessage(msg.id)}>
                    <img
                      src="/buttons/like.png"
                      alt="Like"
                      className="forum-btn-icon"
                    />
                    <span className="forum-btn-count">{msg.likes}</span>
                  </button>

                  {/* Comment button */}
                  <button
                    className="forum-like-btn"
                    onClick={() => setReplyTo(replyTo === msg.id ? null : msg.id)}
                  >
                    <img
                      src="/buttons/comment.png"
                      alt="Comment"
                      className="forum-btn-icon"
                    />
                    <span className="forum-btn-count">{msg.replies?.length || 0}</span>
                  </button>
                </div>

            </li>

            {/* Expanded Comments and Reply Box */}
            {replyTo === msg.id && (
              <div className="expanded-comments">
                {/* Expanded Comments */}
                {msg.replies && msg.replies.length > 0 && (
                  <ul className="reply-list">
                    {msg.replies.map((reply) => (
                      <li key={reply.id} className="reply-item">
                        <strong>{reply.username}: </strong> {/* Space after username */}
                        {reply.message}
                      </li>
                    ))}
                  </ul>
                )}

                {/* Reply Box */}
                <div className="reply-box">
                  <textarea
                    className='forum-text-area'
                    placeholder="Write your reply..."
                    value={newReply}
                    onChange={(e) => setNewReply(e.target.value)}
                  />
                  <button className='average-button' onClick={() => addReply(msg.id)}>Post Reply</button>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </ul>
    )}

    {/* Add new message box */}
    {user ? (
      <div className="add-message-section">
        <textarea
          placeholder="Write your message here..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button className="average-button" onClick={addMessage}>
          Post Message
        </button>
      </div>
    ) : (
    <p>
      You must be <a href="/login" >logged in</a> to post messages.
    </p>
    )}
  </div>
);
};

export default DiscussionBox;


