import React from 'react';
import '../../styles/help.css'; // Ensure the path matches your folder structure
import EnableScrollOnPage from '../../Integrations/EnableScroll'; // Import the scroll enabler

const EndgamesHelp = () => {
  return (
    <div className="help-container">
      {/* Enable scrolling on this page */}
      <EnableScrollOnPage />

      <h1>Endgames Training Guide</h1>

      <section className="intro-section">
        <p>This guide explains how to use <strong>endgames training</strong> effectively and why mastering endgames is crucial for improving your chess skills.</p>
      </section>

      <section className="spaced-repetition">
        <h2>What is Spaced Repetition?</h2>
        <p>Spaced repetition is a learning technique that helps reinforce knowledge by reviewing information at increasing intervals. It is particularly useful for retaining patterns and concepts long-term.</p>
        <p>In endgames training, puzzles are reviewed based on your performance. Success advances a puzzle, extending the review interval. Failures bring it back for more frequent practice.</p>

        <h3>How It Works:</h3>
        <ul>
          <li>Start at <strong>Level 1</strong> and work towards <strong>Level 5</strong>.</li>
          <li>Each puzzle has stars (1 to 5), representing your current mastery level.</li>
          <li>Puzzles with frequent errors stay at lower levels, ensuring more practice.</li>
        </ul>
      </section>

      <section className="color-meanings">
        <h2>Understanding Colors</h2>
        <p>Puzzles are highlighted based on their due date for review:</p>
        <ul>
          <li><strong style={{backgroundColor: 'green', padding: '2px 5px', borderRadius: '5px'}}>Green:</strong> Mastered, no immediate review needed. Allow the forgetting process to strengthen recall.</li>
          <li><strong style={{backgroundColor: 'yellow', padding: '2px 5px', borderRadius: '5px'}}>Yellow:</strong> Approaching review time. Review soon.</li>
          <li><strong style={{backgroundColor: 'red', padding: '2px 5px', borderRadius: '5px'}}>Red:</strong> Past review date. Needs immediate attention.</li>
        </ul>

        <h3>Example:</h3>
        <p>If a puzzle has 5 stars and the last two out of three attempts were successful, it will stay green until review is needed again.</p>
      </section>

      <section className="benefits-section">
        <h2>Why Focus on Endgames?</h2>
        <p>Endgames often decide the outcome of competitive chess games. Training in this phase strengthens critical skills:</p>
        <ul>
          <li><strong>Precision:</strong> Endgames require exact calculation and execution.</li>
          <li><strong>Pattern Recognition:</strong> Master common themes like opposition, zugzwang, and pawn promotion.</li>
          <li><strong>Conversion Skills:</strong> Learn how to convert slight advantages into victories.</li>
        </ul>
        <p>By mastering endgames, you’ll approach the final stages of any game with confidence.</p>
      </section>

      <section className="tips-section">
        <h2>Tips for Success</h2>
        <ul>
          <li>Focus on quality, not speed. Take your time to calculate correctly.</li>
          <li>Revisit failed puzzles and analyze your errors to improve.</li>
          <li>Use hints sparingly and try to solve puzzles independently first.</li>
        </ul>
      </section>

      <section className="strategy-tips">
        <h2>Have Questions?</h2>
        <p>For more help, check out our <a href="/help">main help page</a> or <a href="/contact">contact us</a> for further assistance.</p>
      </section>
    </div>
  );
};

export default EndgamesHelp;
